/**
 * RecommendationDetail scene slice
 *
 * @author Miguel Antunes <mantunes@ubiwhere.com>
 *
 *
 */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  editing: false,
  loading: false,
  recommendationDetail: undefined,
};

export default createSlice({
  name: 'RecommendationDetail',
  initialState,
  reducers: {
    onMount: (state, { payload }) => {
      return {
        ...state,
        loading: true,
      };
    },
    setEditing: (state, { payload }) => {
      state.editing = payload.value;
    },
    setRecommendationDetail: (state, { payload }) => {
      return {
        ...state,
        loading: false,
        recommendationDetail: payload,
      };
    },
    onUnmount: (state, { payload }) => {
      return initialState;
    },
  },
});
