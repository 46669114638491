/**
 * UserAccountForm container
 *
 * @author Miguel Antunes <mantunes@ubiwhere.com>
 *
 *
 */

import React from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { Button as StyledButton, Input as StyledInput } from 'shared/components/ui';
import styled from 'styled-components';
import { actions } from 'store/rootSlices';

//import config from 'config'

const UserAccountForm = ({ t }) => {
  const dispatch = useDispatch();
  const { logout } = actions.App;

  const {
    register,
    //handleSubmit,
    formState: { errors },
  } = useForm();

  return (
    <Form>
      <Input
        name="name"
        register={register}
        //rules={rules.name}
        label={t('account.form_name')}
        placeholder={t('account.form_name')}
        defaultValue="John Doe"
        error={errors.email && errors.email.message}
      />
      <Input
        name="email"
        register={register}
        //rules={rules.email}
        label={t('account.form_email')}
        placeholder={t('account.form_email')}
        error={errors.email && errors.email.message}
        defaultValue="email@pocityf.com"
      />
      <Input
        name="password"
        register={register}
        //rules={rules.password}
        label={t('account.form_password')}
        placeholder={t('account.form_password')}
        defaultValue="*******************"
        error={errors.password && errors.password.message}
      />
      <WrapperButtons>
        <Button type="button" active={false}>
          {t('account.edit_profile')}
        </Button>
        <Button type="button" onClick={() => dispatch(logout())}>
          {t('account.log_out')}
        </Button>
      </WrapperButtons>
    </Form>
  );
};

export default UserAccountForm;

const Form = styled.form``;

const Input = styled(StyledInput)`
  margin-bottom: 1.5rem;
`;

const WrapperButtons = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 2rem;
`;

const Button = styled(StyledButton)`
  width: 15.4375rem;
  margin-bottom: 1rem;
`;
