import * as React from 'react';

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={144}
      height={103}
      viewBox="0 0 144 103"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M106.381 102.88a7.739 7.739 0 017.739-7.735 7.739 7.739 0 017.739 7.735"
        fill="#F2F2F2"
      />
      <path
        d="M114.12 102.88a.204.204 0 01-.205-.204v-4.078c0-.114.091-.205.205-.205.114 0 .204.091.204.205v4.078c0 .113-.09.204-.204.204z"
        fill="#CCC"
      />
      <path
        d="M114.12 101.483a.285.285 0 01-.136-.045l-2.512-2.159a.204.204 0 01-.023-.295.204.204 0 01.296-.023l2.511 2.159a.202.202 0 01.023.295.225.225 0 01-.159.068z"
        fill="#CCC"
      />
      <path
        d="M45.355 102.88a7.739 7.739 0 017.739-7.735 7.739 7.739 0 017.74 7.735"
        fill="#F2F2F2"
      />
      <path
        d="M53.094 102.88a.204.204 0 01-.204-.204v-4.078c0-.114.09-.205.204-.205.114 0 .205.091.205.205v4.078c.011.113-.091.204-.205.204z"
        fill="#CCC"
      />
      <path
        d="M53.094 101.483a.286.286 0 01-.136-.045l-2.512-2.159a.203.203 0 01-.023-.295.203.203 0 01.296-.023l2.511 2.159a.203.203 0 01.023.295c-.034.045-.09.068-.159.068z"
        fill="#CCC"
      />
      <path
        d="M57.587 46.995C57.587 33.066 46.282 0 32.322 0S7.08 33.053 7.08 46.995c.023 13.943 11.328 25.23 25.288 25.23 13.903-.022 25.22-11.299 25.22-25.23z"
        fill="#F2F2F2"
      />
      <path
        d="M32.345 102.892a.293.293 0 01-.301-.302V43.681c0-.174.128-.301.301-.301.174 0 .302.127.302.301v58.909c-.035.174-.162.302-.302.302z"
        fill="#CCC"
      />
      <path
        d="M32.345 54.691c-.081 0-.174-.046-.209-.104L17.585 37.85a.302.302 0 01.023-.405.302.302 0 01.406.023l14.528 16.735a.302.302 0 01-.023.406c-.046.035-.116.081-.174.081zM32.345 64.345a.292.292 0 01-.301-.301.28.28 0 01.081-.209l8.244-8.24a.302.302 0 01.406 0 .302.302 0 010 .406l-8.244 8.24c-.046.058-.128.104-.186.104z"
        fill="#CCC"
      />
      <path
        d="M112.54 61.5c0-10.315-8.375-34.805-18.706-34.805-10.33 0-18.694 24.479-18.694 34.804.011 10.326 8.387 18.686 18.728 18.686 10.297-.023 18.672-8.372 18.672-18.686z"
        fill="#F2F2F2"
      />
      <path
        d="M93.846 102.892a.209.209 0 01-.216-.216v-43.63c0-.125.09-.216.216-.216.125 0 .216.09.216.216v43.619c-.012.125-.102.227-.216.227z"
        fill="#CCC"
      />
      <path
        d="M93.846 67.19a.2.2 0 01-.16-.08L82.914 54.73a.212.212 0 01.011-.296.213.213 0 01.296.011l10.75 12.393c.08.09.069.216-.01.295a.14.14 0 01-.114.057zM93.846 74.346a.209.209 0 01-.216-.216c0-.068.011-.113.068-.159l6.103-6.1c.08-.079.216-.079.295 0 .08.08.08.217 0 .296l-6.114 6.1c-.022.045-.09.08-.136.08z"
        fill="#CCC"
      />
      <path
        d="M80.369 32.454c.045-5.02-3.228-9.11-7.308-9.144-4.08-.034-7.42 4.01-7.466 9.03-.034 4.555 2.648 8.338 6.193 9.031l-.25 1.465 2.705.023-.227-1.465c3.568-.636 6.319-4.385 6.353-8.94z"
        fill="#09F"
      />
      <path
        d="M68.084 34.749l-1.023-.012c-.33-1.92-.307-3.885.045-5.793l1.023.012c-.466 1.999-.477 3.93-.045 5.793z"
        fill="#fff"
      />
      <path d="M71.383 41.357l-.005.681 3.068.024.006-.681-3.069-.024z" fill="#3F3D56" />
      <path
        d="M94.586 35.226c.397-4.998-2.58-9.326-6.649-9.644-4.068-.318-7.682 3.476-8.08 8.474-.363 4.532 2.046 8.508 5.535 9.45l-.352 1.443 2.693.216-.114-1.477c3.603-.386 6.603-3.93 6.967-8.462z"
        fill="#E6007E"
      />
      <path
        d="M82.165 36.634l-1.023-.08a16.702 16.702 0 01.454-5.77l1.023.08c-.602 1.954-.75 3.885-.454 5.77z"
        fill="#fff"
      />
      <path d="M84.982 43.467l-.054.68 3.06.242.053-.679-3.059-.243z" fill="#3F3D56" />
      <path d="M72.039 62.897l14.364-19.163.296.17-14.376 19.174-.284-.181z" fill="#3F3D56" />
      <path
        d="M100.359 40.144c2.364-4.43 1.363-9.575-2.239-11.495-3.603-1.92-8.433.113-10.796 4.543-2.137 4.01-1.523 8.622 1.295 10.871l-.897 1.181 2.386 1.273.489-1.409c3.443 1.09 7.625-.954 9.762-4.964z"
        fill="#09F"
      />
      <path
        d="M88.403 36.475l-.897-.477a16.556 16.556 0 012.727-5.111l.898.477c-1.33 1.556-2.239 3.26-2.728 5.111z"
        fill="#fff"
      />
      <path d="M88.257 43.863l-.321.601 2.708 1.443.32-.601-2.707-1.443z" fill="#3F3D56" />
      <path d="M72.505 62.476l16.75-17.572.274.204-16.752 17.573-.272-.205z" fill="#3F3D56" />
      <path
        d="M72.584 63.056l.523-21.367.34.057-.454 21.037c-.034.182-.068.33-.25.307l-.159-.034zM143.648 102.881H.368c-.224 0-.368-.046-.368-.114s.144-.114.368-.114h143.264c.224 0 .368.046.368.114s-.128.114-.352.114z"
        fill="#3F3D56"
      />
      <path
        d="M114.018 83.23l-.069.215-4.284-1.363c.375.5 1.648 2.067 2.978 2.408 1.579.41 2.386-.159 2.625-1.079.238-.92-.193-1.806-1.773-2.226-1.341-.353-3.25.42-3.796.659l4.319 1.385zM72.448 90.567l-.227.012-.228-4.487c-.34.534-1.364 2.272-1.216 3.623.17 1.625.989 2.181 1.932 2.09.943-.09 1.625-.806 1.466-2.43-.136-1.375-1.523-2.897-1.943-3.317l.216 4.51zM71.481 16.983l.171.148-3.012 3.328c.614-.136 2.569-.648 3.421-1.704 1.034-1.272.841-2.238.114-2.84-.728-.602-1.716-.579-2.762.682-.875 1.068-.977 3.124-.977 3.714l3.045-3.328zM121.086 44.642l.171.148-3.012 3.328c.614-.136 2.569-.647 3.421-1.704 1.034-1.272.841-2.237.114-2.84-.728-.601-1.716-.579-2.762.682-.875 1.068-.977 3.124-.977 3.715l3.045-3.328zM7.012 10.372l.136-.182 3.614 2.67c-.193-.602-.887-2.488-2.023-3.249-1.364-.897-2.318-.625-2.841.16-.523.783-.41 1.771.954 2.68 1.148.76 3.205.659 3.808.613l-3.648-2.692z"
        fill="#F2F2F2"
      />
      <path
        d="M55.378 75.937a1.468 1.468 0 00.66-1.954.866.866 0 00-.137-.216l1.091-10.632H54.64l-.864 10.382a1.458 1.458 0 00-.17 2.056c.443.511 1.17.67 1.772.364zM57.799 57.785a3.329 3.329 0 10.001-6.658 3.329 3.329 0 00-.001 6.658z"
        fill="#FFD4C7"
      />
      <path
        d="M58.162 69.326a.62.62 0 01-.59-.443 34.442 34.442 0 00-2.058-4.862c-.977-2-.136-4.407 1.864-5.384.137-.068.284-.125.42-.182a4.029 4.029 0 015.137 2.454 19.904 19.904 0 01.784 6.759.629.629 0 01-.477.567l-4.966 1.08c-.023 0-.068.01-.114.01z"
        fill="#C6C6C6"
      />
      <path d="M62.97 100.893h1.659l.795-6.396H62.97v6.396z" fill="#FFD4C7" />
      <path d="M68.231 102.824h-6v-2.795h3.591a2.41 2.41 0 012.41 2.408v.387z" fill="#2F2E41" />
      <path d="M46.366 98.746l1.455.795 3.784-5.225-2.148-1.182-3.09 5.612z" fill="#FFD4C7" />
      <path
        d="M50.037 102.971l-5.262-2.885 1.352-2.453 3.148 1.726a2.408 2.408 0 01.955 3.272l-.193.34zM62.89 97.337a.603.603 0 01-.602-.534l-2.08-15.993a.342.342 0 00-.375-.296.33.33 0 00-.25.17l-8.648 15.699a.607.607 0 01-.75.273l-1.887-.716a.604.604 0 01-.352-.784v-.011l5.989-14.71.034-.114c.807-6.281 2.875-9.473 3.489-10.302a.486.486 0 00.068-.432l-.08-.25a.615.615 0 01.114-.59c2.216-2.522 6.069-1.148 6.103-1.125l.023.011.01.011c4.32 5.93 2.728 25.172 2.365 28.966a.597.597 0 01-.557.545l-2.557.194c-.023-.012-.034-.012-.057-.012z"
        fill="#2F2E41"
      />
      <path
        d="M71.323 62.612c-.012.069-.023.148-.034.228l-5.33.534-2.75-2.806-1.512 1.976 3.694 3.67c.374.215.818.192 1.295-.035l5.512-1.908a1.423 1.423 0 10.989-2.67 1.423 1.423 0 00-1.83.841l-.034.17z"
        fill="#FFD4C7"
      />
      <path
        d="M64.424 61.533l-1.875 2.443a.613.613 0 01-.852.113c-.023-.022-.046-.034-.068-.057l-2.137-2.215a1.696 1.696 0 01-.295-2.374 1.693 1.693 0 012.363-.295l2.671 1.488c.296.16.398.534.239.83-.012.022-.023.045-.046.067zM57.14 65.77l-3.035-.545a.598.598 0 01-.488-.704c0-.034.011-.057.023-.091l.977-2.908a1.693 1.693 0 011.977-1.352 1.681 1.681 0 011.353 1.965l-.091 3.056a.609.609 0 01-.625.59c-.034 0-.068-.01-.091-.01z"
        fill="#C6C6C6"
      />
      <path
        d="M51.423 53.9a4.328 4.328 0 014.33-4.328 4.31 4.31 0 013.99 2.658c.283.727.25 1.545-.092 2.25-.42.92-.045 2.18.42 3.475-3.431.83-6.432 1.011-8.648 0V53.9z"
        fill="#2F2E41"
      />
      <path d="M58.526 49.879a3.38 3.38 0 013.387 3.385H56.15v-3.385h2.375z" fill="#2F2E41" />
    </svg>
  );
}

export default SvgComponent;
