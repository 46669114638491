import * as React from 'react';

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.094 11.25h5.062a3.097 3.097 0 003.094-3.094V3.094A3.097 3.097 0 008.156 0H3.094A3.097 3.097 0 000 3.094v5.062a3.097 3.097 0 003.094 3.094zM1.5 3.094c0-.88.715-1.594 1.594-1.594h5.062c.88 0 1.594.715 1.594 1.594v5.062c0 .88-.715 1.594-1.594 1.594H3.094A1.596 1.596 0 011.5 8.156V3.094zM15.844 11.25h5.062A3.097 3.097 0 0024 8.156V3.094A3.097 3.097 0 0020.906 0h-5.062a3.097 3.097 0 00-3.094 3.094v5.062a3.097 3.097 0 003.094 3.094zM14.25 3.094c0-.88.715-1.594 1.594-1.594h5.062c.88 0 1.594.715 1.594 1.594v5.062c0 .88-.715 1.594-1.594 1.594h-5.062a1.596 1.596 0 01-1.594-1.594V3.094zM18.375 24a5.631 5.631 0 01-5.625-5.625 5.631 5.631 0 015.625-5.625A5.631 5.631 0 0124 18.375 5.631 5.631 0 0118.375 24zm0-9.75a4.13 4.13 0 00-4.125 4.125 4.13 4.13 0 004.125 4.125 4.13 4.13 0 004.125-4.125 4.13 4.13 0 00-4.125-4.125zM3.094 24h5.062a3.097 3.097 0 003.094-3.094v-5.062a3.097 3.097 0 00-3.094-3.094H3.094A3.097 3.097 0 000 15.844v5.062A3.097 3.097 0 003.094 24zM1.5 15.844c0-.88.715-1.594 1.594-1.594h5.062c.88 0 1.594.715 1.594 1.594v5.062c0 .88-.715 1.594-1.594 1.594H3.094A1.596 1.596 0 011.5 20.906v-5.062z"
        fill={props.fill || '#000'}
      />
    </svg>
  );
}

export default SvgComponent;
