import * as React from 'react';

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12 1.047C5.96 1.047 1.047 5.961 1.047 12c0 6.04 4.914 10.953 10.953 10.953 6.04 0 10.953-4.914 10.953-10.953 0-6.04-4.914-10.953-10.953-10.953zm7.43 17.595A9.987 9.987 0 0112 21.968a10.004 10.004 0 01-7.43-3.326l-.103-.113.061-.141a8.221 8.221 0 012.785-3.364 8.14 8.14 0 014.682-1.47c1.687 0 3.307.51 4.683 1.47a8.16 8.16 0 012.784 3.364l.061.141-.094.113zM8.414 8.99A3.587 3.587 0 0112 5.405a3.587 3.587 0 013.585 3.585A3.587 3.587 0 0112 12.575 3.59 3.59 0 018.415 8.99zm11.8 8.663l-.192-.348a9.216 9.216 0 00-3.934-3.774 8.948 8.948 0 00-1.244-.509l-.424-.136.354-.274a4.542 4.542 0 001.795-3.627 4.57 4.57 0 00-9.14 0 4.55 4.55 0 001.795 3.627l.354.274-.424.136a9.17 9.17 0 00-5.182 4.283l-.194.348-.212-.334A9.922 9.922 0 012.027 12c0-5.498 4.47-9.973 9.973-9.973 5.502 0 9.973 4.475 9.973 9.973 0 1.89-.532 3.726-1.54 5.319l-.217.334z"
        fill={props.fill || '#000'}
      />
      <path
        d="M12 23C5.932 23 1 18.068 1 12S5.932 1 12 1s11 4.932 11 11-4.932 11-11 11zm0-21.906C5.989 1.094 1.094 5.99 1.094 12c0 6.011 4.895 10.906 10.906 10.906 6.011 0 10.906-4.895 10.906-10.906C22.906 5.989 18.01 1.094 12 1.094zm0 20.921c-2.836 0-5.559-1.22-7.462-3.34l-.123-.136.076-.17a8.271 8.271 0 012.798-3.382A8.201 8.201 0 0112 13.507c1.696 0 3.326.514 4.71 1.48a8.204 8.204 0 012.8 3.382l.07.17-.113.136A10.057 10.057 0 0112 22.015zM4.524 18.52l.085.09a9.952 9.952 0 007.396 3.311 9.919 9.919 0 007.391-3.312l.075-.09-.051-.112a8.098 8.098 0 00-2.77-3.345 8.077 8.077 0 00-4.655-1.46 8.077 8.077 0 00-4.654 1.46 8.195 8.195 0 00-2.77 3.345l-.047.113zm-.74-.773l-.254-.4a9.97 9.97 0 01-1.55-5.342c0-5.526 4.494-10.02 10.02-10.02s10.02 4.494 10.02 10.02a9.958 9.958 0 01-1.55 5.342l-.259.4-.23-.419a9.098 9.098 0 00-5.154-4.259l-.51-.165.425-.33a4.493 4.493 0 001.776-3.589 4.526 4.526 0 00-4.523-4.518 4.526 4.526 0 00-4.522 4.518c0 1.413.645 2.723 1.776 3.59l.424.33-.509.164a9.066 9.066 0 00-2.388 1.192 9.02 9.02 0 00-2.766 3.072l-.226.414zm10.736-4.88l.34.108a9.073 9.073 0 012.407 1.206 9.223 9.223 0 012.793 3.1l.156.278.174-.269a9.875 9.875 0 001.531-5.295c0-5.474-4.452-9.926-9.926-9.926S2.075 6.526 2.075 12c0 1.88.531 3.707 1.53 5.295l.17.269.155-.278a9.143 9.143 0 012.794-3.1 9.121 9.121 0 012.416-1.201l.34-.109-.283-.216a4.623 4.623 0 01-1.814-3.675A4.62 4.62 0 0112 4.373a4.62 4.62 0 014.617 4.612c0 1.442-.66 2.78-1.814 3.665l-.283.217zM12 12.622A3.637 3.637 0 018.368 8.99 3.637 3.637 0 0112 5.358a3.637 3.637 0 013.632 3.632A3.637 3.637 0 0112 12.622zm0-7.17A3.543 3.543 0 008.462 8.99 3.543 3.543 0 0012 12.528a3.543 3.543 0 003.538-3.538A3.543 3.543 0 0012 5.452z"
        fill={props.fill || '#000'}
      />
    </svg>
  );
}

export default SvgComponent;
