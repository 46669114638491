/**
 * Input component
 *
 * @author Miguel Antunes <mantunes@ubiwhere.com>
 *
 *
 */

import React from 'react';
import { UseFormRegister } from 'react-hook-form';
import styled from 'styled-components';

type InputProps = {
  name: string;
  register: UseFormRegister<any>;
  rules?: any;
  defaultValue?: any;
  label: string;
  placeholder: string;
  error?: any;
};

const Input = (props: InputProps) => {
  const { name, register, rules, defaultValue, label, placeholder, error } = props;
  return (
    <Wrapper {...props}>
      {label && <Label error={error}>{`${label}${rules?.required?.value ? '*' : ''}`}</Label>}
      <InputWrapper>
        <InputText
          defaultValue={defaultValue}
          placeholder={placeholder}
          error={error}
          {...register(name, rules)}
        />
        {error && <InputError>{error}</InputError>}
      </InputWrapper>
    </Wrapper>
  );
};

export default Input;

const Wrapper = styled.div``;

const Label = styled.div<{ error?: boolean | string }>`
  line-height: ${({ theme }) => theme.lineHeights.small};
  font-size: ${({ theme }) => theme.fontSizes.small};
  font-weight: 500;
  color: ${({ theme, error }) => (error ? theme.colors.red : theme.colors.primaryMedium)};
  margin-bottom: 0.375rem;
`;

const InputWrapper = styled.div`
  width: 100%;
  margin-bottom: 1rem;
  position: relative;
`;

const InputText = styled.input<{ error?: boolean | string }>`
  color: ${({ theme }) => theme.colors.primary};
  line-height: ${({ theme }) => theme.lineHeights.medium};
  font-size: ${({ theme }) => theme.fontSizes.medium};
  border: none;
  width: 100%;
  padding: 0.625rem 0;
  margin-bottom: 0.25rem;
  border-bottom: 0.063rem solid
    ${({ theme, error }) => (error ? theme.colors.red : theme.colors.primaryMedium)};
  outline: none;
  :placeholder-shown {
    border-bottom: 0.063rem solid
      ${({ theme, error }) => (error ? theme.colors.red : theme.colors.darkerGray)};
  }
  ::placeholder {
    color: ${({ theme }) => theme.colors.darkGray};
    opacity: 1;
  }
  :-ms-input-placeholder {
    color: ${({ theme }) => theme.colors.darkGray};
  }
  ::-ms-input-placeholder {
    color: ${({ theme }) => theme.colors.darkGray};
  }
`;

const InputError = styled.div`
  color: ${({ theme }) => theme.colors.red};
  line-height: ${({ theme }) => theme.lineHeights.extraSmall};
  font-size: ${({ theme }) => theme.fontSizes.superSmall};
  position: absolute;
  right: 0;
`;
