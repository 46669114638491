/**
 * Badge component
 *
 * @author Miguel Antunes <mantunes@ubiwhere.com>
 *
 *
 */

import React from 'react';
import styled from 'styled-components';
import { CategoryName } from 'api/types';

const Badge = ({ type }) => {
  return (
    <Wrapper type={type}>
      <Label>{type}</Label>
    </Wrapper>
  );
};

export default Badge;

const Wrapper = styled.div<{ type: string }>`
  border-radius: 1rem 1rem 0rem 1rem;
  background: ${({ type, theme }) => {
    switch (type) {
      case CategoryName.EXHIBITION:
        return theme.colors.secondary;
      case CategoryName.MUSIC:
        return theme.colors.mint;
      case 'fair':
        return theme.colors.green;
      case 'art':
      default:
        return theme.colors.highlight;
    }
  }};
  padding: 0.25rem 0.75rem;
`;

const Label = styled.label`
  font-weight: bold;
  font-size: ${({ theme }) => theme.fontSizes.small};
  line-height: ${({ theme }) => theme.lineHeights.medium};
  letter-spacing: 0.013rem;
  color: ${({ theme }) => theme.colors.white};
`;
