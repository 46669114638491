import * as React from 'react';

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      transform="rotate(180.1)"
      {...props}
    >
      <path
        d="M21.219 11.063H4.674l2.74-2.728a.781.781 0 10-1.102-1.108L2.23 11.29a.782.782 0 000 1.107l4.082 4.063a.781.781 0 001.102-1.108l-2.74-2.727h16.545a.781.781 0 000-1.563z"
        fill={props.fill || '#000'}
      />
    </svg>
  );
}

export default SvgComponent;
