/**
 * PracticesCarousel container
 *
 * @author Miguel Antunes <mantunes@ubiwhere.com>
 *
 *
 */

import React, { useLayoutEffect, useRef } from 'react';
import styled, { css } from 'styled-components';
import touchWithMouseHOC from 'react-touch-carousel/lib/touchWithMouseHOC';

const OPTIONS = { passive: false };

const PracticesCarousel = (props) => {
  const { cursor, data, carouselWidth, onTouchMove, ...rest } = props;

  //! same size as the card wrapper in (../SustainabilityEvaluation) renderCard
  const cardSize = 304;
  const cardCount = data.length;

  let current = -Math.round(cursor) % cardCount;
  while (current < 0) {
    current += cardCount;
  }
  // Put current card at center
  const translateX = cursor * cardSize + (carouselWidth - cardSize) / 2;
  const nodeRef = useRef(null);

  useLayoutEffect(() => {
    const node = nodeRef.current;
    node.addEventListener('touchmove', onTouchMove, OPTIONS);

    return () => {
      node.removeEventListener('touchmove', onTouchMove, OPTIONS);
    };
  }, [onTouchMove]);

  return (
    <CarouselContainer ref={nodeRef} {...rest}>
      <CarouselTrack ref={nodeRef} translateX={translateX} {...rest} />
      <PaginationWrapper>
        <Pagination>
          {data.map((_, index) => (
            <PaginationIndicator key={index} active={current === index} />
          ))}
        </Pagination>
      </PaginationWrapper>
    </CarouselContainer>
  );
};

export default touchWithMouseHOC(PracticesCarousel);

const CarouselContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 60rem;
  margin: 0 auto;
  overflow: hidden;
  touch-action: pan-y;
`;

const CarouselTrack = styled.div<{ translateX: number }>`
  display: flex;
  height: 100%;
  transform: translate3d(${({ translateX }) => translateX}px, 0, 0);
`;

const PaginationWrapper = styled.div`
  display: flex;
  justify-content: center;
  pointer-events: none;
`;

const PaginationIndicator = styled.li<{ active: boolean }>`
  width: 0.375rem;
  height: 0.375rem;
  border-radius: 50%;
  background: ${({ theme }) => theme.colors.gray};

  ${({ theme, active }) =>
    !active &&
    css`
      mix-blend-mode: normal;
      opacity: 0.4;
    `}
`;

const Pagination = styled.ol`
  margin-top: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;

  > ${PaginationIndicator} {
    margin-right: 0.625rem;
  }
  :last-child {
    margin-right: 0;
  }
`;
