/**
 * EventRating scene sagas
 *
 * @author Miguel Antunes <mantunes@ubiwhere.com>
 *
 *
 */
import { call, put, takeLatest } from 'redux-saga/effects';
import API from 'api';

import { actions } from 'store/rootSlices';
import { RatingSurvey } from 'api/types';

function* onMountSaga({ payload }) {
  console.log(`get/event/${payload.id}/rating`);
  const response: RatingSurvey = yield call(API.Events.getRatingSurvey, { id: payload.id });
  yield put(actions.EventRating.setRatingSurvey(response));
}

function* onUnmountSaga({ payload }) {}

export default function* watcherSignin() {
  yield takeLatest('EventRating/onMount', onMountSaga);
  yield takeLatest('EventRating/onUnmount', onUnmountSaga);
}
