/**
 * Tile component
 *
 * @author Miguel Antunes <mantunes@ubiwhere.com>
 *
 *
 */

import styled from 'styled-components';

interface Props {
  children: any;
  disabled?: boolean;
  type?: 'cancel' | 'submit';
  className?: any;
}

export default styled.div<Props>`
  display: flex;
  flex-direction: column;
  border-radius: 1.6rem;
  justify-content: center;
  min-width: 8rem;
  min-height: 8rem;
  align-items: center;
  color: ${({ theme, disabled = false }) => (disabled ? theme.colors.primary : theme.colors.white)};
  background-color: ${({ theme, disabled, type = 'submit' }) =>
    disabled
      ? theme.colors.gray
      : (type === 'submit' && theme.colors.secondary) ||
        (type === 'cancel' && theme.colors.highlight)};
`;
