import * as React from 'react';

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.375 12.685a.623.623 0 01-.442-.183l-.495-.495v7.945c0 .99-.806 1.797-1.797 1.797H5.359c-.99 0-1.797-.806-1.797-1.797v-7.945l-.495.495a.625.625 0 11-.884-.884l7.773-7.773a2.894 2.894 0 014.088 0l7.773 7.773a.625.625 0 01-.442 1.067zm-7.5 7.814h-3.75v-4.922c0-.388.315-.703.703-.703h2.344c.388 0 .703.315.703.703v4.922zm1.25 0v-4.922a1.955 1.955 0 00-1.953-1.953h-2.344a1.955 1.955 0 00-1.953 1.953v4.922H5.359a.547.547 0 01-.547-.547v-9.195l6.028-6.028c.64-.64 1.68-.64 2.32 0l6.027 6.028v9.195a.547.547 0 01-.546.547h-3.516z"
        fill={props.fill || '#000'}
      />
    </svg>
  );
}

export default SvgComponent;
