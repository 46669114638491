/**
 * PracticeCard component
 *
 * @author Miguel Antunes <mantunes@ubiwhere.com>
 *
 *
 */

import React from 'react';
import styled from 'styled-components';
import { Card, Heading3, Text } from 'shared/components/ui';
import * as Goals from 'assets/images/goals';

const PracticeCard = ({ practice }) => {
  const { title, description, goals } = practice;
  const getGoal = (goal) => {
    switch (goal) {
      case 1:
        return Goals.Goal1;
      case 2:
        return Goals.Goal2;
      case 3:
        return Goals.Goal3;
      case 4:
        return Goals.Goal4;
      case 5:
        return Goals.Goal5;
      case 6:
        return Goals.Goal6;
      case 7:
        return Goals.Goal7;
      case 8:
        return Goals.Goal8;
      case 9:
        return Goals.Goal9;
      case 10:
        return Goals.Goal10;
      case 11:
        return Goals.Goal11;
      case 12:
        return Goals.Goal12;
      case 13:
        return Goals.Goal13;
      case 14:
        return Goals.Goal14;
      case 15:
        return Goals.Goal15;
      case 16:
        return Goals.Goal16;
      case 17:
        return Goals.Goal17;
      default:
        break;
    }
  };
  return (
    <Wrapper>
      <WrapperInfo>
        <Title>{title}</Title>
        <Description>{description}</Description>
      </WrapperInfo>
      <WrapperGoals>
        {goals.map((goal) => (
          <Goal key={`sustainability_goal_${goal}`} src={getGoal(goal)} />
        ))}
      </WrapperGoals>
    </Wrapper>
  );
};

export default PracticeCard;

const Wrapper = styled(Card)`
  background-color: ${({ theme }) => theme.colors.white};
  padding: 2rem 1rem 1.625rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  width: 15.5rem;
  height: 13.875rem;
`;
const WrapperInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const Title = styled(Heading3)`
  margin-bottom: 0.25rem;
`;
const Description = styled(Text)`
  text-align: center;
`;
const WrapperGoals = styled.div`
  display: flex;
  > * {
    margin-right: 0.813rem;
    :last-child {
      margin-right: 0;
    }
  }
`;
const Goal = styled.img`
  width: 4.5rem;
  height: 4.5rem;
`;
