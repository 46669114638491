/**
 * Login scene
 *
 * @author Miguel Antunes <mantunes@ubiwhere.com>
 *
 *
 */

import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { actions } from 'store/rootSlices';
import Loader from 'shared/components/ui/Loader';
import Cookies from 'js-cookie';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const Login = () => {
  const { onMount, onUnmount } = actions.Login;
  const { navigateTo } = actions.App;
  const query = useQuery();
  const code = query.get('code');
  const dispatch = useDispatch();

  useEffect(() => {
    const access_token = Cookies.get('access_token');
    if (access_token) dispatch(navigateTo(`/home`));
    else dispatch(onMount({ code }));
    return () => {
      dispatch(onUnmount());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [code, dispatch, onMount, onUnmount]);
  return <Loader full />;
};

export default Login;
