/**
 * Events scene sagas
 *
 * @author Miguel Antunes <mantunes@ubiwhere.com>
 *
 *
 */
import { call, putResolve, takeLatest } from 'redux-saga/effects';

import { actions } from 'store/rootSlices';
import API from 'api';

function* onMountSaga() {
  yield putResolve(actions.Events.getUserEvents());
  yield putResolve(actions.Events.getFutureEvents());
  yield putResolve(actions.Events.getPreviousEvents());
}

function* getUserEventsSaga() {
  try {
    const response = yield call(API.Events.getEvents, { params: null });
    yield putResolve(actions.Events.setUserEvents(response.data.results));
  } catch (error) {
    yield console.log('getUserEventsSaga onMountSaga:', error);
  }
}

function* getFutureEventsSaga() {
  try {
    const response = yield call(API.Events.getEvents, { params: null, mocked: true });
    yield putResolve(actions.Events.setFutureEvents(response.data.results));
  } catch (error) {
    yield console.log('getFutureEventsSaga onMountSaga:', error);
  }
}

function* getPreviousEventsSaga() {
  try {
    const response = yield call(API.Events.getEvents, { params: null, mocked: true });
    yield putResolve(actions.Events.setPreviousEvents(response.data.results));
  } catch (error) {
    yield console.log('getPreviousEventsSaga onMountSaga:', error);
  }
}

function* onUnmountSaga() {}

export default function* watcherSignin() {
  yield takeLatest('Events/onMount', onMountSaga);
  yield takeLatest('Events/getUserEvents', getUserEventsSaga);
  yield takeLatest('Events/getFutureEvents', getFutureEventsSaga);
  yield takeLatest('Events/getPreviousEvents', getPreviousEventsSaga);
  yield takeLatest('Events/onUnmount', onUnmountSaga);
}
