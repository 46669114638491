/**
 * RecommendationDetail scene sagas
 *
 * @author Miguel Antunes <mantunes@ubiwhere.com>
 *
 *
 */
import { call, put, takeLatest } from 'redux-saga/effects';

import API from 'api';

import { actions } from 'store/rootSlices';

function* onMountSaga({ payload }) {
  console.log('get/recommendation/' + payload.id);
  const response = yield call(API.Recommendations.getRecommendationDetail, { id: payload.id });
  yield put(actions.RecommendationDetail.setRecommendationDetail(response));
}

function* onUnmountSaga({ payload }) {}

export default function* watcherSignin() {
  yield takeLatest('RecommendationDetail/onMount', onMountSaga);
  yield takeLatest('RecommendationDetail/onUnmount', onUnmountSaga);
}
