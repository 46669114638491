import * as React from 'react';

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={59}
      height={40}
      viewBox="0 0 59 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.131 21.584l.39-1.209-1.014-.75h1.249l.39-1.209.39 1.209h1.24l-1.006.75.39 1.209-1.014-.75-1.015.75zM20.346 26.367l-1.006.75.382 1.209-1.007-.75-1.014.75.381-1.21-1.006-.75h1.25l.39-1.208.381 1.209h1.249zM19.097 13.687h1.249l-1.006.74.382 1.218-1.007-.75-1.014.75.381-1.217-1.006-.742h1.25l.39-1.209.381 1.21zM22.497 9.768l-1.015-.74h1.258l.381-1.219.382 1.218h1.249l-1.006.741.39 1.21-1.015-.75-1.014.75.39-1.21zM25.099 30.911l-1.015.741.39 1.21-1.006-.75-1.015.75.39-1.21-1.014-.74h1.249l.39-1.21.382 1.21h1.249zM31.178 32.261l-1.014.75.39 1.21-1.015-.742-1.006.741.39-1.209-1.014-.75h1.248l.382-1.2.382 1.2h1.257zM29.92 7.2h1.258l-1.014.75.39 1.21-1.015-.742-1.006.742.39-1.21-1.014-.75h1.248l.382-1.208.382 1.209zM37.11 30.911l-1.014.741.39 1.21-1.015-.75-1.006.75.39-1.21-1.014-.74h1.248l.382-1.21.39 1.21h1.25zM36.2 9.027h1.257l-1.015.741.39 1.21-1.014-.75-1.015.75.39-1.21-1.005-.74h1.248l.382-1.219.382 1.218zM41.863 26.367l-1.006.75.382 1.209-1.015-.75-1.006.75.381-1.21-1.014-.75h1.257l.382-1.208.39 1.209h1.249zM39.6 14.428l-1.015-.742h1.257l.382-1.209.39 1.21h1.249l-1.006.74.382 1.218-1.015-.75-1.006.75.381-1.217zM42.418 20.375l.39 1.209-1.015-.75-1.014.75.398-1.209-1.014-.75h1.24l.39-1.209.382 1.209h1.257l-1.014.75z"
        fill="#fff"
      />
      <path
        d="M57.578 38.817H1.362V1.077h56.216v37.74zm-55.41-.82H56.78v-36.1H2.168v36.1z"
        fill="#fff"
      />
    </svg>
  );
}

export default SvgComponent;
