/**
 * LinkAction component
 *
 * @author Miguel Antunes <mantunes@ubiwhere.com>
 *
 *
 */
import React from 'react';
import styled from 'styled-components';
import { Forward } from 'assets/icons';

const LinkAction = (props) => {
  return (
    <Wrapper {...props}>
      {props.children}
      <Forward />
    </Wrapper>
  );
};

export default LinkAction;

const Wrapper = styled.a`
  display: flex;
  align-items: center;
  text-decoration: none;
  color: ${({ theme }) => theme.colors.secondary};
  letter-spacing: 0.013rem;
  font-weight: bold;
  font-size: ${({ theme }) => theme.fontSizes.small};
  line-height: ${({ theme }) => theme.lineHeights.medium};
  svg path {
    fill: ${({ theme }) => theme.colors.secondary};
  }
  svg {
    margin-left: 0.25rem;
  }
`;
