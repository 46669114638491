/**
 * Home scene
 *
 * @author Miguel Antunes <mantunes@ubiwhere.com>
 *
 *
 */
import React, { useEffect } from 'react';
import styled from 'styled-components';

import { useDispatch, useSelector } from 'react-redux';

import { actions } from 'store/rootSlices';
import { Help as HelpIcon } from 'assets/icons';
import { Button as StyledButton, Heading1 } from 'shared/components/ui';
import Itenerary from 'assets/illustrations/Itenerary';
import UpcomingEvents from './containers/UpcomingEvents';
import Objectives from './components/Objectives';
import RecommendationCard from 'shared/components/RecommendationCard';
import EUFooter from 'shared/components/EUFooter';

const Home = ({ t, i18n }) => {
  const { loadingUpcommingEvents, upcommingEvents } = useSelector((state) => state.Home);

  const { onMount, onUnmount } = actions.Home;

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(onMount());
    return () => {
      dispatch(onUnmount());
    };
  }, [dispatch, onMount, onUnmount]);

  return (
    <Wrapper>
      <RoundSection>
        <WrapperHelp>
          <HelpIcon />
        </WrapperHelp>
        <Title>{t('home.welcome_title')}</Title>
        <Paragraph>
          {t('home.welcome_description')} <Strong>{t('home.welcome_description_strong')}</Strong>
        </Paragraph>
        <UpcomingEvents loading={loadingUpcommingEvents} events={upcommingEvents} />
      </RoundSection>
      <DarkRoundSection>
        <WrapperInfo>
          <Title>{t('home.role_title')}</Title>
          <Paragraph>{t('home.role_description')}</Paragraph>
        </WrapperInfo>
        <Objectives />
        <WrapperCenter>
          <SustainabilityButton>{t('home.button_learn_more')}</SustainabilityButton>
        </WrapperCenter>
      </DarkRoundSection>
      <Section>
        <IteneraryTitle>{t('home.itenerary_title')}</IteneraryTitle>
        <Paragraph>{t('home.itenerary_description')}</Paragraph>
        <WrapperIllustration>
          <Itenerary />
        </WrapperIllustration>
        <WrapperCenter>
          <IteneraryButton>{t('home.button_itenerary')}</IteneraryButton>
        </WrapperCenter>
      </Section>
      <TemplateRecommendation />
      <Footer />
    </Wrapper>
  );
};

export default Home;

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding-bottom: ${({ theme }) => theme.dimensions.navBarHeight};
`;

const Button = styled(StyledButton)`
  max-width: 15.625rem;
`;

const SustainabilityButton = styled(Button)`
  margin-bottom: 0.5rem;
`;

const Title = styled(Heading1)`
  margin-top: 2rem;
`;

const RoundSection = styled.section`
  border-radius: 0 0 1rem 1rem;
  background-color: ${({ theme }) => theme.colors.background};
  padding: 2rem 1rem;
  box-shadow: 0rem 0.25rem 0.75rem 0.25rem rgba(0, 0, 0, 0.2);
  z-index: 2;
`;

const Strong = styled.strong`
  font-weight: bold;
`;

const Paragraph = styled.p`
  font-size: ${({ theme }) => theme.fontSizes.medium};
  font-weight: 300;
  line-height: ${({ theme }) => theme.lineHeights.extraLarge};
  margin-bottom: 1rem;
  color: ${({ theme }) => theme.colors.primary};
`;

const DarkRoundSection = styled(RoundSection)`
  background-color: ${({ theme }) => theme.colors.primary};
  padding: 2rem 0;
  margin-top: -1.25rem;
  z-index: 1;
`;

const WrapperInfo = styled.div`
  padding: 0 1.5rem;
  > * {
    color: ${({ theme }) => theme.colors.white};
  }
`;

const Section = styled.section`
  padding: 2rem 1.5rem;
  margin-top: -1.25rem;
`;

const TemplateRecommendation = styled(RecommendationCard)<{ recommendation?: boolean }>`
  margin: 0 1rem;
  margin-bottom: -1.875rem;
`;

const WrapperHelp = styled.div`
  position: absolute;
  right: 1rem;
`;

const WrapperCenter = styled.div`
  display: flex;
  justify-content: center;
`;

const IteneraryTitle = styled(Title)`
  margin-bottom: 0.125rem;
`;

const IteneraryButton = styled(Button)`
  margin-bottom: 1.5rem;
`;

const WrapperIllustration = styled(WrapperCenter)`
  margin: 1.5rem 0 2rem;
`;

const Footer = styled(EUFooter)`
  padding: 3.5rem 1.5rem 2rem;
  z-index: 1;
`;
