/**
 * LandingPage scene
 *
 * @author Miguel Antunes <mantunes@ubiwhere.com>
 *
 *
 */
import React, { useEffect } from 'react';
import styled from 'styled-components';

import { useDispatch, useSelector } from 'react-redux';

import { actions, RootState } from 'store/rootSlices';
import Splashscreen from './containers/Splashscreen';
import Logo from 'assets/logos/LogoNoText';
import { Button as StyledButton, Heading1, Link as StyledLink } from 'shared/components/ui';

import { authorizeUser } from 'app/utils';

const LandingPage = ({ t, i18n }) => {
  const { loading } = useSelector((state: RootState) => state.LandingPage);
  const { isAuth } = useSelector((state: RootState) => state.App);

  const { onMount, onUnmount } = actions.LandingPage;
  const { navigateTo } = actions.App;

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(onMount());
    return () => {
      dispatch(onUnmount());
    };
  }, [dispatch, onMount, onUnmount]);

  if (loading) return <Splashscreen />;
  if (isAuth) dispatch(navigateTo('/home'));
  return (
    <Wrapper>
      <WrapperLogo>
        <Title>{t('landing_page.title')}</Title>
        <Logo white={1} />
      </WrapperLogo>
      <WrapperButtons>
        <Button onClick={() => authorizeUser()}>{t('landing_page.login')}</Button>
        <Link onClick={() => dispatch(navigateTo(`/home`))}>{t('common.skip')}</Link>
      </WrapperButtons>
      <WrapperTerms>
        <Text
          dangerouslySetInnerHTML={{
            __html: t('landing_page.agreement'),
          }}
        />
      </WrapperTerms>
    </Wrapper>
  );
};

export default LandingPage;

const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: ${({ theme }) => theme.colors.primary};
`;

const WrapperLogo = styled.div`
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  flex: 1;
  padding-bottom: 6.25rem;
  svg {
    height: 72;
    width: 72;
  }
`;

const Title = styled(Heading1)`
  color: ${({ theme }) => theme.colors.white};
  text-align: center;
  margin: 2rem 0;
`;

const WrapperButtons = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 1rem;
`;

const Button = styled(StyledButton)`
  margin-bottom: 1rem;
`;

const Link = styled(StyledLink)`
  margin-top: 0.5rem;
`;

const WrapperTerms = styled.div`
  padding: 4.75rem 2.625rem 3.125rem;
`;

const Text = styled.p`
  color: ${({ theme }) => theme.colors.white};
  text-align: center;
  font-size: ${({ theme }) => theme.fontSizes.small};
  line-height: ${({ theme }) => theme.lineHeights.medium};
  font-weight: 400;

  a {
    font-weight: 700;
    color: ${({ theme }) => theme.colors.white};
    text-decoration: none;
  }
`;
