/**
 * SustainabilityEvaluation container
 *
 * @author Miguel Antunes <mantunes@ubiwhere.com>
 *
 *
 */

import React from 'react';
import TouchCarousel, { clamp } from 'react-touch-carousel';
import PracticeCard from 'scenes/EventDetail/components/PracticeCard';
import { Heading2, Text } from 'shared/components/ui';
import styled from 'styled-components';
import { Sustainability1 } from 'assets/illustrations';
import PracticesCarousel from '../PracticesCarousel';

//import config from 'config'
const SustainabilityEvaluation = ({ t, data }) => {
  const renderPracticeCard = (index) => {
    return (
      <WrapperCard>
        <PracticeCard key={`practice_card_${index}`} practice={data[index]} />
      </WrapperCard>
    );
  };

  return (
    <Wrapper>
      <DarkSection>
        <WrapperSustainability>
          <Illustration>
            <IllustrationBackground />
            <Sustainability simple={1} />
          </Illustration>
        </WrapperSustainability>
        <WrapperText>
          <Title>{t('event_detail.evaluation_title')}</Title>
          <Description>{t('event_detail.evaluation_description')}</Description>
        </WrapperText>
      </DarkSection>
      <Section>
        <WrapperPractices>
          <TouchCarousel
            component={PracticesCarousel}
            cardCount={data.length}
            data={data}
            loop={false}
            carouselWidth={clamp(window.innerWidth, 0, 960)}
            renderCard={renderPracticeCard}
          />
        </WrapperPractices>
      </Section>
    </Wrapper>
  );
};

export default SustainabilityEvaluation;

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding-top: 3.25rem;
  margin-top: 2.5rem;
`;

const DarkSection = styled.div`
  background-color: ${({ theme }) => theme.colors.primary};
`;

const WrapperSustainability = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 3.125rem;
`;

const Illustration = styled.div`
  position: relative;
  height: 6rem;
  width: 6rem;
`;

const Sustainability = styled(Sustainability1)`
  position: absolute;
  bottom: 0;
  right: 0;
`;

const IllustrationBackground = styled.div`
  position: absolute;
  background: ${({ theme }) => theme.colors.background};
  border-radius: 50%;
  margin-right: 0.031rem;
  width: 5.875rem;
  height: 5.875rem;
  bottom: 0;
  right: 0;
`;

const WrapperText = styled.div`
  padding: 2.25rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 9.5rem;
  text-align: center;
  > * {
    color: ${({ theme }) => theme.colors.white};
  }
`;

const Title = styled(Heading2)`
  margin-bottom: 0.5rem;
`;

const Description = styled(Text)``;

const Section = styled.div``;

const WrapperPractices = styled.div`
  overflow-x: scroll;
  width: 100%;
  padding-bottom: 1.5rem;
  cursor: grab;
  margin-top: -9.5rem;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
`;

const WrapperCard = styled.div`
  flex: 0 0 19rem;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-tap-highlight-color: transparent;
`;
