/**
 * Splashscreen container
 *
 * @author Miguel Antunes <mantunes@ubiwhere.com>
 *
 *
 */

import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import Logo from 'assets/logos/LogoHorizontal';
import LogoEvora from 'assets/images/evora-tmp.png';
//import config from 'config'

const Splashscreen = () => {
  const { t } = useTranslation();
  return (
    <Wrapper>
      <WrapperEvora>
        <Evora src={LogoEvora} />
      </WrapperEvora>
      <WrapperPocityf>
        <Text>{t('splashscreen.powered_by')}</Text>
        <Logo white={1} width={143} />
      </WrapperPocityf>
    </Wrapper>
  );
};

export default Splashscreen;

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.primary};
  * {
    color: ${({ theme }) => theme.colors.white};
  }
`;

const WrapperEvora = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Evora = styled.img`
  width: 15.625rem;
  max-height: 9.375rem;
  object-fit: cover;
`;
const WrapperPocityf = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 4rem;
`;

const Text = styled.p`
  font-size: ${({ theme }) => theme.fontSizes.small};
  line-height: ${({ theme }) => theme.lineHeights.medium};
  margin-bottom: -0.625rem;
`;
