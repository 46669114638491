import * as React from 'react';

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M20.694 3.91c-6.072-2.024-5.699-1.926-5.9-1.901-.093.012.349-.13-5.545 1.836l-5.427-1.81A.625.625 0 003 2.63v13.747c0 .269.172.508.427.593 6.053 2.017 5.62 1.874 5.657 1.884a.61.61 0 00.282.011c.065-.012-.199.073 3.538-1.173.404.25.858.425 1.344.507v3.177a.625.625 0 101.25 0v-3.177c.485-.082.94-.257 1.344-.507l3.457 1.152a.625.625 0 00.823-.592V4.503a.625.625 0 00-.428-.593zM14.873 17a2.502 2.502 0 01-2.5-2.499c0-1.378 1.122-2.5 2.5-2.5s2.5 1.122 2.5 2.5c0 1.379-1.122 2.5-2.5 2.5zM4.25 3.497l4.374 1.458v12.43L4.25 15.926V3.496zm5.624 1.458l4.374-1.458v7.308c-2.74.462-4.05 3.675-2.393 5.92l-1.981.66V4.954zm9.998 12.43l-1.981-.66c1.655-2.244.35-5.457-2.393-5.92V3.496l4.374 1.458v12.43z"
        fill={props.fill || '#000'}
      />
    </svg>
  );
}

export default SvgComponent;
