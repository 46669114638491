/**
 * ButtonIcon component
 *
 * @author Miguel Antunes <mantunes@ubiwhere.com>
 *
 *
 */

import styled from 'styled-components';
import Button from '../Button';

export default styled(Button)`
  width: 2.5rem;
  min-width: 2.5rem;
  height: 2.5rem;
  padding: 0.5rem;

  svg {
    width: 1.25rem;
    height: 1.25rem;
  }
`;
