/**
 * App reducers
 * Please write a description
 *
 * @author Carlos Silva <csilva@ubiwhere.com>
 *
 *
 */

import { createSlice } from '@reduxjs/toolkit';
import { AppState } from 'app/types';
import { DEFAULT_USER, GENERATE_TRANSLATIONS } from './../utils';

const initialState: AppState = {
  user: DEFAULT_USER,
  apiTranslationsEdit: false,
  api_t: { API_TRANSLATION_TEST: null },
  isAuth: null,
};

export default createSlice({
  name: 'App',
  initialState,
  reducers: {
    onMount: () => {},
    setUser: (state, { payload }) => {
      state.user = payload;
    },
    setAuth: (state, { payload }) => {
      state.isAuth = payload;
    },
    getUser: () => {},
    getAuthToken: (state, { payload }) => {},
    checkAuthentication: () => {},
    logout: (state) => {
      state.isAuth = null;
    },
    setApiTranslationsEdit: (state, { payload }) => {
      state.apiTranslationsEdit = payload;
    },
    setApiTranslations: (state, { payload }) => {
      state.api_t = GENERATE_TRANSLATIONS(payload);
    },
    navigateTo: (state, { payload }) => {},
    navigateBack: () => {},
    toggleApiTranslationsEdit: () => {},
    updateApiTranslationKey: (state, { payload }) => {},
    updateUserRole: (state, { payload }) => {},
  },
});
