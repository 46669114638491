// Data Types

// Generated by https://quicktype.io

export interface TokenResponse {
  access_token: string;
  refresh_token: string;
  scope: string;
  id_token: string;
  token_type: string;
  expires_in: number;
}

// Generated by https://quicktype.io

export interface APIResponse<T> {
  count: number;
  next: null;
  previous: null;
  results: T;
}

export interface Event {
  id: string;
  name: string;
  alternateName: string;
  description: string;
  descriptionHtml: string;
  categories: EventCategory[];
  subcategories: EventCategory[];
  location: Location;
  address: string;
  startDate: string;
  endDate: string;
  openingHoursSpecification: null | string;
  website: string;
  seeAlso: string;
  poi: null;
  source: string;
  sdg: any[];
}

export interface EventCategory {
  name: CategoryName;
}

export enum CategoryName {
  EXHIBITION = 'Exposição',
  MUSIC = 'Música',
  OTHERS = 'Outros',
  OTHER_EVENTS = 'Outros Eventos',
  CINEMA = 'Cinema',
  THEATER = 'Teatro',
  LITERATURE = 'Literatura',
  DANCE = 'Dança',
  WORKSHOP = 'Formação/Workshop',
  SPORTS = 'Desportiva',
}

export interface Location {
  type: LocationType;
  coordinates: number[];
}

export enum LocationType {
  Point = 'Point',
}

export interface Host {
  name: string;
  id: number;
}

export interface Practice {
  title: string;
  description: string;
  goals: number[];
}

export interface AgentDetail {
  id: number;
  image: string;
  displayName: string;
  certified: boolean;
  description: string;
  sustainabilityRecognition: string;
  website: string;
  practices: Practice[];
}

export interface EventList {
  events: Event[];
}

export interface Event {
  id: number;
  image: string;
  date: string;
  title: string;
  location: string;
  category: string;
  sustainable: boolean;
}

export interface RatingSurvey {
  eventId: number;
  title: string;
  rewardLocation: string;
  queries: SurveyQuery[];
}

export interface SurveyQuery {
  query: string;
  category: string;
}
