import * as React from 'react';

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12 1C8.454 1 5.568 3.876 5.568 7.41c0 4.44 5.806 10.277 6.052 10.522a.536.536 0 00.38.155.536.536 0 00.38-.155c.245-.245 6.052-6.082 6.052-10.523C18.432 3.876 15.546 1 12 1zm0 15.78c-1.281-1.363-5.358-5.98-5.358-9.37 0-2.945 2.403-5.34 5.358-5.34 2.955 0 5.358 2.395 5.358 5.34 0 3.386-4.077 8.007-5.358 9.37z"
        fill={props.fill || '#000'}
      />
      <path
        d="M12 4.202a3.21 3.21 0 00-3.214 3.203A3.21 3.21 0 0012 10.607a3.21 3.21 0 003.214-3.202A3.21 3.21 0 0012 4.202zm0 5.344a2.143 2.143 0 01-2.144-2.137c0-1.178.962-2.136 2.144-2.136 1.182 0 2.144.958 2.144 2.136A2.143 2.143 0 0112 9.546zM23 18.904c0 .85-.595 2.067-3.438 3.03C17.535 22.622 14.847 23 12 23c-2.847 0-5.535-.378-7.562-1.066C1.595 20.971 1 19.754 1 18.904c0-1.105 1.005-1.888 1.846-2.348a.563.563 0 01.815.649.555.555 0 01-.271.336c-.815.447-1.264.928-1.264 1.363 0 .206.108.541.617.945.47.374 1.16.718 2.057 1.019 1.916.649 4.474 1.006 7.2 1.006s5.284-.357 7.2-1.006c.897-.305 1.591-.65 2.057-1.019.51-.408.617-.74.617-.945 0-.435-.448-.92-1.264-1.363a.555.555 0 01-.224-.761.535.535 0 01.336-.267.547.547 0 01.427.048C22 17.016 23 17.799 23 18.904z"
        fill={props.fill || '#000'}
      />
      <path
        d="M19.251 17.898c0 .8-.798 1.427-2.368 1.87-1.311.373-3.045.575-4.883.575s-3.572-.201-4.883-.571c-1.57-.443-2.368-1.07-2.368-1.87s.798-1.427 2.368-1.87c.336-.095.703-.18 1.096-.254.194.336.4.684.612 1.036-.418.065-.807.146-1.16.233-1.079.275-1.622.606-1.76.808l-.035.047.035.047c.138.202.681.533 1.76.808 1.121.284 2.584.452 4.11.465.078.004.152.008.23.008.073 0 .146-.004.228-.008 1.527-.013 2.99-.181 4.11-.465 1.08-.275 1.623-.606 1.76-.808l.035-.047-.034-.047c-.138-.202-.682-.533-1.76-.808-.354-.09-.742-.168-1.16-.233.21-.352.414-.7.612-1.036.393.073.76.16 1.096.254 1.561.439 2.36 1.066 2.36 1.866z"
        fill={props.fill || '#000'}
      />
    </svg>
  );
}

export default SvgComponent;
