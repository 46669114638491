/**
 * Recommendationcard component
 *
 * @author Miguel Antunes <mantunes@ubiwhere.com>
 *
 *
 */

import React from 'react';
import moment from 'moment';
import styled from 'styled-components';

import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { actions } from 'store/rootSlices';

import LogoNoText from 'assets/logos/LogoNoText';
import { Card, Heading3, LinkAction } from 'shared/components/ui';

const RecommendationCard = ({
  className,
  recommendation,
}: {
  className?: any;
  recommendation?: { title: string; status: string; date: string; id: string };
}) => {
  const { navigateTo } = actions.App;

  const { t } = useTranslation();
  const dispatch = useDispatch();
  return (
    <Wrapper className={className}>
      <Heading3>{recommendation ? recommendation.title : t('recommendation.card_title')}</Heading3>
      <Description>
        {recommendation
          ? `${t('recommendation.card_status')}: `
          : t('recommendation.card_description')}
        {recommendation && <Status>{recommendation.status}</Status>}
      </Description>
      {recommendation && (
        <SubmissionDate>
          {`${t('recommendation.card_submitted')}: ${moment(recommendation.date).fromNow()}`}
        </SubmissionDate>
      )}
      <Link
        onClick={() => {
          dispatch(navigateTo(`recommendations${recommendation && '/' + recommendation.id}`));
        }}
      >
        {t('recommendation.card_link')}
      </Link>
      <WrapperLogo>
        <LogoHidden>
          <LogoNoText white={1} />
        </LogoHidden>
      </WrapperLogo>
    </Wrapper>
  );
};

export default RecommendationCard;

const Wrapper = styled(Card)`
  background-color: ${({ theme }) => theme.colors.primary};
  padding: 1.5rem;
  z-index: 2;
  position: relative;
  overflow: hidden;

  h3,
  p {
    color: ${({ theme }) => theme.colors.white};
  }
`;

const Description = styled.p`
  font-weight: 500;
  font-size: ${({ theme }) => theme.fontSizes.large};
  line-height: ${({ theme }) => theme.lineHeights.large};
  margin-top: 0.5rem;
  letter-spacing: 0.013rem;
`;

const Status = styled.label`
  font-weight: 300;
`;

const SubmissionDate = styled.p`
  margin-top: 0.5rem;
  font-size: ${({ theme }) => theme.fontSizes.small};
  line-height: ${({ theme }) => theme.lineHeights.medium};
  letter-spacing: 0.013rem;
  color: ${({ theme }) => theme.colors.gray} !important;
`;

const Link = styled(LinkAction)`
  margin-top: 1.25rem;
`;

const WrapperLogo = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const LogoHidden = styled.div`
  position: absolute;
  bottom: -2.25rem;
`;
