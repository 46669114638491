/**
 * Menu scene
 *
 * @author Miguel Antunes <mantunes@ubiwhere.com>
 *
 *
 */
import React, { useEffect } from 'react';
import styled from 'styled-components';

import { useDispatch /*useSelector*/ } from 'react-redux';

import theme from 'ui/theme';
import { actions } from 'store/rootSlices';
import { Heading1, Tile as StyledTile } from 'shared/components/ui';
import { Account, Docs, FlagEU, Information, Itinerary, Phone, Settings } from 'assets/icons';

//import config from 'config'

const Menu = ({ t, i18n }) => {
  //const { } = useSelector(state => state.Menu)

  const { onMount, onUnmount } = actions.Menu;
  const { navigateTo } = actions.App;

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(onMount());
    return () => {
      dispatch(onUnmount());
    };
  }, [dispatch, onMount, onUnmount]);

  return (
    <Wrapper>
      <Title>{t('menu.title')}</Title>
      <WrapperTiles>
        <Tile onClick={() => dispatch(navigateTo(`/menu/account`))}>
          <Account fill={theme.colors.white} />
          <Label>{t('menu.account')}</Label>
        </Tile>
        <Tile onClick={() => dispatch(navigateTo(`/menu/itineraries`))} type="cancel">
          <Itinerary fill={theme.colors.white} />
          <Label>{t('menu.itineraries')}</Label>
        </Tile>
      </WrapperTiles>
      <WrapperTiles>
        <Tile onClick={() => dispatch(navigateTo(`/menu/about`))} type="cancel">
          <Information fill={theme.colors.white} />
          <Label>{t('menu.about')}</Label>
        </Tile>
        <Tile onClick={() => dispatch(navigateTo(`/menu/settings`))}>
          <Settings fill={theme.colors.white} />
          <Label>{t('menu.settings')}</Label>
        </Tile>
      </WrapperTiles>
      <WrapperTiles>
        <Tile onClick={() => dispatch(navigateTo(`/menu/termsandconditions`))}>
          <Docs fill={theme.colors.white} />
          <Label>{t('menu.terms')}</Label>
        </Tile>
        <Tile onClick={() => dispatch(navigateTo(`/menu/contacts`))} type="cancel">
          <Phone fill={theme.colors.white} />
          <Label>{t('menu.contacts')}</Label>
        </Tile>
      </WrapperTiles>
      <Footer>
        <WrapperFlag>
          <FlagEU width={31} />
        </WrapperFlag>
        <FundingText>{t('home.funding_eu')}</FundingText>
      </Footer>
    </Wrapper>
  );
};

export default Menu;

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.primary};
  padding-bottom: ${({ theme }) => theme.dimensions.navBarHeight}; ;
`;

const Title = styled(Heading1)`
  margin-top: 4rem;
  margin-left: 1.5rem;
  color: ${({ theme }) => theme.colors.white};
`;

const WrapperTiles = styled.div`
  display: flex;
  flex-direction: row;
  padding: 1.5rem 1.5rem 0 1.5rem;
  justify-content: space-around;
`;

const Tile = styled(StyledTile)`
  width: 8.75rem;
  height: 8.75rem;
  svg {
    width: 2.5rem;
    height: 2.5rem;
  }
`;

const Label = styled.label`
  font-weight: 500;
  font-size: ${({ theme }) => theme.fontSizes.medium};
  line-height: ${({ theme }) => theme.lineHeights.extraLarge};
  text-align: center;
  text-transform: uppercase;
  margin-top: 1rem;
`;

const Footer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 2rem;
`;

const WrapperFlag = styled.div`
  min-width: 2rem;
  margin-right: 1rem;
`;

const FundingText = styled.p`
  color: ${({ theme }) => theme.colors.white};
  font-size: ${({ theme }) => theme.fontSizes.superSmall};
  line-height: ${({ theme }) => theme.lineHeights.small};
`;
