const deviceSize = {
  small: '320px',
  medium: '375px',
  large: '425px',
};

export default {
  colors: {
    primary: '#00264B',
    primaryDark: '#001F3D',
    primaryMedium: '#1961AC',
    secondary: '#0099FF',
    highlight: '#E6007E',
    background: '#FFFFFF',
    white: '#FFFFFF',
    green: '#009641',
    mint: '#28D8A2',
    yellow: '#F9DD4B',
    orange: '#EBA83B',
    red: '#DE261C',
    gray: '#C6C6C6',
    darkGray: '#979797',
    darkerGray: '#4A4A4A',
  },
  fontSizes: {
    superSmall: '0.5rem', // 8px
    extraSmall: '0.625rem', // 10px
    small: '0.75rem', // 12px
    medium: '0.875rem', // 14px
    large: '1rem', // 16px
    extraLarge: '1.25rem', // 20px
    extraExtraLarge: '1.5rem', // 24px
    superLarge: '2rem', // 32px
  },
  lineHeights: {
    extraSmall: '0.5625rem', // 9px
    small: '0.875rem', // 14px
    medium: '1rem', // 16px
    large: '1.375rem', // 22px
    extraLarge: '1.5rem', // 24px
    extraExtraLarge: '2rem', // 32px
    superLarge: '2.375rem', // 38px
  },
  dimensions: {
    navBarHeight: '5.3125rem', // 85px
  },
  device: {
    small: `(min-width: ${deviceSize.small})`,
    medium: `(min-width: ${deviceSize.medium})`,
    large: `(min-width: ${deviceSize.large})`,
  },
};
