import { combineReducers } from 'redux';

import appSlice from 'app/logic/slice';
import appToolsSlice from 'app/AppTools/logic/slice';
import dashboardSlice from 'scenes/Dashboard/logic/slice';
import settingsSlice from 'scenes/Settings/logic/slice';
// WARNING!! Don't erase the nextline as it is necessary for hygen to automatically import your components
// Define imports here
import achievementsSlice from 'scenes/Account/containers/Achievements/logic/slice';
import itinerariesSlice from 'scenes/Itineraries/logic/slice';
import accountSlice from 'scenes/Account/logic/slice';
import registerSlice from 'scenes/Register/logic/slice';
import loginSlice from 'scenes/Login/logic/slice';
import landingPageSlice from 'scenes/LandingPage/logic/slice';
import recommendationDetailSlice from 'scenes/RecommendationDetail/logic/slice';
import eventRatingSlice from 'scenes/EventRating/logic/slice';
import agentDetailSlice from 'scenes/AgentDetail/logic/slice';
import eventDetailSlice from 'scenes/EventDetail/logic/slice';
import newRecommendationSlice from 'scenes/NewRecommendation/logic/slice';
import menuSlice from 'scenes/Menu/logic/slice';
import recommendationsSlice from 'scenes/Recommendations/logic/slice';
import eventsSlice from 'scenes/Events/logic/slice';
import exploreSlice from 'scenes/Explore/logic/slice';
import homeSlice from 'scenes/Home/logic/slice';
import navbarSlice from 'shared/containers/Navbar/logic/slice';

export const rootReducer = combineReducers({
  Achievements: achievementsSlice.reducer,
  Itineraries: itinerariesSlice.reducer,
  Account: accountSlice.reducer,
  Register: registerSlice.reducer,
  Login: loginSlice.reducer,
  LandingPage: landingPageSlice.reducer,
  RecommendationDetail: recommendationDetailSlice.reducer,
  EventRating: eventRatingSlice.reducer,
  AgentDetail: agentDetailSlice.reducer,
  EventDetail: eventDetailSlice.reducer,
  NewRecommendation: newRecommendationSlice.reducer,
  Menu: menuSlice.reducer,
  Recommendations: recommendationsSlice.reducer,
  Events: eventsSlice.reducer,
  Explore: exploreSlice.reducer,
  Home: homeSlice.reducer,
  Navbar: navbarSlice.reducer,
  App: appSlice.reducer,
  AppTools: appToolsSlice.reducer,
  Dashboard: dashboardSlice.reducer,
  Settings: settingsSlice.reducer,
});

export type RootState = ReturnType<typeof rootReducer>;

export const actions = {
  Achievements: achievementsSlice.actions,
  Itineraries: itinerariesSlice.actions,
  Account: accountSlice.actions,
  Register: registerSlice.actions,
  Login: loginSlice.actions,
  LandingPage: landingPageSlice.actions,
  RecommendationDetail: recommendationDetailSlice.actions,
  EventRating: eventRatingSlice.actions,
  AgentDetail: agentDetailSlice.actions,
  EventDetail: eventDetailSlice.actions,
  NewRecommendation: newRecommendationSlice.actions,
  Menu: menuSlice.actions,
  Recommendations: recommendationsSlice.actions,
  Events: eventsSlice.actions,
  Explore: exploreSlice.actions,
  Home: homeSlice.actions,
  Navbar: navbarSlice.actions,
  App: appSlice.actions,
  AppTools: appToolsSlice.actions,
  Dashboard: dashboardSlice.actions,
  Settings: settingsSlice.actions,
};
