import * as React from 'react';

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12 17.4c-.358 0-.717-.136-.99-.41l-8.6-8.6a1.4 1.4 0 111.981-1.98L12 14.02l7.61-7.61a1.4 1.4 0 011.98 1.981l-8.6 8.6c-.273.273-.632.41-.99.41z"
        fill={props.fill || '#000'}
      />
    </svg>
  );
}

export default SvgComponent;
