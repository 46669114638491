/**
 * RatingSurvey container
 *
 * @author Miguel Antunes <mantunes@ubiwhere.com>
 *
 *
 */

import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled, { css } from 'styled-components';
import { Carousel } from 'react-responsive-carousel';
import { useTranslation } from 'react-i18next';

import { actions, RootState } from 'store/rootSlices';
import { Heading2, Link, Subtitle } from 'shared/components/ui';
import { Check, No } from 'assets/icons';

const RatingSurvey = () => {
  const { title, queries, currentQuery } = useSelector((state: RootState) => state.EventRating);
  const dispatch = useDispatch();
  const { setAnswer, setCurrentQuery } = actions.EventRating;
  const { t } = useTranslation();
  if (!queries) return null;
  return (
    <Wrapper>
      <Info>
        <Title>{title}</Title>
        <Category>{queries[currentQuery].category}</Category>
      </Info>
      <Carousel
        renderIndicator={() => null}
        showStatus={false}
        onChange={(index) => dispatch(setCurrentQuery({ index }))}
        emulateTouch
      >
        {queries.map((query, index) => (
          <WrapperQuery key={`query_${index}`}>
            <Query>{queries[index].query}</Query>
            <Answers>
              <AnswerButton
                onClick={() => dispatch(setAnswer({ query: index, answer: 'no' }))}
                selected={queries[index].answer === 'no'}
                highlighted
              >
                <No />
              </AnswerButton>
              <AnswerButton
                onClick={() => dispatch(setAnswer({ query: index, answer: 'yes' }))}
                selected={queries[index].answer === 'yes'}
              >
                <Check />
              </AnswerButton>
            </Answers>
            <Link>{t('rating.not_sure')}</Link>
          </WrapperQuery>
        ))}
      </Carousel>
      <CarouselIndicator>
        {queries.map((circle, index) => (
          <Indicator key={`indicator_${index}`} active={index === currentQuery} />
        ))}
      </CarouselIndicator>
    </Wrapper>
  );
};

export default RatingSurvey;

const Wrapper = styled.div`
  padding: 1.5rem 1rem 0;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
`;

const Info = styled.div``;

const WrapperQuery = styled.div`
  text-align: center;
`;

const Title = styled(Heading2)`
  margin-bottom: 0.25rem;
`;

const Category = styled(Subtitle)`
  color: ${({ theme }) => theme.colors.primaryMedium};
`;

const Query = styled(Heading2)`
  text-align: center;
`;

const Answers = styled.div`
  display: flex;
  justify-content: center;
  margin: 2.5rem 0 4rem;
`;

const AnswerButton = styled.div<{ selected: boolean; highlighted?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 1rem;
  width: 5rem;
  height: 5rem;
  margin: 0 1.5rem;
  background: ${({ theme, selected, highlighted }) =>
    selected ? (highlighted ? theme.colors.highlight : theme.colors.secondary) : theme.colors.gray};
  svg path {
    fill: ${({ theme, selected }) => (selected ? theme.colors.white : theme.colors.primary)};
  }
  svg {
    width: 2rem;
    height: 2rem;
  }
`;

const Indicator = styled.div<{ active: boolean }>`
  width: 0.375rem;
  height: 0.375rem;
  background: ${({ theme }) => theme.colors.gray};
  border-radius: 50%;
  ${({ active }) =>
    !active &&
    css`
      mix-blend-mode: normal;
      opacity: 0.4;
    `}
`;

const CarouselIndicator = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 2.5rem;
  justify-content: center;

  > ${Indicator} {
    margin-right: 0.625rem;
  }
  :last-child {
    margin-right: 0;
  }
`;
