/**
 * Contacts scene
 *
 * @author Miguel Antunes <mantunes@ubiwhere.com>
 *
 *
 */
import React from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';

import { actions } from 'store/rootSlices';
import { Back } from 'assets/icons';
import { Heading2, Heading3, Text } from 'shared/components/ui';

const Contact = ({ contact }) => {
  const { name, email } = contact;
  return (
    <WrapperContact>
      <ContactName>{name}</ContactName>
      <ContactEmail>{email}</ContactEmail>
    </WrapperContact>
  );
};

const Contacts = ({ t, i18n }) => {
  const { navigateBack } = actions.App;
  const dispatch = useDispatch();

  return (
    <Wrapper>
      <Section>
        <Return
          onClick={() => {
            dispatch(navigateBack());
          }}
        >
          <Back />
        </Return>
        <Title>{t('contacts.title')}</Title>
        <Description>{t('contacts.description')}</Description>
        <ContactList>
          <ListTitle>{t('contacts.evora_team')}</ListTitle>
          <Contact contact={{ name: 'João Formiga', email: 'joao.formiga@edp.pt' }} />
          <Contact contact={{ name: 'Nuno Bilo', email: 'nuno.choraobilo@cm-evora.pt' }} />
        </ContactList>
      </Section>
      <SectionBottom>
        <ContactList>
          <ListTitle>{t('contacts.project_coordinator')}</ListTitle>
          <Contact contact={{ name: 'João Formiga', email: 'joao.formiga@edp.pt' }} />
        </ContactList>
      </SectionBottom>
    </Wrapper>
  );
};

export default Contacts;

const Wrapper = styled.div`
  padding-bottom: ${({ theme }) => theme.dimensions.navBarHeight}; ;
`;

const Section = styled.div`
  border-radius: 0 0 1rem 1rem;
  background-color: ${({ theme }) => theme.colors.background};
  padding: 3.25rem 1rem 2.5rem;
  box-shadow: 0rem 0.25rem 0.75rem 0.25rem rgba(0, 0, 0, 0.2);
  z-index: 2;
`;

const SectionBottom = styled.div`
  padding: 3rem 1rem;
`;
const Return = styled.div`
  margin-bottom: 1rem;
  svg path {
    fill: ${({ theme }) => theme.colors.primary};
  }
`;
const Title = styled(Heading2)`
  margin-bottom: 1rem;
`;
const Description = styled(Text)`
  margin-bottom: 2rem;
`;
const ListTitle = styled(Heading3)`
  margin-bottom: 1.5rem;
  color: ${({ theme }) => theme.colors.highlight};
`;
const WrapperContact = styled.div`
  padding-left: 1rem;
`;
const ContactList = styled.div`
  > ${WrapperContact} {
    margin-bottom: 1.5rem;
    :last-child {
      margin-bottom: 0;
    }
  }
`;
const ContactName = styled(Heading3)`
  margin-bottom: 0.5rem;
`;
const ContactEmail = styled.p``;
