import * as React from 'react';

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12 2.032c1.345 0 2.652.262 3.882.782A9.953 9.953 0 0119.05 4.95a9.953 9.953 0 012.136 3.168A9.91 9.91 0 0121.968 12a9.91 9.91 0 01-.782 3.882 9.953 9.953 0 01-2.136 3.168 9.953 9.953 0 01-3.168 2.136 9.91 9.91 0 01-3.882.782 9.91 9.91 0 01-3.882-.782A9.953 9.953 0 014.95 19.05a9.953 9.953 0 01-2.136-3.168A9.909 9.909 0 012.032 12c0-1.345.262-2.652.782-3.882A9.953 9.953 0 014.95 4.95a9.953 9.953 0 013.168-2.136A9.909 9.909 0 0112 2.032zM12 1C5.926 1 1 5.926 1 12s4.926 11 11 11 11-4.926 11-11S18.078 1 12 1z"
        fill={props.fill || '#000'}
      />
      <path
        d="M12 17.876a.604.604 0 01-.602-.602v-6.993h-.77a.604.604 0 01-.601-.602c0-.331.27-.602.602-.602H12c.331 0 .602.27.602.602v7.595a.6.6 0 01-.602.602zM12.968 7.24a.98.98 0 10-1.935-.315.98.98 0 001.935.314z"
        fill={props.fill || '#000'}
      />
      <path
        d="M13.285 17.898H10.72a.604.604 0 01-.602-.602c0-.331.271-.602.602-.602h2.566c.331 0 .602.27.602.602 0 .33-.27.602-.602.602z"
        fill={props.fill || '#000'}
      />
    </svg>
  );
}

export default SvgComponent;
