/**
 * Navbar container
 *
 * @author Miguel Antunes <mantunes@ubiwhere.com>
 *
 *
 */

import React, { useEffect } from 'react';
import styled from 'styled-components';

import { useDispatch /*useSelector*/ } from 'react-redux';

import { actions } from 'store/rootSlices';
import NavTab from 'shared/components/ui/NavTab';

//import config from 'config'

const Navbar = (props) => {
  const { t } = props;
  //const { } = useSelector(state => state.Navbar)

  const { onMount, onUnmount } = actions.Navbar;
  //const { api_t } = actions.App

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(onMount());
    return () => {
      dispatch(onUnmount());
    };
  }, [dispatch, onMount, onUnmount]);

  return (
    <Wrapper>
      <NavTab route="home" t={t} />
      <NavTab route="explore" t={t} />
      <NavTab route="events" t={t} />
      <NavTab route="recommendations" t={t} />
      <NavTab route="menu" t={t} />
    </Wrapper>
  );
};

export default Navbar;

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  background-color: ${({ theme }) => theme.colors.white};
  width: 100%;
  justify-content: space-around;
  padding: 1.25rem 0 2.188rem 0;
  position: fixed;
  bottom: 0;
  box-shadow: 0rem -0.188rem 0.375rem rgba(0, 0, 0, 0.1);
  z-index: 1000;
`;
