/**
 * Navbar container sagas
 *
 * @author Miguel Antunes <mantunes@ubiwhere.com>
 *
 *
 */

import { takeLatest } from 'redux-saga/effects';

//import { actions } from 'store/rootSlices'

function* onMountSaga({ payload }) {}

function* onUnmountSaga({ payload }) {}

export default function* watcherSignin() {
  yield takeLatest('Navbar/onMount', onMountSaga);
  yield takeLatest('Navbar/onUnmount', onUnmountSaga);
}
