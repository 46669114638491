/**
 * App sagas
 * Please write a description
 *
 * @author Carlos Silva <csilva@ubiwhere.com>
 *
 *
 */

import { call, takeLatest, putResolve, select } from 'redux-saga/effects';
import { history } from 'utils';
import API from 'api';
import Cookies from 'js-cookie';

import { actions } from 'store/rootSlices';
import config from 'config';
import { secondsFromNow } from 'app/utils';
//import { setLanguageInterceptor } from 'interceptors';

function* onMountSaga() {
  console.log('onMountApp');
  yield putResolve(
    actions.App.setApiTranslations({ API_TRANSLATION_TEST: ' Api translation Test' })
  );
  yield putResolve(actions.App.getUser());
  const access_token = yield Cookies.get('access_token');
  if (!access_token) yield call(checkAuthenticationSaga);
  //yield setLanguageInterceptor(i18n.language);
}

function* getAuthTokenSaga({ payload }: ReturnType<any>) {
  try {
    const response = yield call(API.Auth.token, { code: payload.code });
    if (
      response.status === 200 &&
      response.data != null &&
      response.data.access_token &&
      response.data.refresh_token
    ) {
      console.log('seconds from now', secondsFromNow(response.data.expires_in));
      yield Cookies.set('access_token', response.data.access_token, {
        expires: secondsFromNow(response.data.expires_in),
        domain: config.DOMAIN,
        secure: config.DOMAIN !== 'localhost',
      });
      yield Cookies.set('refresh_token', response.data.refresh_token, {
        domain: config.DOMAIN,
        secure: config.DOMAIN !== 'localhost',
      });
      yield putResolve(actions.App.getUser());
      yield putResolve(actions.App.navigateTo('/home'));
    }
  } catch (error) {
    yield console.log('getAuthTokenSaga error:', error);
    yield putResolve(actions.App.logout());
  }
}

function* checkAuthenticationSaga() {
  const refresh_token = yield Cookies.get('refresh_token');
  if (refresh_token) {
    try {
      const response = yield call(API.Auth.refresh, { refresh_token: refresh_token });
      if (
        response.status === 200 &&
        response.data != null &&
        response.data.access_token &&
        response.data.refresh_token
      ) {
        yield Cookies.set('access_token', response.data.access_token, {
          expires: secondsFromNow(response.data.expires_in),
          domain: config.DOMAIN,
          secure: config.DOMAIN !== 'localhost',
        });
        yield Cookies.set('refresh_token', response.data.refresh_token, {
          domain: config.DOMAIN,
          secure: config.DOMAIN !== 'localhost',
        });
        yield putResolve(actions.App.getUser());
      }
    } catch (error) {
      yield console.log('checkAuthenticationSaga error:', error);
      yield putResolve(actions.App.logout());
    }
  } else {
    yield putResolve(actions.App.logout());
  }
}

function* getUserSaga() {
  try {
    const response = yield call(API.Auth.getUser);
    if (response.status === 200 && response.data != null) {
      yield putResolve(actions.App.setAuth(response.data));
    }
  } catch (error) {
    yield console.log('getUserSaga error:', error);
    yield putResolve(actions.App.logout());
  }
}

function* logoutSaga() {
  console.log('logged out');
  yield Cookies.remove('access_token');
  yield Cookies.remove('refresh_token');
  yield putResolve(actions.App.navigateTo('/'));
}

function* navigateToSaga({ payload }: ReturnType<any>) {
  yield call(history.push, payload);
}

function* navigateBackSaga() {
  yield call(history.goBack);
}

function* toggleApiTranslationsEditSaga() {
  const { App } = yield select((state) => state);
  yield putResolve(actions.App.setApiTranslationsEdit(!App.apiTranslationsEdit));
}

function* updateApiTranslationKeySaga({ payload }: ReturnType<any>) {
  yield putResolve(actions.App.setApiTranslations({ API_TRANSLATION_TEST: payload }));
}

function* updateUserRoleSaga({ payload }: ReturnType<any>) {
  //alert('Update user role')
}

export default function* watcherSignin() {
  yield takeLatest('App/onMount', onMountSaga);
  yield takeLatest('App/getAuthToken', getAuthTokenSaga);
  yield takeLatest('App/checkAuthentication', checkAuthenticationSaga);
  yield takeLatest('App/getUser', getUserSaga);
  yield takeLatest('App/logout', logoutSaga);
  yield takeLatest('App/navigateTo', navigateToSaga);
  yield takeLatest('App/navigateBack', navigateBackSaga);
  yield takeLatest('App/toggleApiTranslationsEdit', toggleApiTranslationsEditSaga);
  yield takeLatest('App/updateApiTranslationKey', updateApiTranslationKeySaga);
  yield takeLatest('App/updateUserRole', updateUserRoleSaga);
}
