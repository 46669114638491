/**
 * Link component
 *
 * @author Miguel Antunes <mantunes@ubiwhere.com>
 *
 *
 */

import styled from 'styled-components';

export default styled.a`
  font-weight: 500;
  font-size: ${({ theme }) => theme.fontSizes.medium};
  line-height: ${({ theme }) => theme.lineHeights.small};
  letter-spacing: 0.013rem;
  text-decoration-line: underline;
  color: ${({ theme }) => theme.colors.secondary};
  svg path {
    fill: ${({ theme }) => theme.colors.secondary};
  }
`;
