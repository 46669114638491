import config from 'config';

const { API_URL, OAUTH2 } = config;

export default {
  AUTH: {
    AUTHORIZE: `${OAUTH2.URL}/authorize`,
    TOKEN: `${OAUTH2.URL}/token`,
    USER: `${OAUTH2.URL}/userinfo`,
  },
  EVENT: {
    DEFAULT: `${API_URL}/event/`,
    QUESTIONS: `${API_URL}/event/questions`,
    REVIEW: `${API_URL}/event/review`,
  },
  FEEDBACK: `${API_URL}/feedback`,
  ME: {
    AGENDA: `${API_URL}/me/agenda`,
    REWARDS: `${API_URL}/me/rewards`,
  },
  POIS: `${API_URL}/pois`,
  REPLY: `${API_URL}/reply`,
  SDG: `${API_URL}/sdg`,
  RECOMMENDATION: `${API_URL}/sugestion`,
  RANKING: `${API_URL}/user/ranking`,
};
