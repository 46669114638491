/**
 * Subtitle component
 *
 * @author Miguel Antunes <mantunes@ubiwhere.com>
 *
 *
 */

import styled from 'styled-components';

export default styled.h4`
  font-weight: bold;
  font-size: ${({ theme }) => theme.fontSizes.large};
  line-height: ${({ theme }) => theme.lineHeights.large};
  letter-spacing: 0.013rem;
  color: ${({ theme }) => theme.colors.primary};
`;
