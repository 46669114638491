/**
 * Navtab component
 *
 * @author Miguel Antunes <mantunes@ubiwhere.com>
 *
 *
 */

import React from 'react';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

import HomeIcon from 'assets/icons/Home';
import MapIcon from 'assets/icons/Map';
import CalendarIcon from 'assets/icons/Calendar';
import RecommendationsIcon from 'assets/icons/Recommendations';
import MenuIcon from 'assets/icons/Menu';

const Navtab = ({ route, t }) => {
  const Icon = ({ route }) => {
    switch (route) {
      case 'home':
        return <HomeIcon />;
      case 'explore':
        return <MapIcon />;
      case 'events':
        return <CalendarIcon />;
      case 'recommendations':
        return <RecommendationsIcon />;
      case 'menu':
        return <MenuIcon />;
      default:
        return <></>;
    }
  };

  return (
    <Tab to={`/${route}`}>
      <Icon route={route} />
      <Label>{t(`navbar.${route}`)}</Label>
    </Tab>
  );
};

export default Navtab;

const Tab = styled(NavLink)`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: ${({ theme }) => theme.colors.darkGray};
  text-decoration: none;

  svg {
    width: 1.125rem;
    height: 1.125rem;
    path {
      fill: ${({ theme }) => theme.colors.darkGray};
    }
  }

  &.active {
    color: ${({ theme }) => theme.colors.primaryMedium};

    svg path {
      fill: ${({ theme }) => theme.colors.primaryMedium};
    }
  }
`;

const Label = styled.div`
  font-weight: 400;
  font-size: ${({ theme }) => theme.fontSizes.extraSmall};
  font-variant: normal;
  margin-top: 0.25rem;
  letter-spacing: 0.013rem;
  text-transform: uppercase;
`;
