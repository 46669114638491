/**
 * ProgressBar component
 *
 * @author Miguel Antunes <mantunes@ubiwhere.com>
 *
 *
 */

import React from 'react';
import styled from 'styled-components';
import { Point } from 'shared/components/ui';

const ProgressBar = ({ points, totalPoints }) => {
  return (
    <Wrapper>
      <Points>
        {Array.from(Array(totalPoints)).map((point, index) => (
          <Point active={index < points} key={index} />
        ))}
      </Points>
      <Line />
    </Wrapper>
  );
};

export default ProgressBar;

const Wrapper = styled.div`
  position: relative;
  height: 2rem;
`;
const Points = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  z-index: 4;
`;
const Line = styled.div`
  width: 100%;
  position: absolute;
  background-color: ${({ theme }) => theme.colors.white};
  height: 0.0625rem;
  top: 50%;
  z-index: 1;
`;
