/**
 * AgentDetail scene sagas
 *
 * @author Miguel Antunes <mantunes@ubiwhere.com>
 *
 *
 */
import { call, put, takeLatest } from 'redux-saga/effects';
import API from 'api';

import { actions } from 'store/rootSlices';

function* onMountSaga({ payload }) {
  console.log('get/agent/', payload.id);
  const response = yield call(API.Events.getAgentDetail, { id: payload.id });
  yield put(actions.AgentDetail.setAgentDetail(response));
}

function* onUnmountSaga({ payload }) {}

export default function* watcherSignin() {
  yield takeLatest('AgentDetail/onMount', onMountSaga);
  yield takeLatest('AgentDetail/onUnmount', onUnmountSaga);
}
