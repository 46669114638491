/**
 * About scene
 *
 * @author Miguel Antunes <mantunes@ubiwhere.com>
 *
 *
 */
import React from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';

import { actions } from 'store/rootSlices';
import { Back } from 'assets/icons';
import { Heading2, Heading3, Text } from 'shared/components/ui';
import EUFooter from 'shared/components/EUFooter';

//import config from 'config'

const About = ({ t }) => {
  const { navigateBack } = actions.App;
  const dispatch = useDispatch();

  return (
    <Wrapper>
      <WrapperBody>
        <Header>
          <Return
            onClick={() => {
              dispatch(navigateBack());
            }}
          >
            <Back />
          </Return>
          <Title>{t('about.title')}</Title>
        </Header>
        <WrapperPOCITYF>
          <TitlePOCITYF>{t('about.pocityf_title')}</TitlePOCITYF>
          <Description>{t('about.pocityf_description')}</Description>
        </WrapperPOCITYF>
        <Image />
        <WrapperCEM>
          <TitleCEM>{t('about.cem_title')}</TitleCEM>
          <Description>{t('about.cem_description')}</Description>
        </WrapperCEM>
      </WrapperBody>
      <EUFooter />
    </Wrapper>
  );
};

export default About;

const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 3.25rem 0 ${({ theme }) => theme.dimensions.navBarHeight};
`;

const WrapperBody = styled.div``;

const Header = styled.div`
  padding: 0 1rem 0;
`;

const Return = styled.div`
  margin-bottom: 1rem;
  svg path {
    fill: ${({ theme }) => theme.colors.primary};
  }
`;

const Title = styled(Heading2)`
  margin-bottom: 1rem;
`;

const WrapperPOCITYF = styled.div`
  padding: 0 1rem;
  margin-bottom: 2rem;
`;

const TitlePOCITYF = styled(Heading3)`
  color: ${({ theme }) => theme.colors.highlight};
  margin-bottom: 0.5rem;
`;

const TitleCEM = styled(Heading3)`
  color: ${({ theme }) => theme.colors.secondary};
  margin-bottom: 0.5rem;
`;

const Image = styled.div`
  background-image: url('https://www.batepe.pt/wp-content/uploads/elementor/thumbs/frank-nurnberger-srlmnorX2g8-unsplash-scaled-owutokhwiet7c0l8utxt6thhxwxna20bbtyd6cerj4.jpg');
  width: 80%;
  background-size: cover;
  background-position: right;
  height: 28.125rem;
  border-radius: 0px 16px 0px 0px;
`;

const WrapperCEM = styled.div`
  margin: -4rem 0 2.5rem 3rem;
  padding: 1.5rem 1rem 0 1.5rem;
  border-radius: 16px 0px 0px 0px;
  background-color: ${({ theme }) => theme.colors.white};
`;

const Description = styled(Text)``;
