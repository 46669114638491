/**
 * AgentDetail scene
 *
 * @author Miguel Antunes <mantunes@ubiwhere.com>
 *
 *
 */
import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';

import { actions, RootState } from 'store/rootSlices';
import { AgentBadge, Back } from 'assets/icons';
import { Heading2, Heading3, Link, Text } from 'shared/components/ui';

//import config from 'config'

const AgentDetail = ({ t, i18n }) => {
  const { agent } = useSelector((state: RootState) => state.AgentDetail);
  const { id } = useParams();

  const { onMount, onUnmount } = actions.AgentDetail;
  const { navigateBack } = actions.App;

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(onMount({ id }));
    return () => {
      dispatch(onUnmount());
    };
  }, [dispatch, onMount, onUnmount, id]);

  if (agent)
    return (
      <Wrapper>
        <Header>
          <WrapperTitle>
            <Return
              onClick={() => {
                dispatch(navigateBack());
              }}
            >
              <Back />
            </Return>
            <Title>{agent.displayName}</Title>
            {agent.certified && <CertifiedAgent>{t('agent_detail.certified')}</CertifiedAgent>}
          </WrapperTitle>
          {agent.certified && <AgentBadge height={56} width={56} />}
        </Header>
        <Description>{agent.description}</Description>
        <Image src={agent.image} />
        <Sustainability>
          <Subtitle>{t('agent_detail.sustainability_recognition')}</Subtitle>
          <SustainabilityRecognition>{agent.sustainabilityRecognition}</SustainabilityRecognition>
          <Practices>
            {agent.practices.map((practice, index) => (
              <Practice key={`practice_${index}`}>
                <PracticeTitle>{practice.title}</PracticeTitle>
                <PracticeDescription>{practice.description}</PracticeDescription>
              </Practice>
            ))}
          </Practices>
          <Website>{agent.website}</Website>
        </Sustainability>
      </Wrapper>
    );

  return <Wrapper>Loading...</Wrapper>;
};

export default AgentDetail;

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding-bottom: ${({ theme }) => theme.dimensions.navBarHeight};
`;

const Header = styled.div`
  background: ${({ theme }) => theme.colors.primary};
  padding: 3.25rem 1rem 1.5rem;
  height: 6.5rem;
  border-radius: 0 0 1rem 1rem;
  box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: space-between;
  * {
    color: ${({ theme }) => theme.colors.white};
  }
`;

const Return = styled.div`
  margin-bottom: 1rem;
  svg path {
    fill: ${({ theme }) => theme.colors.white};
  }
`;

const Title = styled(Heading2)``;
const WrapperTitle = styled.div``;

const CertifiedAgent = styled(Text)``;
const Description = styled(Text)`
  padding: 1.5rem 1rem;
`;
const Image = styled.img`
  max-height: 15rem;
`;
const Sustainability = styled.div`
  padding: 2.5rem 1rem;
`;
const Subtitle = styled(Heading3)`
  margin-bottom: 0.75rem;
`;
const SustainabilityRecognition = styled(Text)`
  margin-bottom: 1.5rem;
`;
const Practices = styled.div``;
const Practice = styled.div``;
const PracticeTitle = styled(Text)`
  margin-bottom: 0.5rem;
  font-weight: 700;
`;
const PracticeDescription = styled(Text)`
  margin-bottom: 1rem;
`;
const Website = styled(Link)`
  margin-top: 0.5rem;
`;
