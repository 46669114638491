/**
 * Upcomingevents container
 *
 * @author Miguel Antunes <mantunes@ubiwhere.com>
 *
 *
 */

import React from 'react';
import styled from 'styled-components';
import { Link, Subtitle } from 'shared/components/ui';
import { useTranslation } from 'react-i18next';
import EventList from 'shared/components/EventList';

const UpcomingEvents = ({ loading, events }) => {
  const { t } = useTranslation();
  return (
    <Wrapper>
      <Row>
        <Subtitle>{t('home.upcoming_events')}</Subtitle>
        <Link>{t('common.view_all')}</Link>
      </Row>
      <EventList loading={loading} events={events} />
    </Wrapper>
  );
};

export default UpcomingEvents;

const Wrapper = styled.div`
  margin-top: 2.5rem;
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
