import * as React from 'react';

interface LogoProps extends React.SVGProps<SVGSVGElement> {
  white?: number;
}
function SvgComponent(props: LogoProps) {
  return (
    <svg
      width={72}
      height={72}
      viewBox="0 0 72 72"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M32.162 35.59v.01a4.094 4.094 0 004.094 4.095h.01a4.094 4.094 0 004.095-4.094v-.01a4.094 4.094 0 00-4.095-4.094h-.01a4.094 4.094 0 00-4.094 4.094z"
        fill="#09F"
      />
      <path
        d="M32.36.484V16.09h7.803V.484h-7.804zM32.36 55.122v15.605h7.803V55.122h-7.804zM16.733 31.694H1.125v7.803h15.608v-7.803zM71.377 31.694H55.77v7.803h15.607v-7.803z"
        fill="#E6007E"
      />
      <path
        d="M21.064 3.57A11.869 11.869 0 002.844 18.58a11.868 11.868 0 009.804 5.31h11.868V12.026a11.866 11.866 0 00-3.452-8.455zm-4.322 12.52h-4.104a4.105 4.105 0 01-2.903-7.007 4.105 4.105 0 017.007 2.902v4.104zM68.291 3.57a11.87 11.87 0 00-20.324 8.415v11.867h11.868a11.87 11.87 0 0011.72-14.227 11.867 11.867 0 00-3.303-6.095l.04.04zm-8.416 12.52H55.77v-4.104a4.104 4.104 0 114.105 4.103zM59.875 47.31H48.006v11.866A11.866 11.866 0 0062.19 70.815a11.868 11.868 0 008.65-16.18 11.867 11.867 0 00-10.965-7.326zm0 16a4.105 4.105 0 01-4.105-4.094v-4.104h4.105a4.105 4.105 0 010 8.198zM12.648 47.31A11.87 11.87 0 001.008 61.49 11.866 11.866 0 0017.19 70.14a11.868 11.868 0 007.326-10.964V47.31H12.648zm4.094 11.866a4.103 4.103 0 11-4.094-4.104h4.094v4.104z"
        fill={props.white ? '#FFF' : '#00264B'}
      />
    </svg>
  );
}

export default SvgComponent;
