/**
 * EventDetail scene
 *
 * @author Miguel Antunes <mantunes@ubiwhere.com>
 *
 *
 */
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';

import { actions, RootState } from 'store/rootSlices';
import {
  Badge,
  Button,
  ButtonIcon,
  Date,
  Heading2,
  Heading3,
  Link,
  LinkAction,
  Text,
} from 'shared/components/ui';
import { Back, CheckRound, Star, StarFull, Sustainable } from 'assets/icons';
import ShareIcon from '@material-ui/icons/Share';
import SustainabilityEvaluation from './containers/SustainabilityEvaluation';
import LogoNoText from 'assets/logos/LogoNoText';
import Loader from 'shared/components/ui/Loader';

const EventDetail = ({ t }) => {
  const { event, loading } = useSelector((state: RootState) => state.EventDetail);
  const { id } = useParams();
  const { onMount, onUnmount } = actions.EventDetail;
  const { navigateBack, navigateTo } = actions.App;

  // TODO: viewer relations?
  const eventStatus = ['not_going', 'going', 'participated', 'reviewed'];
  const [status, setStatus] = useState(eventStatus[0]);
  const [favorite, setFavorite] = useState(false);
  const reward = 't-shirt';

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(onMount({ id }));
    return () => {
      dispatch(onUnmount());
    };
  }, [dispatch, onMount, onUnmount, id]);

  if (loading) return <Loader full />;

  if (event && event !== null)
    return (
      <Wrapper>
        <Banner src={event.seeAlso || ''}>
          <Return
            onClick={() => {
              dispatch(navigateBack());
            }}
          >
            <ButtonIcon primary active={false} flat>
              <Back />
            </ButtonIcon>
          </Return>
          <Category>
            <Badge type={event.categories?.[0].name} />
          </Category>
        </Banner>
        <WrapperDetails>
          <Actions>
            <Status>
              <StatusButtonWrapper
                onClick={() =>
                  setStatus(
                    eventStatus[
                      eventStatus.indexOf(status) < 3 ? eventStatus.indexOf(status) + 1 : 0
                    ]
                  )
                }
              >
                <StatusButton
                  disabled={status === 'participated' || status === 'reviewed'}
                  primary
                  active={status === 'going'}
                >
                  <CheckRound width={16} height={16} />
                  <StatusText>{t('event_detail.going')}</StatusText>
                </StatusButton>
              </StatusButtonWrapper>
              <ButtonIcon primary active={favorite} onClick={() => setFavorite(!favorite)}>
                {favorite ? <StarFull /> : <Star />}
              </ButtonIcon>
            </Status>
            <ButtonIcon primary active={false}>
              <ShareIcon />
            </ButtonIcon>
          </Actions>
          <WrapperInfo>
            <SubCategory>{event.subcategories[0].name}</SubCategory>
            <WrapperTitle>
              <Title>{event.name}</Title>
              {event.sustainable && <Sustainable />}
            </WrapperTitle>
            <Location>{event.address}</Location>
            <Date date={event.startDate} />
            <Description>{event.description}</Description>
            {event.source && (
              <Location>
                {t('event_detail.hosted_by') + ' '}
                <Link onClick={() => dispatch(navigateTo(`/events/agents/1`))}>{event.source}</Link>
              </Location>
            )}
          </WrapperInfo>
          <SustainabilityEvaluation t={t} data={event.sdg} />
          {status === 'going' && (
            <WrapperItinerary>
              <Button>{t('event_detail.create_itinerary')}</Button>
            </WrapperItinerary>
          )}
        </WrapperDetails>
        <Rating>
          <RatingTitle>{t('event_detail.rating_title')}</RatingTitle>
          <Text>{t('event_detail.rating_description')}</Text>
          <Text>
            <Strong>{t('event_detail.reward') + ':'}</Strong>
            {' ' + reward}
          </Text>
          {status === 'reviewed' ? (
            <RewardButton highlight>{t('event_detail.get_reward')}</RewardButton>
          ) : status === 'participated' ? (
            <RatingLink onClick={() => dispatch(navigateTo(`/events/${id}/rating`))}>
              {t('event_detail.rating_link')}
            </RatingLink>
          ) : (
            <Disclamer>{t('event_detail.rating_disclamer')}</Disclamer>
          )}

          <WrapperLogo>
            <LogoHidden>
              <LogoNoText white={1} />
            </LogoHidden>
          </WrapperLogo>
        </Rating>
      </Wrapper>
    );

  return null;
};

export default EventDetail;

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding-bottom: ${({ theme }) => theme.dimensions.navBarHeight};
`;

const Banner = styled.div<{ src: string }>`
  background-image: url(${({ src }) => src});
  height: 15.5rem;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  position: relative;
  border-radius: 0 0 1rem 1rem;
  box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.2);
  z-index: 3;
`;

const Return = styled.div`
  margin-left: 1.25rem;
  margin-top: 3.25rem;
`;

const Category = styled.div`
  position: absolute;
  bottom: 0.5rem;
  left: 0.5rem;
  max-width: 7.5rem;
`;

const Actions = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 2rem 1rem;
  height: 2.5rem;
`;

const Status = styled.div`
  display: flex;
  flex-direction: row;
`;
const StatusButtonWrapper = styled.div`
  display: flex;
`;
const StatusButton = styled(Button)`
  padding: 1.2rem 2rem;
  margin-right: 0.8rem;
`;

const StatusText = styled.div`
  margin-left: 0.6rem;
`;

const WrapperDetails = styled.div`
  box-shadow: 0rem 0.4rem 1.2rem 0.4rem rgba(0, 0, 0, 0.2);
  border-radius: 0rem 0rem 1.6rem 1.6rem;
  padding: 3.2rem 0 0;
  margin-top: -3.2rem;
  background: ${({ theme }) => theme.colors.background};
  z-index: 2;
`;

const WrapperInfo = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 2rem;
`;

const SubCategory = styled.div`
  font-size: ${({ theme }) => theme.fontSizes.small};
  line-height: ${({ theme }) => theme.lineHeights.medium};
  letter-spacing: 0.02rem;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.primary};
  margin-bottom: 1.6rem;
`;

const WrapperTitle = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  svg path {
    fill: ${({ theme }) => theme.colors.green};
  }
`;

const Title = styled(Heading2)`
  margin-right: 0.8rem;
`;

const Location = styled(Text)`
  margin-bottom: 0.8rem;
`;

const Description = styled(Text)`
  padding: 2.4rem 0;
  white-space: pre-line;
`;

const WrapperItinerary = styled.div`
  padding: 1.6rem 1.6rem 0;
`;

const Rating = styled.div`
  padding: 6.4rem 1.6rem 2.6rem;
  margin-top: -3.2rem;
  background: ${({ theme }) => theme.colors.primary};
  position: relative;
  z-index: 1;
  ${Heading3}, ${Text} {
    color: ${({ theme }) => theme.colors.white};
  }
`;

const RatingTitle = styled(Heading3)`
  margin-bottom: 0.8rem;
`;

const Strong = styled.label`
  font-weight: 500;
`;

const Disclamer = styled(Text)`
  font-size: ${({ theme }) => theme.fontSizes.small};
  line-height: ${({ theme }) => theme.lineHeights.medium};
  margin-top: 2.4rem;
  max-width: 70%;
`;

const WrapperLogo = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const LogoHidden = styled.div`
  position: absolute;
  bottom: -3rem;
`;

const RewardButton = styled(Button)`
  margin-top: 2.4rem;
  margin-bottom: 4.3rem;
`;

const RatingLink = styled(LinkAction)`
  margin-top: 2.4rem;
`;
