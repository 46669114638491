/**
 * Heading3 component
 *
 * @author Miguel Antunes <mantunes@ubiwhere.com>
 *
 *
 */

import styled from 'styled-components';

export default styled.h3`
  font-weight: bold;
  font-size: ${({ theme }) => theme.fontSizes.extraLarge};
  line-height: 1.625rem;
  color: ${({ theme }) => theme.colors.primaryDark};
`;
