/**
 * Languages component
 *
 * @author Miguel Antunes <mantunes@ubiwhere.com>
 *
 *
 */

import React, { useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { FlagEN, FlagES, FlagFR, FlagPT } from 'assets/icons';
import { Heading3, Text } from 'shared/components/ui';

const Languages = React.forwardRef((props, ref) => {
  const { t } = useTranslation();
  const [language, setLanguage] = useState('PT-pt');

  const LangOption = ({ lang, children }) => {
    return (
      <WrapperOption onClick={() => setLanguage(lang)}>
        {children}
        <Label active={language === lang}>{t(`settings.language_${lang}`)}</Label>
      </WrapperOption>
    );
  };

  return (
    <Wrapper {...props} ref={ref}>
      <Title>{t('settings.language_modal_title')}</Title>
      <WrapperRow>
        <LangOption lang="PT-pt">
          <FlagPT />
        </LangOption>
        <LangOption lang="EN-us">
          <FlagEN />
        </LangOption>
      </WrapperRow>
      <WrapperRow>
        <LangOption lang="FR-fr">
          <FlagFR />
        </LangOption>
        <LangOption lang="ES-es">
          <FlagES />
        </LangOption>
      </WrapperRow>
    </Wrapper>
  );
});

export default Languages;

const Wrapper = styled.div`
  background: ${({ theme }) => theme.colors.white};
  box-shadow: 0px 0px 0.375rem 0.0625rem rgba(0, 0, 0, 0.1);
  border-radius: 2.5rem 2.5rem 0px 0px;
  text-align: center;
  padding: 2rem 2.5rem 3.3125rem;
  bottom: 0;
  position: absolute;
  width: -webkit-fill-available;
`;

const Title = styled(Heading3)`
  margin-bottom: 2.5rem;
`;

const WrapperRow = styled.div`
  display: flex;
  margin-bottom: 2rem;
`;

const WrapperOption = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;

  svg {
    width: 2.5rem;
    height: 2.5rem;
    margin-bottom: 0.5rem;
  }
`;

const Label = styled(Text)<{ active: boolean }>`
  text-decoration: ${({ active }) => (active ? 'underline' : 'none')};
`;
