import * as React from 'react';

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M21.414 8.417l-10.55 10.55a2.002 2.002 0 01-2.831 0L2.586 13.52a2.002 2.002 0 012.83-2.83l4.032 4.031 9.135-9.135a2.002 2.002 0 012.831 2.83z"
        fill={props.fill || '#000'}
      />
    </svg>
  );
}

export default SvgComponent;
