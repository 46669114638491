/**
 * Settings scene
 *
 * @author Miguel Antunes <mantunes@ubiwhere.com>
 *
 *
 */
import React, { useState } from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';

import { actions } from 'store/rootSlices';
import { ArrowDown, Back } from 'assets/icons';
import { Heading2, Text } from 'shared/components/ui';
import { Modal } from '@material-ui/core';
import Languages from './components/Languages';

const Setting = ({
  onClick,
  text,
  children,
}: {
  onClick?: () => void;
  text: string;
  children: any;
}) => (
  <WrapperSetting onClick={onClick && onClick}>
    <SettingText>{text}</SettingText>
    {children}
  </WrapperSetting>
);

const Settings = ({ t, i18n }) => {
  const { navigateBack } = actions.App;
  const dispatch = useDispatch();
  const [modalOpen, setModalOpen] = useState(false);

  return (
    <Wrapper>
      <Return
        onClick={() => {
          dispatch(navigateBack());
        }}
      >
        <Back />
      </Return>
      <Title>{t('settings.title')}</Title>
      <SettingsList>
        <Setting text={t('settings.location')}>
          <ArrowRight />
        </Setting>
        <Setting onClick={() => setModalOpen(true)} text={t('settings.language')}>
          Português
        </Setting>
      </SettingsList>
      <Modal open={modalOpen} onClose={() => setModalOpen(false)}>
        <Languages />
      </Modal>
    </Wrapper>
  );
};

export default Settings;

const Wrapper = styled.div`
  padding: 3.25rem 1rem ${({ theme }) => theme.dimensions.navBarHeight};
`;

const Return = styled.div`
  margin-bottom: 1rem;
  svg path {
    fill: ${({ theme }) => theme.colors.primary};
  }
`;
const Title = styled(Heading2)`
  margin-bottom: 1rem;
`;

const WrapperSetting = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 1.5rem 0;
  margin: 0 1rem;
  border-bottom: 0.0625rem solid rgb(198, 198, 198, 0.2);
`;

const SettingText = styled(Text)``;

const ArrowRight = styled(ArrowDown)`
  transform: rotate(-90.1deg);
  height: 0.75rem;
  width: 0.75rem;
`;
const SettingsList = styled.div``;
