/**
 * NewRecommendation scene slice
 *
 * @author Miguel Antunes <mantunes@ubiwhere.com>
 *
 *
 */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {};

export default createSlice({
  name: 'NewRecommendation',
  initialState,
  reducers: {
    onMount: (state, { payload }) => {},
    onUnmount: (state, { payload }) => {},
  },
});
