import * as React from 'react';

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M19.422 3.563h-1.797v-.938a.625.625 0 10-1.25 0v.938h-8.75v-.938a.625.625 0 10-1.25 0v.938H4.578A2.581 2.581 0 002 6.14v13.28A2.581 2.581 0 004.578 22h14.844A2.581 2.581 0 0022 19.422V6.14a2.581 2.581 0 00-2.578-2.579zM4.578 4.813h1.797v.625a.625.625 0 101.25 0v-.625h8.75v.625a.625.625 0 101.25 0v-.625h1.797A1.33 1.33 0 0120.75 6.14v1.484H3.25V6.141a1.33 1.33 0 011.328-1.329zM19.422 20.75H4.578a1.33 1.33 0 01-1.328-1.328V8.875h17.5v10.547a1.33 1.33 0 01-1.328 1.328z"
        fill={props.fill || '#000'}
      />
    </svg>
  );
}

export default SvgComponent;
