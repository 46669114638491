import { camelizeKeys } from 'humps';

import getEventDetail from './getEventDetail.json';
import getEvents from './getEvents.json';
import getRatingSurvey from './getRatingSurvey.json';
import getAgentDetail from './getAgentDetail.json';
import getRecommendationDetail from './getRecommendationDetail.json';
import { AxiosResponse } from 'axios';
import { APIResponse, Event } from 'api/types';
import { delay } from './utils';

export default {
  getEventDetail: new Promise<AxiosResponse<APIResponse<Event>>>((resolve) =>
    setTimeout(() => resolve(camelizeKeys(getEventDetail)), delay())
  ),
  getEvents: new Promise<AxiosResponse<APIResponse<Event[]>>>((resolve) =>
    setTimeout(() => resolve(camelizeKeys(getEvents)), delay())
  ),
  getRatingSurvey: new Promise((resolve) =>
    setTimeout(() => resolve(camelizeKeys(getRatingSurvey)), delay())
  ),
  getAgentDetail: new Promise((resolve) =>
    setTimeout(() => resolve(camelizeKeys(getAgentDetail)), delay())
  ),
  getRecommendationDetail: new Promise((resolve) =>
    setTimeout(() => resolve(camelizeKeys(getRecommendationDetail)), delay())
  ),
};
