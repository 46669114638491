/**
 * Events scene
 *
 * @author Miguel Antunes <mantunes@ubiwhere.com>
 *
 *
 */
import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';

import { actions, RootState } from 'store/rootSlices';
import theme from 'ui/theme';
import EventList from 'shared/components/EventList';
import { Heading2, Heading3, Link } from 'shared/components/ui';
import { Back, CalendarFilled, Search } from 'assets/icons';

const Events = ({ t }) => {
  const {
    loadingUserEvents,
    userEvents,
    loadingFutureEvents,
    futureEvents,
    loadingPreviousEvents,
    previousEvents,
  } = useSelector((state: RootState) => state.Events);

  const { onMount, onUnmount } = actions.Events;
  const { navigateBack } = actions.App;

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(onMount());
    return () => {
      dispatch(onUnmount());
    };
  }, [dispatch, onMount, onUnmount]);

  return (
    <Screen>
      <DarkSection>
        <Row>
          <Back
            onClick={() => {
              dispatch(navigateBack());
            }}
            fill={theme.colors.white}
          />
          <Wrapper>
            <CalendarFilled fill={theme.colors.white} />
            <Search style={{ marginLeft: '1.6rem' }} fill={theme.colors.white} />
          </Wrapper>
        </Row>
        <LightTitle>{t('events.title')}</LightTitle>
        <Row>
          <LightSubtitle>{t('events.my')}</LightSubtitle>
          <Link>{t('common.view_all')}</Link>
        </Row>
        <EventList loading={loadingUserEvents} events={userEvents} />
      </DarkSection>
      <Section>
        <Title>{t('events.all')}</Title>
        <Subtitle>{t('events.future')}</Subtitle>
        <EventList loading={loadingFutureEvents} events={futureEvents} />
      </Section>
      <Section>
        <Subtitle>{t('events.previous')}</Subtitle>
        <EventList loading={loadingPreviousEvents} events={previousEvents} />
      </Section>
    </Screen>
  );
};

export default Events;

const Screen = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding-bottom: ${({ theme }) => theme.dimensions.navBarHeight};
`;

const Wrapper = styled.div``;

const Section = styled.section`
  background-color: ${({ theme }) => theme.colors.background};
  padding: 0 1rem 2rem;
  z-index: 1;
`;

const DarkSection = styled.section`
  background-color: ${({ theme }) => theme.colors.primary};
  border-radius: 0 0 1rem 1rem;
  box-shadow: 0rem 0.24rem 0.75rem 0.24rem rgba(0, 0, 0, 0.2);
  padding: 2rem 1rem;
  margin-bottom: 4rem;
  z-index: 2;
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
`;

const Title = styled(Heading2)`
  margin-bottom: 2rem;
`;

const LightTitle = styled(Title)`
  color: ${({ theme }) => theme.colors.white};
`;

const Subtitle = styled(Heading3)`
  margin-bottom: 2rem;
`;

const LightSubtitle = styled(Subtitle)`
  color: ${({ theme }) => theme.colors.white};
  margin-bottom: 0;
`;
