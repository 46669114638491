/**
 * StatusBar component
 *
 * @author Miguel Antunes <mantunes@ubiwhere.com>
 *
 *
 */

import React from 'react';
import styled from 'styled-components';

const StatusBar = ({ status }) => {
  return (
    <Wrapper>
      <Submitted status={status} />
      <Pending status={status} />
      <Accepted status={status} />
    </Wrapper>
  );
};

export default StatusBar;

const Wrapper = styled.div`
  display: flex;
  background-color: rgba(198, 198, 198, 0.72);
  border-radius: 0.5rem;
  margin-bottom: 0.5rem;
  * {
    height: 0.5rem;
    width: 100%;
  }
`;
const Submitted = styled.div<{ status: string }>`
  flex: 1;
  background-color: ${({ theme, status }) => status && theme.colors.primary};
  border-radius: 0.5rem 0 0 0.5rem;
`;
const Pending = styled.div<{ status: string }>`
  flex: 1;
  background-color: ${({ theme, status }) =>
    status === 'pending'
      ? theme.colors.primaryMedium
      : status === 'accepted'
      ? theme.colors.primary
      : 'transparent'};
  border-left: 0.063rem solid ${({ theme }) => theme.colors.white};
  border-right: 0.063rem solid ${({ theme }) => theme.colors.white};
`;
const Accepted = styled.div<{ status: string }>`
  flex: 1;
  background-color: ${({ theme, status }) =>
    status === 'accepted' ? theme.colors.green : 'transparent'};
  border-radius: 0 0.5rem 0.5rem 0;
`;
