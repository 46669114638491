/**
 * EventRating scene slice
 *
 * @author Miguel Antunes <mantunes@ubiwhere.com>
 *
 *
 */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  loading: false,
  title: null,
  queries: null,
  currentQuery: 0,
  surveyStarted: false,
  eventRated: false,
  rewardLocation: null,
  rewardRedeemed: false,
};

export default createSlice({
  name: 'EventRating',
  initialState,
  reducers: {
    onMount: (state, { payload }) => {
      return {
        ...state,
        loading: true,
        currentQuery: 0,
      };
    },
    startSurvey: (state, { payload }) => {
      state.surveyStarted = true;
    },
    setRatingSurvey: (state, { payload }) => {
      const tmpQueries = [...payload.queries];
      tmpQueries.map((query) => (query.answer = null));
      return {
        ...state,
        loading: false,
        queries: tmpQueries,
        title: payload.title,
        rewardLocation: payload.rewardLocation,
      };
    },
    setAnswer: (state, { payload }) => {
      const { query, answer } = payload;
      if (state.queries !== null) {
        const newQueries = [...state.queries];
        newQueries[query].answer = answer;

        state.queries = newQueries;
      }
    },
    setCurrentQuery: (state, { payload }) => {
      state.currentQuery = payload.index;
    },
    onUnmount: (state, { payload }) => {
      return initialState;
    },
  },
});
