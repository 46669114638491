/**
 * NewRecommendation scene
 *
 * @author Miguel Antunes <mantunes@ubiwhere.com>
 *
 *
 */
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { useDispatch /*useSelector*/ } from 'react-redux';

import { actions } from 'store/rootSlices';
import theme from 'ui/theme';
import { Back } from 'assets/icons';
import { Button, Heading2, Input } from 'shared/components/ui';
import { Controller, useForm } from 'react-hook-form';
import { DateRangePicker } from 'shared/components/ui/material';

//import config from 'config'

interface NewReservationForm {
  title: string;
  description: string;
  location: string;
  date: string;
  similarEvents: string;
}

const rules = {
  title: {
    required: {
      value: true,
      message: 'Required field',
    },
  },
  description: {
    required: {
      value: true,
      message: 'Required field',
    },
  },
  location: {
    required: {
      value: true,
      message: 'Required field',
    },
  },
  date: {
    required: {
      value: true,
      message: 'Required field',
    },
  },
  similarEvents: {
    required: {
      value: true,
      message: 'Required field',
    },
  },
};

const NewRecommendation = ({ t, i18n }) => {
  //const { } = useSelector(state => state.Newrecommendation)
  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<NewReservationForm>();
  const { onMount, onUnmount } = actions.NewRecommendation;
  const { navigateBack } = actions.App;
  const [selectedDate /*, handleDateChange*/] = useState([null, null]);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(onMount());
    return () => {
      dispatch(onUnmount());
    };
  }, [dispatch, onMount, onUnmount]);

  const onSubmit = (data) => {
    console.log(data);
  };

  return (
    <Wrapper>
      <Back
        onClick={() => {
          dispatch(navigateBack());
        }}
        fill={theme.colors.primary}
      />
      <Title>{t('new_recommendation.title')}</Title>
      <Form>
        <Input
          name="title"
          register={register}
          rules={rules.title}
          label={t('new_recommendation.form_title')}
          placeholder={t('new_recommendation.form_title_placeholder')}
          error={errors.title && errors.title.message}
        />
        <Input
          name="description"
          register={register}
          rules={rules.description}
          label={t('new_recommendation.form_description')}
          placeholder={t('new_recommendation.form_description_placeholder')}
          error={errors.description && errors.description.message}
        />
        <Input
          name="location"
          register={register}
          rules={rules.location}
          label={t('new_recommendation.form_location')}
          placeholder={t('new_recommendation.form_location_placeholder')}
          error={errors.location && errors.location.message}
        />
        <Input
          name="date"
          register={register}
          rules={rules.date}
          label={t('new_recommendation.form_date')}
          placeholder={t('new_recommendation.form_date_placeholder')}
          defaultValue={moment.now()}
          error={errors.date && errors.date.message}
        />
        <Controller
          name="datePicker"
          control={control}
          defaultValue={selectedDate}
          render={({ field }) => (
            <DateRangePicker
              {...field}
              required
              label={t('new_recommendation.form_date')}
              value={field.value}
            />
          )}
        />
        <Input
          name="similarEvents"
          register={register}
          label={t('new_recommendation.form_similar_events')}
          rules={rules.similarEvents}
          placeholder={t('new_recommendation.form_similar_events_placeholder')}
          error={errors.similarEvents && errors.similarEvents.message}
        />
      </Form>
      <Button onClick={handleSubmit(onSubmit)}>{t('common.submit')}</Button>
    </Wrapper>
  );
};

export default NewRecommendation;

const Wrapper = styled.div`
  padding: 2rem 1rem ${({ theme }) => theme.dimensions.navBarHeight};
`;

const Title = styled(Heading2)`
  margin: 2rem 0 1rem;
`;

const Form = styled.form`
  margin-bottom: 4rem;

  > * {
    margin-bottom: 1rem;
  }
`;
