/**
 * Home scene slice
 *
 * @author Miguel Antunes <mantunes@ubiwhere.com>
 *
 *
 */
import { createSlice } from '@reduxjs/toolkit';
import { HomeState } from '../types';

const initialState: HomeState = {
  loadingUpcommingEvents: false,
  upcommingEvents: null,
};

export default createSlice({
  name: 'Home',
  initialState,
  reducers: {
    onMount: (state) => {},
    getUpcommingEvents: (state) => {
      state.loadingUpcommingEvents = true;
    },
    setUpcommingEvents: (state, { payload }) => {
      state.upcommingEvents = payload;
      state.loadingUpcommingEvents = false;
    },
    onUnmount: (state) => {},
  },
});
