/**
 * Reward component
 *
 * @author Miguel Antunes <mantunes@ubiwhere.com>
 *
 *
 */

import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button as StyledButton, Text } from 'shared/components/ui';
import styled from 'styled-components';

const Reward = ({ reward }) => {
  const { t } = useTranslation();
  return (
    <Wrapper>
      <WrapperInfo>
        <Label>{reward.reward}</Label>
        <Text>{reward.company}</Text>
      </WrapperInfo>
      <WrapperButton>
        <Button>{t('achievements.get_reward')}</Button>
      </WrapperButton>
    </Wrapper>
  );
};

export default Reward;

const Wrapper = styled.div`
  display: flex;
  margin-top: 1rem;
`;

const WrapperInfo = styled.div`
  flex: 1;
`;
const WrapperButton = styled.div`
  flex: 1;
`;

const Label = styled(Text)`
  font-weight: 700;
`;

const Button = styled(StyledButton)`
  height: fit-content;
`;
