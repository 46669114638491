/**
 * Terms scene
 *
 * @author Miguel Antunes <mantunes@ubiwhere.com>
 *
 *
 */
import React from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import { actions } from 'store/rootSlices';
import { Back } from 'assets/icons';
import { Heading2 } from 'shared/components/ui';

const Terms = ({ t, i18n }) => {
  const { navigateBack } = actions.App;
  const dispatch = useDispatch();

  return (
    <Wrapper>
      <Return
        onClick={() => {
          dispatch(navigateBack());
        }}
      >
        <Back />
      </Return>
      <Title>{t('terms_and_conditions.title')}</Title>
      <TermsAndConditions
        dangerouslySetInnerHTML={{
          __html: t('terms_and_conditions.html'),
        }}
      />
    </Wrapper>
  );
};

export default Terms;

const Wrapper = styled.div`
  padding: 3.25rem 1rem ${({ theme }) => theme.dimensions.navBarHeight};
`;
const Return = styled.div`
  margin-bottom: 1rem;
  svg path {
    fill: ${({ theme }) => theme.colors.primary};
  }
`;

const Title = styled(Heading2)`
  margin-bottom: 1rem;
`;
const TermsAndConditions = styled.div`
  color: ${({ theme }) => theme.colors.primary};
  font-size: ${({ theme }) => theme.fontSizes.medium};
  line-height: ${({ theme }) => theme.lineHeights.extraLarge};

  p {
    margin-bottom: 1.5rem;
  }
`;
