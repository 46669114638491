import * as React from 'react';

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M21.632 15.965l-4.65-.997a.478.478 0 00-.439.13l-1.786 1.786-.101-.051a16.918 16.918 0 01-4.343-3.139 16.757 16.757 0 01-3.138-4.342l-.051-.102L8.91 7.464a.475.475 0 00.13-.438l-.998-4.65A.477.477 0 007.577 2a5.53 5.53 0 00-2.634.657c-.766.406-1.43 1-1.93 1.715a5.581 5.581 0 00-.954 2.385c-.133.906-.04 1.81.277 2.685a20.546 20.546 0 004.757 7.465 20.514 20.514 0 007.465 4.757 5.472 5.472 0 002.685.277 5.582 5.582 0 002.384-.953 5.57 5.57 0 001.716-1.931A5.485 5.485 0 0022 16.422a.463.463 0 00-.368-.457zm-.606.989c-.144 1.325-.836 2.482-1.942 3.26-.793.555-1.7.84-2.623.84-.527 0-1.059-.094-1.579-.281A19.434 19.434 0 013.232 9.117 4.533 4.533 0 013.79 4.92a4.544 4.544 0 013.26-1.943l.144-.016.86 4.01-1.84 1.842c-.141.14-.18.36-.095.539a17.826 17.826 0 008.537 8.536c.18.086.398.047.539-.094l1.84-1.84 4.011.86-.02.14z"
        fill={props.fill || '#000'}
      />
    </svg>
  );
}

export default SvgComponent;
