/**
 * Achievements container
 *
 * @author Miguel Antunes <mantunes@ubiwhere.com>
 *
 *
 */

import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { useDispatch /*useSelector*/ } from 'react-redux';

import { actions } from 'store/rootSlices';
import { Button, Heading2, Text } from 'shared/components/ui';
import ProgressBar from 'scenes/Account/components/ProgressBar';
import Reward from 'scenes/Account/components/Reward';

//import config from 'config'
const rewards = {
  next: {
    reward: 'Cupão 20% desconto',
    company: 'Continente',
  },
  achieved: [
    {
      reward: 'Cupão 20% desconto',
      company: 'Continente',
    },
    {
      reward: 'Cupão 10% desconto',
      company: 'Continente',
    },
  ],
};

const Achievements = ({ t }) => {
  //const { } = useSelector(state => state.Achievements)

  const { onMount, onUnmount } = actions.Achievements;
  //const { api_t } = actions.App
  const maxPoints = 7;
  const [points, setPoints] = useState(3);
  const [hasRewards, setHasRewards] = useState(false);
  const [goalReached, setGoalReached] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(onMount());
    return () => {
      dispatch(onUnmount());
    };
  }, [dispatch, onMount, onUnmount]);

  // TODO: Remove - Example only
  useEffect(() => {
    if (points === maxPoints) setGoalReached(true);
    if (goalReached && points !== maxPoints) {
      setHasRewards(true);
      setGoalReached(false);
    }
  }, [points, goalReached]);

  return (
    <Wrapper onClick={() => setPoints(points < maxPoints ? points + 1 : 0)}>
      <Title>{t('achievements.title')}</Title>
      <Description>{t(`achievements.description${!points ? '_basic' : ''}`)}</Description>
      <ProgressLabel>{t('achievements.progress_status') + ` ${points}/${maxPoints}`}</ProgressLabel>
      <ProgressBar points={points} totalPoints={7} />
      <WrapperRewards>
        {!goalReached && (
          <>
            <Label>{t('achievements.next_reward') + ` (${maxPoints} points)`}</Label>
            <Description>{`${rewards.next.reward} - ${rewards.next.company}`}</Description>
          </>
        )}

        {goalReached && (
          <WrapperGoalCompleted>
            <Label>{t('achievements.goal_completed')}</Label>
            <Description>Cupão 20% desconto - Continente</Description>
            <WrapperGoalButton>
              <GoalButton>{t('achievements.get_reward')}</GoalButton>
            </WrapperGoalButton>
          </WrapperGoalCompleted>
        )}

        {hasRewards && (
          <RewardList>
            <Title>{t('achievements.rewards')}</Title>
            {rewards.achieved.map((reward, index) => (
              <Reward key={`reward_${index}`} reward={reward} />
            ))}
          </RewardList>
        )}
      </WrapperRewards>
    </Wrapper>
  );
};

export default Achievements;

const Wrapper = styled.div``;
const Title = styled(Heading2)``;

const Description = styled(Text)``;

const ProgressLabel = styled.p`
  font-size: ${({ theme }) => theme.fontSizes.large};
  line-height: ${({ theme }) => theme.lineHeights.large};
  font-weight: 700;
  letter-spacing: 0.0125rem;
  margin: 1.5rem 0 1rem;
`;
const WrapperRewards = styled.div`
  margin-top: 1.5rem;
`;
const WrapperGoalCompleted = styled.div``;
const Label = styled.p`
  font-size: ${({ theme }) => theme.fontSizes.medium};
  line-height: ${({ theme }) => theme.lineHeights.extraLarge};
  font-weight: 700;
`;
const GoalButton = styled(Button)`
  width: 15.625rem;
  margin-top: 1.5rem;
`;
const WrapperGoalButton = styled.div`
  display: flex;
  justify-content: center;
`;

const RewardList = styled.div`
  margin-top: 2rem;
`;
