import * as React from 'react';

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={231}
      height={225}
      viewBox="0 0 231 225"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#prefix__clip0)">
        <path
          d="M76.946 120.051c0-20.275-16.557-68.384-36.982-68.384S2.98 99.777 2.98 120.05a36.611 36.611 0 0010.84 25.962 37.108 37.108 0 0026.143 10.752 37.108 37.108 0 0026.143-10.752 36.611 36.611 0 0010.84-25.962z"
          fill="#C6C6C6"
        />
        <path
          d="M76.946 120.051c0-20.275-16.557-68.384-36.982-68.384S2.98 99.777 2.98 120.05a36.611 36.611 0 0010.84 25.962 37.108 37.108 0 0026.143 10.752 37.108 37.108 0 0026.143-10.752 36.611 36.611 0 0010.84-25.962z"
          fill="#fff"
          fillOpacity={0.7}
        />
        <path
          d="M39.964 201.396a.423.423 0 01-.423-.42v-85.754a.42.42 0 01.423-.42.424.424 0 01.423.42v85.754a.42.42 0 01-.423.42z"
          fill="#C6C6C6"
        />
        <path
          d="M39.964 131.244a.425.425 0 01-.32-.144l-21.27-24.365a.418.418 0 01.044-.591.423.423 0 01.596.041l21.27 24.364a.42.42 0 01-.091.629.429.429 0 01-.229.066zM39.964 145.305a.426.426 0 01-.391-.26.415.415 0 01.092-.457l12.085-12.005a.423.423 0 01.722.297.42.42 0 01-.124.297l-12.085 12.005a.42.42 0 01-.3.123z"
          fill="#C6C6C6"
        />
        <path
          d="M141.639 98.484c0-26.48-21.626-89.317-48.303-89.317-26.677 0-48.303 62.836-48.303 89.317.007 12.72 5.099 24.917 14.157 33.909 9.058 8.992 21.34 14.043 34.146 14.043 12.806 0 25.088-5.051 34.146-14.043 9.058-8.992 14.15-21.189 14.157-33.91z"
          fill="#E6007E"
        />
        <path
          d="M93.4 207.5c-.22 0-.432-.059-.589-.165-.156-.105-.244-.248-.244-.397V92.228c0-.148.088-.291.244-.397.157-.105.369-.164.59-.164.221 0 .433.059.59.164.156.106.244.249.244.398v114.709c0 .149-.088.292-.245.397a1.066 1.066 0 01-.59.165z"
          fill="#C6C6C6"
        />
        <path
          d="M93.336 113.104a.55.55 0 01-.418-.19l-27.78-31.822a.546.546 0 01.057-.772.555.555 0 01.778.053l27.78 31.822a.552.552 0 01.085.588.552.552 0 01-.502.321zM93.335 131.468a.55.55 0 01-.39-.937l15.785-15.679a.555.555 0 01.781 0 .545.545 0 010 .776l-15.785 15.679a.543.543 0 01-.39.161z"
          fill="#C6C6C6"
        />
        <path d="M205.153 198.911s.171-3.695 3.806-3.269l-3.806 3.269z" fill="#3F3D56" />
        <path
          d="M204.131 195.387a1.819 1.819 0 10-.002-3.638 1.819 1.819 0 00.002 3.638z"
          fill="#E6007E"
        />
        <path d="M204.33 196.637h-.512v3.581h.512v-3.581z" fill="#3F3D56" />
        <path d="M182.971 193.909v-16.541c0 .029 6.447 11.738 0 16.541z" fill="#C6C6C6" />
        <path
          d="M182.971 193.909v-16.541c0 .029 6.447 11.738 0 16.541z"
          fill="#fff"
          fillOpacity={0.5}
        />
        <path d="M183.369 193.909l-12.157-11.169s12.98 3.183 12.157 11.169z" fill="#C6C6C6" />
        <path
          d="M183.369 193.909l-12.157-11.169s12.98 3.183 12.157 11.169z"
          fill="#fff"
          fillOpacity={0.5}
        />
        <path d="M57.147 197.888v-16.541c0 .029 6.447 11.738 0 16.541z" fill="#C6C6C6" />
        <path
          d="M57.147 197.888v-16.541c0 .029 6.447 11.738 0 16.541z"
          fill="#fff"
          fillOpacity={0.5}
        />
        <path d="M57.544 197.887l-12.156-11.169s12.98 3.183 12.156 11.169z" fill="#C6C6C6" />
        <path
          d="M57.544 197.887l-12.156-11.169s12.98 3.183 12.156 11.169z"
          fill="#fff"
          fillOpacity={0.5}
        />
        <path d="M100.887 209.824v-20.548s8.009 14.58 0 20.548z" fill="#C6C6C6" />
        <path d="M100.887 209.824v-20.548s8.009 14.58 0 20.548z" fill="#fff" fillOpacity={0.5} />
        <path d="M101.398 209.824l-15.139-13.897s16.133 3.95 15.139 13.897z" fill="#C6C6C6" />
        <path
          d="M101.398 209.824l-15.139-13.897s16.133 3.95 15.139 13.897z"
          fill="#fff"
          fillOpacity={0.5}
        />
        <path
          d="M231 207.323l-.142.54-47.688-13.585-35.902 7.929-45.927 7.844-.057-.028-21.756-6.082-5.51-1.563-16.786-4.718-14.003 3.126-4.09.91-8.919 1.989-.113-.54 9.032-2.018 4.09-.909 14.031-3.126h.057l16.73 4.689 5.48 1.563 21.843 6.139 45.785-7.844 36.043-7.929h.057L231 207.323z"
          fill="#C6C6C6"
        />
        <path
          d="M231 207.323l-.142.54-47.688-13.585-35.902 7.929-45.927 7.844-.057-.028-21.756-6.082-5.51-1.563-16.786-4.718-14.003 3.126-4.09.91-8.919 1.989-.113-.54 9.032-2.018 4.09-.909 14.031-3.126h.057l16.73 4.689 5.48 1.563 21.843 6.139 45.785-7.844 36.043-7.929h.057L231 207.323z"
          fill="#fff"
          fillOpacity={0.5}
        />
        <path
          d="M176.9 27.084l3.635-2.899c-2.812-.312-3.976 1.222-4.459 2.444-2.215-.91-4.601.285-4.601.285l7.271 2.643a5.333 5.333 0 00-1.846-2.473zM12.242 33.365l3.635-2.899c-2.812-.313-3.976 1.222-4.46 2.444-2.215-.91-4.6.284-4.6.284l7.27 2.643a6.106 6.106 0 00-1.845-2.472zM137.782 75.91l3.636-2.9c-2.812-.312-3.977 1.223-4.46 2.445-2.215-.91-4.601.284-4.601.284l7.271 2.643c-.397-.995-1.022-1.847-1.846-2.473z"
          fill="#3F3D56"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M194.085 98.46c3.811-5.583 16.912-25.329 16.912-33.814 0-9.936-7.999-17.98-17.919-17.98-9.921 0-17.952 8.044-17.952 17.98 0 8.453 13.102 28.23 16.912 33.814.063.126.189.22.315.315.567.41 1.355.252 1.732-.315zm8.094-35.076c0 5.052-4.089 9.148-9.133 9.148-5.044 0-9.133-4.096-9.133-9.148s4.089-9.147 9.133-9.147c5.044 0 9.133 4.095 9.133 9.147z"
          fill="#09F"
        />
        <path
          d="M193.046 112.275a4.097 4.097 0 004.094-4.1 4.097 4.097 0 00-4.094-4.101 4.097 4.097 0 00-4.094 4.101c0 2.265 1.833 4.1 4.094 4.1z"
          fill="#09F"
        />
        <path
          d="M173.334 162.755a3.714 3.714 0 004.581-2.597c.469-1.659-.276-3.4-1.766-4.256l-7.892-8.954-6.677 1.437 9.381 9.202a3.678 3.678 0 001.766 4.92c.193.11.414.193.607.248z"
          fill="#FFD4C7"
        />
        <path
          d="M147.425 130.67l19.646 24.706 5.987-2.763-23.095-35.982-2.538 14.039z"
          fill="#979797"
        />
        <path d="M163.097 205.26l4.664-.083-.552 16.001h-4.112V205.26z" fill="#FFD4C7" />
        <path
          d="M162.049 219.824h8.112c2.842 0 5.16 2.321 5.16 5.168v.166h-13.272v-5.334z"
          fill="#00264B"
        />
        <path
          d="M132.083 201.031l5.132 3.289-10.237 12.326-3.477-2.239 8.582-13.376z"
          fill="#FFD4C7"
        />
        <path
          d="M123.336 212.694l6.815 4.394a5.183 5.183 0 011.545 7.158l-.082.138-11.176-7.185 2.898-4.505z"
          fill="#00264B"
        />
        <path
          d="M130.262 152.226s11.368-37.806 15.01-38.304c3.643-.497 9.547 9.701 9.547 9.701 7.644 9.755 15.508 41.343 15.508 41.343s-13.107.829-22.709 5.942c-9.603 5.113-20.888-.249-20.888-.249l3.532-18.433z"
          fill="#979797"
        />
        <path
          opacity={0.1}
          d="M130.262 152.226s11.368-37.806 15.01-38.304c3.642-.497 8.03 9.811 8.03 9.811 3.807 15.725 16.997 41.233 16.997 41.233s-13.107.829-22.709 5.942c-9.575 5.113-20.888-.249-20.888-.249l3.56-18.433z"
          fill="#000"
        />
        <path
          d="M139.588 140.895v19.014l-2.235 27.498-11.147 20.837 5.325 3.787 15.204-19.788 6.015-19.649 9.437 43.499 7.119-.497-4.94-60.33-9.353-16.996-15.425 2.625z"
          fill="#00264B"
        />
        <path
          d="M151.039 122.517c3.56 3.565.249 7.351.249 7.351l4.056 9.148-15.977 4.311-5.573-24.983 3.808-4.063c7.615-.248 7.615-.414 7.615-.414 1.325 1.326 5.822 8.65 5.822 8.65z"
          fill="#fff"
        />
        <path
          d="M133.076 115.857a6.812 6.812 0 00-1.959 8.705c1.821 3.51 3.808 8.927 2.787 14.868-1.849 10.751-7.726 18.931-11.009 21.557-1.132.912-1.187 2.238-1.021 3.205.469 2.681 3.062 5.694 4.828 6.302.111.027.249.055.387.055 2.897 0 10.043-9.258 18.266-27.36 5.105-11.276 1.407-20.313-2.621-25.923a6.802 6.802 0 00-9.52-1.547c-.027.027-.055.027-.055.055l-.083.083z"
          fill="#979797"
        />
        <path
          d="M147.48 110.606h-15.866c-.69 0-1.242-.553-1.242-1.244v-6.853c0-5.058 4.084-9.176 9.161-9.176 5.049 0 9.161 4.09 9.161 9.176v6.853a1.198 1.198 0 01-1.214 1.244z"
          fill="#001F3D"
        />
        <path
          d="M141.437 110.44c3.703 0 6.705-3.006 6.705-6.715s-3.002-6.716-6.705-6.716c-3.703 0-6.705 3.007-6.705 6.716s3.002 6.715 6.705 6.715z"
          fill="#FFD4C7"
        />
        <path
          d="M150.901 103.227h-9.685l-.11-1.382-.497 1.382h-1.49l-.193-2.763-.993 2.763h-2.898v-.138c0-4.007 3.256-7.24 7.257-7.268h1.38c4.001 0 7.229 3.26 7.257 7.268v.138h-.028z"
          fill="#001F3D"
        />
        <path
          d="M141.106 111.877c-.083 0-.138 0-.221-.027l-7.091-1.244V98.861h7.808l-.193.22c-2.704 3.151-.662 8.236.773 11 .221.415.193.912-.083 1.299-.221.332-.579.497-.993.497z"
          fill="#001F3D"
        />
        <path
          d="M111.581 164.635a3.731 3.731 0 01-4.663-2.432c-.524-1.631.166-3.427 1.628-4.312l7.561-9.23 6.705 1.216-9.051 9.534a3.685 3.685 0 01-1.6 4.975 2.2 2.2 0 01-.58.249z"
          fill="#FFD4C7"
        />
        <path
          d="M140.664 126.746l-23.067 30.316-6.098-2.542 21.798-36.812 7.367 9.038z"
          fill="#979797"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M214.082 126.931c2.552-1.23 11.441-5.658 13.221-8.738 2.084-3.607.872-8.199-2.723-10.273-3.596-2.074-8.193-.834-10.277 2.773-1.773 3.069-1.173 12.987-.962 15.81-.004.059.022.12.047.181.12.267.438.374.694.247zm10.29-11.04a3.83 3.83 0 01-5.228 1.411 3.826 3.826 0 01-1.392-5.23 3.832 3.832 0 015.229-1.411 3.826 3.826 0 011.391 5.23z"
          fill="#E2F1FF"
        />
        <path
          d="M210.808 131.729c.82.473 1.869.19 2.344-.632a1.715 1.715 0 00-.624-2.345 1.716 1.716 0 00-2.343.633 1.714 1.714 0 00.623 2.344z"
          fill="#E2F1FF"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M157 57.337c.21-2.824.817-12.73-.962-15.81-2.084-3.607-6.67-4.855-10.265-2.78-3.596 2.074-4.819 6.673-2.735 10.28 1.773 3.068 10.669 7.508 13.22 8.738.05.032.115.04.181.048a.511.511 0 00.561-.476zm-4.423-14.425a3.825 3.825 0 01-1.391 5.23 3.83 3.83 0 01-5.228-1.41 3.825 3.825 0 011.391-5.23 3.83 3.83 0 015.228 1.41z"
          fill="#E2F1FF"
        />
        <path
          d="M159.521 62.569a1.714 1.714 0 00.624-2.345 1.717 1.717 0 00-2.344-.632 1.715 1.715 0 00-.623 2.345 1.716 1.716 0 002.343.632z"
          fill="#E2F1FF"
        />
      </g>
      <defs>
        <clipPath id="prefix__clip0">
          <path fill="#fff" d="M0 0h231v225H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default SvgComponent;
