import * as React from 'react';

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={279}
      height={292}
      viewBox="0 0 279 292"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M278.649 232.201c.036 28.361-23.302 51.383-52.095 51.418-28.793.034-52.165-22.953-52.2-51.314-.036-27.945 22.633-50.793 51.004-51.382h1.196c.599 0 1.197 0 1.76.034 1.303.035 2.57.139 3.837.278.739.069 1.443.173 2.182.277.071 0 .176.035.247.035a50.408 50.408 0 018.764 2.115c21.12 7.038 35.305 26.558 35.305 48.539z"
        fill="#fff"
      />
      <path
        d="M226.482 283.965c-28.934 0-52.482-23.16-52.482-51.66-.035-28.118 22.774-51.14 51.356-51.729h.493c.845 0 1.689 0 2.499.035 1.302.034 2.605.138 3.872.277.669.069 1.373.173 2.182.277l.247.035c25.554 3.918 44.386 25.587 44.351 51.036.035 28.5-23.513 51.729-52.447 51.764l-.071-.035zm.071-102.73h-1.197c-27.948.589-50.687 23.507-50.652 51.036.035 28.118 23.267 50.966 51.778 50.966h.036c28.546-.034 51.778-22.917 51.743-51.07.035-25.137-18.55-46.529-43.753-50.377l-.247-.035c-.774-.104-1.478-.208-2.147-.277-1.267-.139-2.534-.209-3.801-.278-.599.035-1.197.035-1.76.035z"
        fill="#979797"
      />
      <path
        d="M186.696 234.946c.104-.162-.158-.509-.585-.775-.427-.266-.858-.351-.962-.189-.104.162.158.509.585.775.427.266.858.351.962.189z"
        fill="#3F3D56"
      />
      <path
        d="M185.158 231.993c.493-.762 1.479-.97 2.253-.485l20.627 12.863c.774.485.985 1.456.493 2.219l-23.373-14.597z"
        fill="#09F"
      />
      <path
        d="M183.223 232.652l.633-1.005c.317-.52 1.021-.659 1.549-.347l23.478 14.666c.598.381.81 1.179.422 1.803l-.528.832-25.554-15.949z"
        fill="#2F2E41"
      />
      <path
        d="M186.721 233.217l-.244.38 1.576.983.245-.38-1.577-.983zM188.87 234.53l-.245.381 1.576.983.245-.381-1.576-.983z"
        fill="#D0CDE1"
      />
      <path
        d="M226.694 186.193c8.106 0 14.678-6.473 14.678-14.458s-6.572-14.458-14.678-14.458c-8.107 0-14.678 6.473-14.678 14.458s6.571 14.458 14.678 14.458z"
        fill="#FFD4C7"
      />
      <path
        d="M240.634 281.608a53.16 53.16 0 01-35.833-2.774l1.126-29.644.071-2.288.035-.763.035-1.109.035-.694h.141c.246 0 .774-.069 1.584-.104.915-.069 2.147-.104 3.59-.173.352 0 .704-.035 1.056-.035 7.568-.277 19.43-.347 20.979 1.907l.211 1.318.317 2.149.634 4.369c.035 0 7.392 14.423 6.019 27.841z"
        fill="#C6C6C6"
      />
      <path
        d="M244.363 206.857l-2.886 26.281-5.491 11.996-2.042 4.438-29.497-.347 3.027-20.248c-4.294-10.921 5.914-15.325 5.914-15.325l10.56-21.322s2.816-1.387 10.172-1.734c6.829-.312 1.444.693 1.444.693 8.94-.936 8.799 15.568 8.799 15.568z"
        fill="#00264B"
      />
      <path
        opacity={0.2}
        d="M235.493 234.49l-5.491 11.996c-1.76-3.259-3.098-6.934-3.098-9.119 0-5.998 6.09-2.808 6.09-2.808s-4.928-26.489-4.928-34.428c-.035-5.27 4.435 18.098 7.427 34.359z"
        fill="#000"
      />
      <path
        d="M243.589 162.929a16.28 16.28 0 00-4.33-5.999c.774-.242 1.408-.797 1.795-1.49.563-1.11.282-2.497-.493-3.468-.704-.832-1.619-1.456-2.64-1.872.74.347 1.584.486 2.394.451.986-.104 2.006-.867 1.936-1.838-.07-.901-.95-1.525-1.795-2.011l-1.584-.936.422-.936c.951-2.357.387-5.027-1.408-6.83 1.725 1.734 1.303 4.438-.422 6.622-.071.07-.106.139-.176.208-.352-.208-.669-.381-1.021-.589-.845-.486-1.725-1.006-2.71-1.11-.986-.104-2.112.278-2.499 1.144-.423.937.07 2.115-.493 2.982-.493.763-1.549.902-2.499.867-.916-.035-1.901-.173-2.746.208-1.162.555-1.514 2.08-1.021 3.224.211.486.563.902.986 1.249-2.464.346-4.858 1.04-7.251 1.802-2.816.867-5.667 1.769-7.991 3.537-2.358 1.733-4.118 4.507-3.907 7.385.212 2.878 3.133 7.419 6.019 6.969l.458-1.352a9.392 9.392 0 0110.665 1.872c.036-1.803 2.852-2.427 4.224-1.248 1.373 1.178 1.655 3.189 1.76 4.992.106 1.803.212 3.779 1.408 5.166 1.162 1.318 3.133 1.734 4.858 1.318 1.725-.382 3.203-1.456 4.435-2.705 4.576-4.541 6.019-11.753 3.626-17.612z"
        fill="#00264B"
      />
      <path
        d="M196.81 247.457c2.851 2.045 6.864 1.387 8.94-1.422.212-.312.423-.624.564-.971l39.951.763 2.64-8.633-45.97-.797c-3.309-1.318-7.04.277-8.378 3.536a6.304 6.304 0 002.253 7.524z"
        fill="#FFD4C7"
      />
      <path
        d="M235.634 189.833s-9.292.694-9.292 8.633c0 7.94 8.412 36.093 8.412 36.093s-6.089-3.155-6.089 2.808c0 5.998 10.208 23.438 11.264 11.789 0 0 15.382 7.384 14.467-6.519-.88-13.903-10.56-43.339-10.56-43.339s-1.021-10.019-8.202-9.465z"
        fill="#00264B"
      />
      <path
        d="M108.519 230.459c0 21.864-13.587 41.534-34.26 49.553-.803.323-1.607.611-2.41.863-.913.323-1.827.575-2.74.827-.657.18-1.278.359-1.935.503a54.886 54.886 0 01-29.804-1.258 41.233 41.233 0 01-2.228-.791c-.987-.36-1.973-.792-2.959-1.223l-.219-.108c-.365-.18-.767-.36-1.132-.539l-1.534-.756a51.692 51.692 0 01-3.397-1.941c-.584-.36-1.132-.72-1.717-1.079C-.579 258.076-7.08 224.993 9.611 200.648c7.89-11.507 20.088-19.526 33.895-22.331.95-.18 1.899-.36 2.885-.503.621-.108 1.242-.18 1.826-.252.439-.036.877-.108 1.315-.144 1.206-.108 2.41-.18 3.653-.216H54.426c.621 0 1.242 0 1.827.036 1.351.036 2.666.144 3.98.288.768.072 1.498.18 2.265.288.074 0 .183.035.256.035.256.036.511.072.73.108.512.072 1.06.18 1.571.288.475.108.986.18 1.461.288 1.79.395 3.58.899 5.296 1.474 11.797 3.992 21.878 11.831 28.453 22.259.182.288.365.576.548.899.146.216.255.432.401.684.037.071.073.143.146.215.037.036.037.072.073.144.256.432.475.827.694 1.259a51.348 51.348 0 016.392 24.992z"
        fill="#fff"
      />
      <path
        d="M54.461 284.039a56.094 56.094 0 01-17.24-2.733 176.53 176.53 0 01-2.264-.791A55.03 55.03 0 0132 279.292a22.055 22.055 0 01-.22-.107c-.365-.18-.73-.324-1.095-.504l-.037-.036-1.534-.755a64.33 64.33 0 01-3.433-1.942 27.413 27.413 0 01-1.716-1.115c-24.91-16.541-31.448-49.84-14.647-74.365 7.962-11.615 20.198-19.67 34.15-22.475.95-.18 1.936-.359 2.922-.503.585-.072 1.206-.18 1.863-.252.438-.036.877-.108 1.352-.144a61.242 61.242 0 013.652-.215h1.278c.621 0 1.242 0 1.863.035 1.352.036 2.703.144 4.018.288.694.072 1.424.18 2.264.288l.366.072.657.108c.475.072.986.179 1.57.287.475.108.987.216 1.462.324a45.19 45.19 0 015.332 1.51c11.87 4.028 21.988 11.939 28.672 22.403.182.288.365.576.548.899.146.216.255.432.401.684.037.071.074.143.147.215.036.036.036.072.073.108l.036.036c.219.396.475.791.694 1.259 14.099 26.143 3.981 58.579-22.572 72.459-1.826.935-3.689 1.798-5.588 2.517-.767.288-1.607.612-2.41.863-.914.324-1.827.576-2.777.863-.657.18-1.315.36-1.972.504-4.273.935-8.547 1.438-12.857 1.438zm-23.558-6.077l.073.036c.365.18.73.36 1.132.539.074.036.147.072.183.072.95.432 1.936.827 2.959 1.223.803.288 1.533.539 2.227.791a54.92 54.92 0 0029.622 1.259 30.277 30.277 0 001.936-.504 79.269 79.269 0 002.739-.827c.84-.288 1.644-.575 2.41-.863 20.6-7.875 34.114-27.437 34.041-49.193a52.459 52.459 0 00-6.245-24.777c-.256-.467-.475-.863-.694-1.258l-.037-.036c0-.036-.036-.036-.036-.072l-.037-.036-.109-.216c-.147-.252-.256-.467-.402-.683l-.548-.863c-6.575-10.357-16.546-18.16-28.27-22.115a54.342 54.342 0 00-5.26-1.475c-.474-.108-.95-.216-1.46-.287-.585-.108-1.096-.216-1.571-.288l-.657-.108-.366-.072c-.803-.108-1.534-.216-2.228-.288-1.315-.143-2.63-.215-3.944-.287-.585-.036-1.206-.036-1.827-.036H53.33c-1.205.036-2.41.108-3.616.215h-.036c-.402.036-.84.072-1.279.144-.657.072-1.278.144-1.826.252-.95.144-1.936.324-2.885.503-29.037 5.862-47.738 33.803-41.784 62.391 2.812 13.521 10.92 25.424 22.535 33.155.548.36 1.133.755 1.68 1.079a39.2 39.2 0 003.397 1.906c.402.216.913.467 1.388.719z"
        fill="#979797"
      />
      <path
        d="M100.737 218.88c-2.228 2.877-6.391 3.416-9.313 1.222a16.918 16.918 0 01-.877-.791l-14.245 5.071-5.259-7.768 20.234-6.904c2.886-2.265 7.086-1.798 9.387 1.043a6.459 6.459 0 01.073 8.127z"
        fill="#9F616A"
      />
      <path d="M56.178 219.599l29.365-9.565 4.968 10.644-27.394 8.271-6.94-9.35z" fill="#C6C6C6" />
      <path
        d="M67.171 282.205a54.886 54.886 0 01-29.804-1.258l.658-6.114.62-5.717 9.132-13.917 2.922-4.459 12.09 4.315 1.241 7.624 1.972 12.19 1.17 7.336z"
        fill="#2F2E41"
      />
      <path
        d="M74.257 279.976c-.804.323-1.607.611-2.41.863-.914.323-1.827.575-2.74.827-.658.18-1.279.359-1.936.503a54.886 54.886 0 01-29.804-1.258 41.233 41.233 0 01-2.228-.791 41.65 41.65 0 01-.183 6.4 72.524 72.524 0 01-2.739-7.587c-.036-.18-.11-.36-.146-.504-.256-.863-.475-1.69-.694-2.481-.22-.899-.438-1.726-.584-2.517-.658-3.021-.877-4.855-.877-4.855l7.159-14.599 1.424.143.585.072 3.579.396 5.187.575 8.437.935 7.816 6.545 7.013 5.861s.584 1.942 1.497 5.215c.402 1.762.986 3.883 1.644 6.257z"
        fill="#00264B"
      />
      <path
        d="M64.215 171.313c.495-6.855-4.748-12.806-11.71-13.293-6.962-.488-13.007 4.674-13.502 11.529-.495 6.854 4.748 12.806 11.71 13.293 6.962.487 13.007-4.675 13.502-11.529z"
        fill="#9F616A"
      />
      <path
        d="M60.414 196.621s-3.031-9.242-10.19-9.242c-1.79 0-4.675.144-7.013 1.654-9.423 6.041-15.157 16.362-15.596 27.438l-2.191 55.342s12.09 4.315 16.472 2.697c4.383-1.618 8.803-12.442 8.803-12.442s-1.096 14.599 3.287 14.06c4.383-.539 20.855-1.618 21.44-3.776.584-2.157-8.4-48.689-8.4-48.689l-6.612-27.042z"
        fill="#C6C6C6"
      />
      <path
        d="M45.477 174.074c1.132-.072 2.228.323 3.36.467 4.018.54 8.218-2.337 9.095-6.257.073-.503.22-1.007.475-1.51.62-1.007 2.045-1.187 3.177-.971 1.133.216 2.265.755 3.434.827 1.826.108 3.58-1.007 4.565-2.553.986-1.547 1.242-3.416 1.133-5.214l-.877.899c-.365-.756-.511-1.583-.402-2.374a2.81 2.81 0 00-2.666.683c-.767.072-.183-1.402-.767-1.941-.292-.18-.621-.216-.95-.18-1.643 0-2.958-1.223-4.273-2.23a18.297 18.297 0 00-7.67-3.452c-1.863-.359-3.835-.431-5.588.252-1.462.575-2.667 1.582-3.763 2.625-2.775 2.625-5.186 5.682-6.355 9.278-.95 2.948-.95 6.077-.073 9.062.475 1.546 1.863 6.796 3.908 6.904 2.593.252 1.06-4.136 4.237-4.315z"
        fill="#00264B"
      />
      <path
        opacity={0.1}
        d="M50.809 213.018l-12.93 18.664-4.127 43.403 17.057-62.067z"
        fill="#000"
      />
      <path
        d="M36.674 289.217c2.155-2.912 1.46-7.012-1.498-9.133-.328-.216-.657-.432-1.022-.576l.803-14.923-9.094-2.697-.84 21.072c-1.388 3.381.292 7.192 3.725 8.559a6.695 6.695 0 007.926-2.302z"
        fill="#9F616A"
      />
      <path
        d="M36.638 276.955H23.489l-3.616-39.844v-.035l12.309-35.565c1.716-4.962 7.232-7.659 12.272-5.933s7.78 7.12 6.026 12.082c-.182.54-.438 1.043-.693 1.547l-14.464 25.639 1.315 42.109z"
        fill="#C6C6C6"
      />
      <path
        d="M88.392 221.721a1.656 1.656 0 01-.402-2.337l14.72-20.317a1.717 1.717 0 012.374-.396l-16.692 23.05z"
        fill="#09F"
      />
      <path
        d="M89.013 223.734l-.84-.611a1.32 1.32 0 01-.329-1.87l16.729-23.122c.365-.504 1.095-.647 1.607-.252l1.022.719-18.189 25.136z"
        fill="#2F2E41"
      />
      <path
        d="M105.629 201.595c.305-.421.419-.855.254-.97-.164-.116-.544.131-.849.552-.305.42-.418.855-.254.97.164.116.544-.132.849-.552z"
        fill="#3F3D56"
      />
      <path
        d="M104.07 200.478l-1.125 1.552.387.272 1.125-1.552-.387-.272zM102.529 202.596l-1.125 1.552.387.272 1.125-1.552-.387-.272z"
        fill="#D0CDE1"
      />
      <path
        d="M207.24 52.238l-26.845 11.41-36.651 15.57a1.03 1.03 0 01-.922 0L105.005 63.6 77.578 52.286h-.097c-.923 0-1.65.725-1.65 1.644v70.64c0 .919.727 1.644 1.65 1.644H207.24c.922 0 1.651-.725 1.651-1.644V53.882c0-.919-.729-1.644-1.651-1.644z"
        fill="#fff"
      />
      <path
        d="M207.336 52.72c-.049 0-.097 0-.146-.048L142.626 7.9c-.389-.29-.923-.29-1.36 0L77.188 52.672c-.097.097-.243.049-.34-.048-.097-.097-.049-.242.048-.339l64.079-44.772a1.69 1.69 0 011.893 0l64.565 44.772c.097.097.145.242.048.339.049.048-.048.096-.145.096z"
        fill="#979797"
      />
      <path
        d="M80.928 54.316l61.166-45.16 61.603 48.351-58.448 34.522-31.748-7.204-32.573-30.51z"
        fill="#C6C6C6"
      />
      <path
        d="M80.928 54.316l61.166-45.16 61.603 48.351-58.448 34.522-31.748-7.204-32.573-30.51z"
        fill="#fff"
        fillOpacity={0.5}
      />
      <path
        d="M116.171 113.353H85.976a1.406 1.406 0 01-1.408-1.402c0-.774.631-1.402 1.408-1.402h30.195c.777 0 1.408.628 1.408 1.402 0 .773-.631 1.402-1.408 1.402zM96.802 107.309H85.976a1.406 1.406 0 01-1.408-1.402c0-.774.631-1.402 1.408-1.402h10.826c.776 0 1.407.628 1.407 1.402 0 .773-.63 1.402-1.407 1.402z"
        fill="#09F"
      />
      <path
        d="M142.918 79.458c-.242 0-.437-.049-.679-.145l-37.719-15.57V14.959c0-.919.728-1.644 1.65-1.644h72.331c.923 0 1.651.725 1.651 1.644v48.834l-.049.049-36.457 15.52a3.753 3.753 0 01-.728.097z"
        fill="#fff"
      />
      <path
        d="M142.918 79.555a1.95 1.95 0 01-.728-.146l-37.768-15.617V14.958c0-.967.777-1.74 1.747-1.74h72.332c.971 0 1.747.773 1.747 1.74v48.883l-36.602 15.568c-.194.097-.437.145-.728.145zm-38.011-16.053l37.477 15.472c.34.145.728.145 1.068 0l36.311-15.472V14.958c0-.725-.583-1.257-1.262-1.257h-72.332a1.25 1.25 0 00-1.262 1.257v48.544z"
        fill="#979797"
      />
      <path
        d="M206.9 52.238l-26.845 11.41-36.651 15.57a1.03 1.03 0 01-.922 0L104.666 63.6 77.238 52.286h-.097c-.923 0-1.65.725-1.65 1.644v70.64c0 .919.727 1.644 1.65 1.644H206.9c.923 0 1.651-.725 1.651-1.644V53.882c0-.919-.777-1.644-1.651-1.644zm1.165 72.284c0 .629-.534 1.16-1.165 1.16H77.14a1.178 1.178 0 01-1.164-1.16v-70.64c0-.629.485-1.16 1.116-1.16l27.574 11.41 37.622 15.569c.388.145.873.145 1.262 0l36.457-15.52L206.9 52.77c.631.048 1.117.531 1.117 1.16l.048 70.592z"
        fill="#979797"
      />
      <path
        d="M137.044 31.543h-25.68c-1.019 0-1.844-.822-1.844-1.885a1.84 1.84 0 011.844-1.837h25.68c1.02 0 1.845.821 1.845 1.885.049 1.015-.777 1.837-1.845 1.837zM123.743 23.662h-12.379a1.84 1.84 0 01-1.844-1.837 1.84 1.84 0 011.844-1.837h12.379a1.84 1.84 0 011.845 1.837c.048 1.015-.777 1.837-1.845 1.837z"
        fill="#09F"
      />
      <path
        d="M161.559 50.158h-39.224c-1.02 0-1.845-.822-1.845-1.885a1.84 1.84 0 011.845-1.837h39.224c1.019 0 1.845.822 1.845 1.885a1.84 1.84 0 01-1.845 1.837z"
        fill="#C6C6C6"
      />
      <path
        d="M161.559 50.158h-39.224c-1.02 0-1.845-.822-1.845-1.885a1.84 1.84 0 011.845-1.837h39.224c1.019 0 1.845.822 1.845 1.885a1.84 1.84 0 01-1.845 1.837z"
        fill="#fff"
        fillOpacity={0.5}
      />
      <path
        d="M161.559 58.765h-39.224a1.84 1.84 0 01-1.845-1.837 1.84 1.84 0 011.845-1.837h39.224a1.84 1.84 0 011.845 1.837 1.84 1.84 0 01-1.845 1.837z"
        fill="#C6C6C6"
      />
      <path
        d="M161.559 58.765h-39.224a1.84 1.84 0 01-1.845-1.837 1.84 1.84 0 011.845-1.837h39.224a1.84 1.84 0 011.845 1.837 1.84 1.84 0 01-1.845 1.837z"
        fill="#fff"
        fillOpacity={0.5}
      />
      <path
        d="M41.072 22.115c-.34-.193-.825-.096-1.02.242-.096.145-.096.29-.096.483l.582 4.207v.193c0 .049-.048.097-.097.145l-3.252 3.82c-.243.242-.243.629-.049.919.194.29.534.387.874.29l2.33-.774c.146-.048.34 0 .437.097a.438.438 0 01.145.338l.098 8.317c0 .241.145.483.34.628.194.145.436.145.68.097l17.475-6.672a.75.75 0 00.437-.967c-.049-.145-.194-.29-.34-.387l-.242-.145-18.302-10.83zm-.825.677a.41.41 0 01.388-.483c.097 0 .195 0 .291.048l18.302 10.83.243.146c.194.145.29.386.145.628-.048.097-.145.145-.242.194l-17.477 6.672c-.242.097-.485-.048-.582-.242 0-.048-.049-.096-.049-.145l-.097-8.316c0-.097 0-.145-.048-.242l17.767 2.03a.365.365 0 00.34-.241c0-.145-.049-.242-.146-.29h-.048L40.78 27.289v-.145l-.534-4.352z"
        fill="#C6C6C6"
      />
      <path
        d="M41.072 22.115c-.34-.193-.825-.096-1.02.242-.096.145-.096.29-.096.483l.582 4.207v.193c0 .049-.048.097-.097.145l-3.252 3.82c-.243.242-.243.629-.049.919.194.29.534.387.874.29l2.33-.774c.146-.048.34 0 .437.097a.438.438 0 01.145.338l.098 8.317c0 .241.145.483.34.628.194.145.436.145.68.097l17.475-6.672a.75.75 0 00.437-.967c-.049-.145-.194-.29-.34-.387l-.242-.145-18.302-10.83zm-.825.677a.41.41 0 01.388-.483c.097 0 .195 0 .291.048l18.302 10.83.243.146c.194.145.29.386.145.628-.048.097-.145.145-.242.194l-17.477 6.672c-.242.097-.485-.048-.582-.242 0-.048-.049-.096-.049-.145l-.097-8.316c0-.097 0-.145-.048-.242l17.767 2.03a.365.365 0 00.34-.241c0-.145-.049-.242-.146-.29h-.048L40.78 27.289v-.145l-.534-4.352z"
        fill="#fff"
        fillOpacity={0.7}
      />
      <path
        d="M41.072 22.115c-.34-.193-.825-.096-1.02.242-.096.145-.096.29-.096.483l.582 4.207v.193c0 .049-.048.097-.097.145l-3.252 3.82c-.243.242-.243.629-.049.919.194.29.534.387.874.29l2.33-.774c.146-.048.34 0 .437.097a.438.438 0 01.145.338l.098 8.317c0 .241.145.483.34.628.194.145.436.145.68.097l17.475-6.672a.75.75 0 00.437-.967c-.049-.145-.194-.29-.34-.387l-.242-.145-18.302-10.83zm-.825.677a.41.41 0 01.388-.483c.097 0 .195 0 .291.048l18.302 10.83.243.146c.194.145.29.386.145.628-.048.097-.145.145-.242.194l-17.477 6.672c-.242.097-.485-.048-.582-.242 0-.048-.049-.096-.049-.145l-.097-8.316c0-.097 0-.145-.048-.242a1.01 1.01 0 00-.195-.29s-.048 0-.048-.048c-.194-.146-.485-.194-.728-.146l-2.33.774a.486.486 0 01-.534-.193.438.438 0 01.048-.532l3.253-3.82c.048-.048.048-.096.097-.145 0-.048.048-.097.048-.145 0-.048 0-.097.049-.145v-.145l-.534-4.255z"
        fill="#C6C6C6"
      />
      <path
        d="M41.072 22.115c-.34-.193-.825-.096-1.02.242-.096.145-.096.29-.096.483l.582 4.207v.193c0 .049-.048.097-.097.145l-3.252 3.82c-.243.242-.243.629-.049.919.194.29.534.387.874.29l2.33-.774c.146-.048.34 0 .437.097a.438.438 0 01.145.338l.098 8.317c0 .241.145.483.34.628.194.145.436.145.68.097l17.475-6.672a.75.75 0 00.437-.967c-.049-.145-.194-.29-.34-.387l-.242-.145-18.302-10.83zm-.825.677a.41.41 0 01.388-.483c.097 0 .195 0 .291.048l18.302 10.83.243.146c.194.145.29.386.145.628-.048.097-.145.145-.242.194l-17.477 6.672c-.242.097-.485-.048-.582-.242 0-.048-.049-.096-.049-.145l-.097-8.316c0-.097 0-.145-.048-.242a1.01 1.01 0 00-.195-.29s-.048 0-.048-.048c-.194-.146-.485-.194-.728-.146l-2.33.774a.486.486 0 01-.534-.193.438.438 0 01.048-.532l3.253-3.82c.048-.048.048-.096.097-.145 0-.048.048-.097.048-.145 0-.048 0-.097.049-.145v-.145l-.534-4.255z"
        fill="#fff"
        fillOpacity={0.5}
      />
      <path
        d="M40.831 27.192l18.253 6.092h.048c.146.096.195.241.098.386-.049.097-.195.194-.292.145l-17.767-2.03-.291-.048.048-.29h.049l18.058 2.078-18.252-6.091-.098-.049.098-.29.048.097z"
        fill="#C6C6C6"
      />
      <path
        d="M40.831 27.192l18.253 6.092h.048c.146.096.195.241.098.386-.049.097-.195.194-.292.145l-17.767-2.03-.291-.048.048-.29h.049l18.058 2.078-18.252-6.091-.098-.049.098-.29.048.097z"
        fill="#fff"
        fillOpacity={0.5}
      />
      <path
        d="M222.483.116l-16.602 13.248-.194.193c-.34.242-.389.725-.097 1.064a.51.51 0 00.437.242l18.252 4.158c.243.048.486 0 .68-.194a.63.63 0 00.243-.628l-1.068-8.22c0-.096 0-.242.097-.338a.392.392 0 01.437-.145l2.427.435c.34.048.68-.097.825-.387.146-.29.097-.677-.194-.87l-3.738-3.336c-.048-.049-.097-.097-.097-.146-.049-.048-.049-.096-.049-.193V.744c0-.435-.339-.725-.776-.725-.292-.048-.486 0-.583.097zm.923.58V5.096l-17.234 8.558s-.048 0-.048.048c-.146.097-.146.29-.049.435.097.097.194.145.34.097l17.33-4.497v.242l1.068 8.22a.409.409 0 01-.388.483h-.146l-18.252-4.158a.445.445 0 01-.34-.532c0-.096.097-.193.145-.241l.243-.194L222.677.31c.195-.145.486-.145.631.048.049.145.098.242.098.339z"
        fill="#C6C6C6"
      />
      <path
        d="M222.483.116l-16.602 13.248-.194.193c-.34.242-.389.725-.097 1.064a.51.51 0 00.437.242l18.252 4.158c.243.048.486 0 .68-.194a.63.63 0 00.243-.628l-1.068-8.22c0-.096 0-.242.097-.338a.392.392 0 01.437-.145l2.427.435c.34.048.68-.097.825-.387.146-.29.097-.677-.194-.87l-3.738-3.336c-.048-.049-.097-.097-.097-.146-.049-.048-.049-.096-.049-.193V.744c0-.435-.339-.725-.776-.725-.292-.048-.486 0-.583.097zm.923.58V5.096l-17.234 8.558s-.048 0-.048.048c-.146.097-.146.29-.049.435.097.097.194.145.34.097l17.33-4.497v.242l1.068 8.22a.409.409 0 01-.388.483h-.146l-18.252-4.158a.445.445 0 01-.34-.532c0-.096.097-.193.145-.241l.243-.194L222.677.31c.195-.145.486-.145.631.048.049.145.098.242.098.339z"
        fill="#fff"
        fillOpacity={0.7}
      />
      <path
        d="M222.483.116l-16.602 13.248-.194.193c-.34.242-.389.725-.097 1.064a.51.51 0 00.437.242l18.252 4.158c.243.048.486 0 .68-.194a.63.63 0 00.243-.628l-1.068-8.22c0-.096 0-.242.097-.338a.392.392 0 01.437-.145l2.427.435c.34.048.68-.097.825-.387.146-.29.097-.677-.194-.87l-3.738-3.336c-.048-.049-.097-.097-.097-.146-.049-.048-.049-.096-.049-.193V.744c0-.435-.339-.725-.776-.725-.292-.048-.486 0-.583.097zm.923.58V5.096c0 .048.048.096.048.145 0 .048.049.097.097.145.049.048.049.097.097.097l3.738 3.336c.146.145.194.338.097.532-.097.193-.291.29-.485.241l-2.427-.435c-.243-.048-.534.049-.68.242 0 0 0 .048-.049.048a.438.438 0 00-.145.339v.242l1.068 8.22a.41.41 0 01-.389.483h-.145l-18.253-4.159a.446.446 0 01-.34-.531c0-.097.097-.194.146-.242l.243-.194L222.629.357c.194-.145.485-.145.631.049.097.096.146.193.146.29z"
        fill="#C6C6C6"
      />
      <path
        d="M222.483.116l-16.602 13.248-.194.193c-.34.242-.389.725-.097 1.064a.51.51 0 00.437.242l18.252 4.158c.243.048.486 0 .68-.194a.63.63 0 00.243-.628l-1.068-8.22c0-.096 0-.242.097-.338a.392.392 0 01.437-.145l2.427.435c.34.048.68-.097.825-.387.146-.29.097-.677-.194-.87l-3.738-3.336c-.048-.049-.097-.097-.097-.146-.049-.048-.049-.096-.049-.193V.744c0-.435-.339-.725-.776-.725-.292-.048-.486 0-.583.097zm.923.58V5.096c0 .048.048.096.048.145 0 .048.049.097.097.145.049.048.049.097.097.097l3.738 3.336c.146.145.194.338.097.532-.097.193-.291.29-.485.241l-2.427-.435c-.243-.048-.534.049-.68.242 0 0 0 .048-.049.048a.438.438 0 00-.145.339v.242l1.068 8.22a.41.41 0 01-.389.483h-.145l-18.253-4.159a.446.446 0 01-.34-.531c0-.097.097-.194.146-.242l.243-.194L222.629.357c.194-.145.485-.145.631.049.097.096.146.193.146.29z"
        fill="#fff"
        fillOpacity={0.5}
      />
      <path
        d="M223.503 5.095l.146.29-.098.049-17.233 8.557 17.573-4.545h.049l.097.29-.291.097-17.331 4.496c-.145.049-.34-.048-.388-.193-.049-.097 0-.242.097-.338 0 0 .048 0 .048-.049l17.234-8.557.097-.097z"
        fill="#C6C6C6"
      />
      <path
        d="M223.503 5.095l.146.29-.098.049-17.233 8.557 17.573-4.545h.049l.097.29-.291.097-17.331 4.496c-.145.049-.34-.048-.388-.193-.049-.097 0-.242.097-.338 0 0 .048 0 .048-.049l17.234-8.557.097-.097z"
        fill="#fff"
        fillOpacity={0.5}
      />
      <path
        d="M112.082 146.136a.685.685 0 00-.288.904c.064.134.16.234.288.301l3.338 1.573a.445.445 0 01.128.101c.032.033.064.1.064.133l1.284 4.285a.657.657 0 00.642.469c.288 0 .545-.235.609-.502l.482-2.143c.032-.134.128-.268.288-.301.097-.033.193 0 .289.033l6.065 3.917c.193.1.385.134.578.033a.782.782 0 00.385-.468l3.144-16.369a.703.703 0 00-.513-.803.615.615 0 00-.417.067l-.225.1-16.141 8.67zm.129.937c-.193-.1-.289-.335-.193-.535.032-.067.097-.134.161-.201l16.237-8.603.224-.1a.366.366 0 01.514.167c.032.067.064.167.032.268l-3.145 16.368c-.032.201-.257.368-.449.302-.032 0-.096-.034-.128-.067l-6.065-3.883-.193-.101 9.627-12.452c.096-.1.064-.268-.032-.368a.303.303 0 00-.289-.034s-.032 0-.032.034l-12.804 10.879a.235.235 0 00-.128-.067l-3.337-1.607z"
        fill="#C6C6C6"
      />
      <path
        d="M112.082 146.136a.685.685 0 00-.288.904c.064.134.16.234.288.301l3.338 1.573a.445.445 0 01.128.101c.032.033.064.1.064.133l1.284 4.285a.657.657 0 00.642.469c.288 0 .545-.235.609-.502l.482-2.143c.032-.134.128-.268.288-.301.097-.033.193 0 .289.033l6.065 3.917c.193.1.385.134.578.033a.782.782 0 00.385-.468l3.144-16.369a.703.703 0 00-.513-.803.615.615 0 00-.417.067l-.225.1-16.141 8.67zm.129.937c-.193-.1-.289-.335-.193-.535.032-.067.097-.134.161-.201l16.237-8.603.224-.1a.366.366 0 01.514.167c.032.067.064.167.032.268l-3.145 16.368c-.032.201-.257.368-.449.302-.032 0-.096-.034-.128-.067l-6.065-3.883-.193-.101 9.627-12.452c.096-.1.064-.268-.032-.368a.303.303 0 00-.289-.034s-.032 0-.032.034l-12.804 10.879a.235.235 0 00-.128-.067l-3.337-1.607z"
        fill="#fff"
        fillOpacity={0.7}
      />
      <path
        d="M112.082 146.136a.685.685 0 00-.288.904c.064.134.16.234.288.301l3.338 1.573a.445.445 0 01.128.101c.032.033.064.1.064.133l1.284 4.285a.657.657 0 00.642.469c.288 0 .545-.235.609-.502l.482-2.143c.032-.134.128-.268.288-.301.097-.033.193 0 .289.033l6.065 3.917c.193.1.385.134.578.033a.782.782 0 00.385-.468l3.144-16.369a.703.703 0 00-.513-.803.615.615 0 00-.417.067l-.225.1-16.141 8.67zm.129.937c-.193-.1-.289-.335-.193-.535.032-.067.097-.134.161-.201l16.237-8.603.224-.1a.366.366 0 01.514.167c.032.067.064.167.032.268l-3.145 16.368c-.032.201-.257.368-.449.302-.032 0-.096-.034-.128-.067l-6.065-3.883-.193-.101a.434.434 0 00-.289 0h-.032a.608.608 0 00-.417.469l-.481 2.142a.38.38 0 01-.353.301.416.416 0 01-.385-.267l-1.284-4.285c0-.033-.032-.1-.064-.134-.032-.033-.032-.067-.064-.1l-.096-.101c-.097 0-.161-.033-.193-.033l-3.337-1.607z"
        fill="#C6C6C6"
      />
      <path
        d="M112.082 146.136a.685.685 0 00-.288.904c.064.134.16.234.288.301l3.338 1.573a.445.445 0 01.128.101c.032.033.064.1.064.133l1.284 4.285a.657.657 0 00.642.469c.288 0 .545-.235.609-.502l.482-2.143c.032-.134.128-.268.288-.301.097-.033.193 0 .289.033l6.065 3.917c.193.1.385.134.578.033a.782.782 0 00.385-.468l3.144-16.369a.703.703 0 00-.513-.803.615.615 0 00-.417.067l-.225.1-16.141 8.67zm.129.937c-.193-.1-.289-.335-.193-.535.032-.067.097-.134.161-.201l16.237-8.603.224-.1a.366.366 0 01.514.167c.032.067.064.167.032.268l-3.145 16.368c-.032.201-.257.368-.449.302-.032 0-.096-.034-.128-.067l-6.065-3.883-.193-.101a.434.434 0 00-.289 0h-.032a.608.608 0 00-.417.469l-.481 2.142a.38.38 0 01-.353.301.416.416 0 01-.385-.267l-1.284-4.285c0-.033-.032-.1-.064-.134-.032-.033-.032-.067-.064-.1l-.096-.101c-.097 0-.161-.033-.193-.033l-3.337-1.607z"
        fill="#fff"
        fillOpacity={0.5}
      />
      <path
        d="M116.205 148.368l12.764-11.015.032-.033c.129-.068.289-.034.353.101.064.102.032.204-.032.305l-9.621 12.608-.161.237-.192-.169.032-.034 9.75-12.811-12.765 11.015-.064.034-.16-.204.064-.034z"
        fill="#C6C6C6"
      />
      <path
        d="M116.205 148.368l12.764-11.015.032-.033c.129-.068.289-.034.353.101.064.102.032.204-.032.305l-9.621 12.608-.161.237-.192-.169.032-.034 9.75-12.811-12.765 11.015-.064.034-.16-.204.064-.034z"
        fill="#fff"
        fillOpacity={0.5}
      />
    </svg>
  );
}

export default SvgComponent;
