/**
 * AgentDetail scene slice
 *
 * @author Miguel Antunes <mantunes@ubiwhere.com>
 *
 *
 */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  loading: false,
  agent: undefined,
};

export default createSlice({
  name: 'AgentDetail',
  initialState,
  reducers: {
    onMount: (state, { payload }) => {
      return {
        ...state,
        loading: true,
      };
    },
    setAgentDetail: (state, { payload }) => {
      return {
        ...state,
        loading: false,
        agent: payload,
      };
    },
    onUnmount: (state, { payload }) => {},
  },
});
