/**
 * Objectives component
 *
 * @author Miguel Antunes <mantunes@ubiwhere.com>
 *
 *
 */

import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Card } from 'shared/components/ui';
import {
  Sustainability1,
  Sustainability2,
  Sustainability3,
  Sustainability4,
} from 'assets/illustrations';

const Objectives = (props) => {
  const { t } = useTranslation();

  return (
    <Wrapper>
      <Slider>
        <ObjectiveCard>
          <Sustainability1 />
          <Text>{t('home.objective_awareness')}</Text>
        </ObjectiveCard>
        <ObjectiveCard>
          <Sustainability2 />
          <Text>{t('home.objective_reduce_energy_consumption')}</Text>
        </ObjectiveCard>
        <ObjectiveCard>
          <Sustainability3 />
          <Text>{t('home.objective_trip_planning')}</Text>
        </ObjectiveCard>
        <ObjectiveCard>
          <Sustainability4 />
          <Text>{t('home.objective_citizen_collaboration')}</Text>
        </ObjectiveCard>
      </Slider>
    </Wrapper>
  );
};

export default Objectives;

const Wrapper = styled.div`
  overflow-x: scroll;
  width: 100%;
  padding-top: 1.5rem;
  margin-bottom: 2rem;
  cursor: grab;

  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
`;

const Slider = styled.div`
  display: flex;
  width: fit-content;
  padding-left: 1.5rem;
`;

const ObjectiveCard = styled(Card)`
  background-color: ${({ theme }) => theme.colors.white};
  padding: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 13rem;
  height: 13.438rem;
  margin-right: 1.5rem;
`;

const Text = styled.p`
  color: ${({ theme }) => theme.colors.primary};
  font-weight: 400;
  font-size: ${({ theme }) => theme.fontSizes.small};
  line-height: ${({ theme }) => theme.lineHeights.medium};
  text-align: center;
  letter-spacing: 0.013rem;
  margin-top: 1rem;
`;
