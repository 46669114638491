import * as React from 'react';

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={295}
      height={280}
      viewBox="0 0 295 280"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#prefix__clip0)">
        <path
          d="M117.467 186.332c21.714 0 39.318-17.616 39.318-39.346 0-21.73-17.604-39.345-39.318-39.345-21.715 0-39.319 17.615-39.319 39.345s17.604 39.346 39.319 39.346z"
          fill="#C6C6C6"
        />
        <path
          d="M117.467 186.332c21.714 0 39.318-17.616 39.318-39.346 0-21.73-17.604-39.345-39.318-39.345-21.715 0-39.319 17.615-39.319 39.345s17.604 39.346 39.319 39.346z"
          fill="#fff"
          fillOpacity={0.8}
        />
        <path
          d="M106.828 204.426l9.668 5.991v-40.52l-10.841-6.719a4.221 4.221 0 01-1.82-2.429 4.085 4.085 0 012.993.567l9.668 5.991v-15.706c0-3.036.364-6.072 1.092-9.027a37.764 37.764 0 011.092 9.027v25.664l14.279-8.865a7.374 7.374 0 013.803-1.053c-.567 1.215-1.497 2.227-2.63 2.955l-15.452 9.553v95.854h-2.225v-62.661l-10.8-6.72a4.216 4.216 0 01-1.82-2.428c1.011-.203 2.103 0 2.993.526z"
          fill="#C6C6C6"
        />
        <path
          d="M106.828 204.426l9.668 5.991v-40.52l-10.841-6.719a4.221 4.221 0 01-1.82-2.429 4.085 4.085 0 012.993.567l9.668 5.991v-15.706c0-3.036.364-6.072 1.092-9.027a37.764 37.764 0 011.092 9.027v25.664l14.279-8.865a7.374 7.374 0 013.803-1.053c-.567 1.215-1.497 2.227-2.63 2.955l-15.452 9.553v95.854h-2.225v-62.661l-10.8-6.72a4.216 4.216 0 01-1.82-2.428c1.011-.203 2.103 0 2.993.526z"
          fill="#fff"
          fillOpacity={0.1}
        />
        <path
          d="M59.217 189.651c21.715 0 39.319-17.615 39.319-39.345S80.932 110.96 59.217 110.96c-21.715 0-39.318 17.616-39.318 39.346 0 21.73 17.603 39.345 39.318 39.345z"
          fill="#C6C6C6"
        />
        <path
          d="M59.217 189.651c21.715 0 39.319-17.615 39.319-39.345S80.932 110.96 59.217 110.96c-21.715 0-39.318 17.616-39.318 39.346 0 21.73 17.603 39.345 39.318 39.345z"
          fill="#fff"
          fillOpacity={0.8}
        />
        <path
          d="M69.856 207.786l-9.668 5.991v-40.52l10.841-6.719a4.222 4.222 0 001.82-2.429 4.08 4.08 0 00-2.993.567l-9.668 5.99v-15.705c0-3.036-.364-6.072-1.092-9.027a37.754 37.754 0 00-1.092 9.027v25.663l-14.28-8.865a7.37 7.37 0 00-3.802-1.052c.567 1.214 1.497 2.226 2.63 2.955l15.452 9.553v92.737h2.225v-59.544l10.84-6.72a4.223 4.223 0 001.82-2.429 3.955 3.955 0 00-3.033.527z"
          fill="#C6C6C6"
        />
        <path
          d="M69.856 207.786l-9.668 5.991v-40.52l10.841-6.719a4.222 4.222 0 001.82-2.429 4.08 4.08 0 00-2.993.567l-9.668 5.99v-15.705c0-3.036-.364-6.072-1.092-9.027a37.754 37.754 0 00-1.092 9.027v25.663l-14.28-8.865a7.37 7.37 0 00-3.802-1.052c.567 1.214 1.497 2.226 2.63 2.955l15.452 9.553v92.737h2.225v-59.544l10.84-6.72a4.223 4.223 0 001.82-2.429 3.955 3.955 0 00-3.033.527z"
          fill="#fff"
          fillOpacity={0.1}
        />
        <path
          d="M87.776 135.976c37.397 0 67.714-30.338 67.714-67.762 0-37.423-30.317-67.761-67.714-67.761C50.378.453 20.06 30.79 20.06 68.214c0 37.424 30.317 67.762 67.715 67.762z"
          fill="#C6C6C6"
        />
        <path
          d="M87.776 135.976c37.397 0 67.714-30.338 67.714-67.762 0-37.423-30.317-67.761-67.714-67.761C50.378.453 20.06 30.79 20.06 68.214c0 37.424 30.317 67.762 67.715 67.762z"
          fill="#fff"
          fillOpacity={0.8}
        />
        <path
          d="M106.059 167.104l-16.665 10.322v-69.785l18.648-11.577a7.328 7.328 0 003.155-4.21c-1.78-.324-3.641 0-5.178.972l-16.666 10.322v-27.08c0-5.222-.647-10.444-1.9-15.545a64.637 64.637 0 00-1.902 15.544v44.203l-24.554-15.22a12.045 12.045 0 00-6.553-1.821c1.011 2.064 2.549 3.845 4.53 5.059l26.577 16.475v151.311h3.802V181.96l18.648-11.577a7.322 7.322 0 003.155-4.21c-1.699-.364-3.559 0-5.097.931z"
          fill="#C6C6C6"
        />
        <path
          d="M106.059 167.104l-16.665 10.322v-69.785l18.648-11.577a7.328 7.328 0 003.155-4.21c-1.78-.324-3.641 0-5.178.972l-16.666 10.322v-27.08c0-5.222-.647-10.444-1.9-15.545a64.637 64.637 0 00-1.902 15.544v44.203l-24.554-15.22a12.045 12.045 0 00-6.553-1.821c1.011 2.064 2.549 3.845 4.53 5.059l26.577 16.475v151.311h3.802V181.96l18.648-11.577a7.322 7.322 0 003.155-4.21c-1.699-.364-3.559 0-5.097.931z"
          fill="#fff"
          fillOpacity={0.1}
        />
        <path d="M266.649 276.074H19.778v1h246.871v-1z" fill="#C6C6C6" />
        <path d="M266.649 276.074H19.778v1h246.871v-1z" fill="#fff" fillOpacity={0.5} />
        <path
          d="M154.083 122.65l-15.869 9.169 9.163 15.88 15.868-9.169-9.162-15.88z"
          fill="#F193BD"
        />
        <path
          d="M154.277 151.034l-9.547-16.596 16.545-9.553 9.546 16.556-16.544 9.593zm-8.454-16.313l8.737 15.18 15.169-8.744-8.737-15.179-15.169 8.743z"
          fill="#00264B"
        />
        <path
          d="M139.431 195.561a7.688 7.688 0 007.686-7.691 7.688 7.688 0 00-7.686-7.691 7.688 7.688 0 00-7.685 7.691 7.688 7.688 0 007.685 7.691z"
          fill="#E2F1FF"
        />
        <path
          d="M145.094 195.966c-4.449 0-8.09-3.643-8.09-8.096s3.641-8.096 8.09-8.096c4.45 0 8.091 3.643 8.091 8.096s-3.601 8.096-8.091 8.096zm0-15.382c-4.004 0-7.281 3.279-7.281 7.286s3.277 7.286 7.281 7.286c4.005 0 7.282-3.279 7.282-7.286s-3.236-7.286-7.282-7.286z"
          fill="#4A4A4A"
        />
        <path
          d="M187.932 277.531h-74.43a3.436 3.436 0 01-3.438-3.441v-69.219a3.437 3.437 0 013.438-3.441h74.43a3.437 3.437 0 013.438 3.441v69.219a3.436 3.436 0 01-3.438 3.441z"
          fill="#09F"
        />
        <path
          d="M155.976 280h-10.518a3.436 3.436 0 01-3.438-3.441v-73.712a3.437 3.437 0 013.438-3.441h10.518a3.437 3.437 0 013.438 3.441v73.712c0 1.862-1.537 3.401-3.438 3.441zM207.712 194.59c-.445 7.124-10.072 12.346-10.072 12.346s-8.94-6.356-8.495-13.48c.445-7.124 10.073-12.346 10.073-12.346s8.899 6.355 8.494 13.48z"
          fill="#00264B"
        />
        <path
          d="M208.117 216.893c-7.12-.404-12.378-10.038-12.378-10.038s6.31-8.946 13.47-8.541c7.16.404 12.378 10.038 12.378 10.038s-6.31 8.946-13.47 8.541z"
          fill="#00264B"
        />
        <path
          opacity={0.1}
          d="M191.168 209.931v26.392l-26.293-34.69h20.023l6.27 8.298z"
          fill="#000"
        />
        <path
          d="M213.456 255.146c-1.092 0-2.103-.486-2.75-1.376l-54.73-72.255c-1.133-1.498-.85-3.684.647-4.817l8.05-6.113c1.496-1.133 3.681-.85 4.813.648l54.73 72.255c1.133 1.498.85 3.684-.647 4.817l-8.05 6.112a3.292 3.292 0 01-2.063.729z"
          fill="#09F"
        />
        <path
          d="M191.56 199.002l-13.865 10.511 10.504 13.874 13.865-10.511-10.504-13.874z"
          fill="#00264B"
        />
        <path
          d="M120.243 146.123l-4.742 17.711 17.699 4.746 4.742-17.712-17.699-4.745z"
          fill="#E2F1FF"
        />
        <path
          d="M125.678 171.233l-18.486-4.938 4.935-18.499 18.486 4.938-4.935 18.499zm-17.475-5.545l16.909 4.533 4.53-16.92-16.908-4.534-4.531 16.921z"
          fill="#00264B"
        />
        <path
          d="M47.608 122.011c0 14.127 11.448 36.633 25.524 36.633 14.077 0 25.525-22.506 25.525-36.633 0-14.127-11.448-25.542-25.525-25.542-14.076 0-25.524 11.415-25.524 25.542z"
          fill="#E6007E"
        />
        <path
          d="M72.202 159.535c3.196 1.093 3.964 5.464 2.872 8.703-1.092 3.238-3.438 5.869-4.611 9.067-.526 1.619-.93 3.279-1.133 4.938-.688 4.008-1.335 8.056-.809 12.063.486 3.805 2.023 7.408 3.762 10.849 3.317 6.638 7.403 12.912 12.095 18.701"
          stroke="#00264B"
          strokeWidth={2}
        />
        <path
          d="M70.705 161.073c1.659-1.052 3.277-1.052 4.935 0v-4.938h-4.935v4.938z"
          fill="#FF6584"
        />
        <path d="M75.6 158.523a5.567 5.567 0 01-4.935 0" stroke="#2F2E41" strokeWidth={2} />
        <path
          opacity={0.4}
          d="M62.534 117.68c2.972 0 5.38-2.9 5.38-6.477 0-3.577-2.408-6.477-5.38-6.477-2.97 0-5.38 2.9-5.38 6.477 0 3.577 2.41 6.477 5.38 6.477z"
          fill="#fff"
        />
        <path
          d="M265.233 102.338c0 14.127-11.447 36.633-25.524 36.633-14.117 0-25.525-22.506-25.525-36.633 0-14.127 11.448-25.542 25.525-25.542 14.077-.04 25.524 11.415 25.524 25.542z"
          fill="#E6007E"
        />
        <path
          d="M240.639 139.862c-3.195 1.093-3.964 5.465-2.872 8.703 1.092 3.238 3.439 5.869 4.612 9.067.526 1.619.93 3.279 1.132 4.939.688 4.007 1.335 8.055.809 12.062-.485 3.805-2.022 7.408-3.762 10.849-3.317 6.638-7.402 12.913-12.094 18.701"
          stroke="#00264B"
          strokeWidth={2}
        />
        <path
          d="M242.136 141.4c-1.659-1.052-3.277-1.052-4.935 0v-4.938h4.935v4.938z"
          fill="#FF6584"
        />
        <path d="M237.241 138.85a5.567 5.567 0 004.935 0" stroke="#2F2E41" strokeWidth={2} />
        <path
          opacity={0.4}
          d="M250.307 98.007c2.971 0 5.38-2.9 5.38-6.477 0-3.577-2.409-6.476-5.38-6.476s-5.38 2.9-5.38 6.476c0 3.577 2.409 6.477 5.38 6.477z"
          fill="#fff"
        />
        <path
          d="M197.681 75.743c-1.659-1.052-3.277-1.052-4.935 0v-4.938h4.935v4.938z"
          fill="#6C63FF"
        />
        <path d="M192.746 73.193c1.537.77 3.397.77 4.935-.04" stroke="#2F2E41" strokeWidth={2} />
        <path
          d="M196.143 74.205c-3.195 1.093-3.964 5.465-2.872 8.703 1.093 3.198 3.439 5.87 4.612 9.067.526 1.62.93 3.28 1.132 4.939.688 4.007 1.335 8.055.81 12.063-.486 3.805-2.023 7.407-3.762 10.848-3.317 6.639-7.403 12.913-12.095 18.701"
          stroke="#00264B"
          strokeWidth={2}
        />
        <path
          d="M220.738 36.64c0 14.128-11.448 36.634-25.525 36.634-14.077 0-25.524-22.506-25.524-36.633 0-14.127 11.447-25.542 25.524-25.542 14.077 0 25.525 11.415 25.525 25.542z"
          fill="#09F"
        />
        <path
          opacity={0.4}
          d="M205.811 32.35c2.971 0 5.38-2.9 5.38-6.477 0-3.576-2.409-6.476-5.38-6.476s-5.38 2.9-5.38 6.476c0 3.577 2.409 6.477 5.38 6.477z"
          fill="#fff"
        />
      </g>
      <defs>
        <clipPath id="prefix__clip0">
          <path fill="#fff" d="M0 0h295v280H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default SvgComponent;
