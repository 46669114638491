/**
 * Eventlist container
 *
 * @author Miguel Antunes <mantunes@ubiwhere.com>
 *
 *
 */

import React from 'react';
import styled from 'styled-components';

import EventCard from 'shared/components/EventCard';
import Loader from 'shared/components/ui/Loader';

const EventList = ({ loading = false, events }) => {
  if (loading)
    return (
      <Wrapper>
        <Loader />
      </Wrapper>
    );

  return (
    <Wrapper>
      {events && events.map((event) => <EventCard event={event} key={`event_${event.id}`} />)}
    </Wrapper>
  );
};

export default EventList;

const Wrapper = styled.div`
  margin-top: 1rem;

  > * {
    margin-bottom: 1.5rem;
    :last-child {
      margin-bottom: 0;
    }
  }
`;
