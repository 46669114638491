/**
 * Account scene
 *
 * @author Miguel Antunes <mantunes@ubiwhere.com>
 *
 *
 */
import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';

import { actions } from 'store/rootSlices';
import { Back } from 'assets/icons';
import { Heading2 } from 'shared/components/ui';
import Achievements from './containers/Achievements';
import UserAccountForm from './containers/UserAccountForm';

const Account = ({ t, i18n }) => {
  const { navigateBack } = actions.App;
  const dispatch = useDispatch();
  //const { } = useSelector(state => state.Account)

  const { onMount, onUnmount } = actions.Account;
  //const { api_t } = actions.App

  useEffect(() => {
    dispatch(onMount());
    return () => {
      dispatch(onUnmount());
    };
  }, [dispatch, onMount, onUnmount]);

  return (
    <Wrapper>
      <Header>
        <Return
          onClick={() => {
            dispatch(navigateBack());
          }}
        >
          <Back />
        </Return>
        <Achievements t={t} />
      </Header>
      <WrapperProfile>
        <Title>{t('account.title')}</Title>
        <UserAccountForm t={t} />
      </WrapperProfile>
    </Wrapper>
  );
};

export default Account;

const Wrapper = styled.div`
  padding-bottom: ${({ theme }) => theme.dimensions.navBarHeight};
`;

const Header = styled.div`
  border-radius: 0 0 1rem 1rem;
  background-color: ${({ theme }) => theme.colors.primary};
  padding: 3.25rem 1rem 2.5rem;
  box-shadow: 0rem 0.25rem 0.75rem 0.25rem rgba(0, 0, 0, 0.2);
  z-index: 2;
  * {
    color: ${({ theme }) => theme.colors.white};
  }
`;

const WrapperProfile = styled.div`
  padding: 2.5rem 1rem;
`;

const Return = styled.div`
  margin-bottom: 1rem;
  svg path {
    fill: ${({ theme }) => theme.colors.white};
  }
`;

const Title = styled(Heading2)`
  margin-bottom: 2rem;
`;
