import { TokenResponse } from 'api/types';
import { AxiosResponse } from 'axios';
import axios from 'axios';
import ENDPOINTS from '../endpoints';
import config from 'config';
import Cookies from 'js-cookie';

const wso2Instance = axios.create({});
const { OAUTH2 } = config;

wso2Instance.defaults.headers.post['Content-Type'] =
  'application/x-www-form-urlencoded;charset=UTF-8';
wso2Instance.defaults.headers.get['Content-Type'] = 'multipart/form-data';
wso2Instance.defaults.headers.get['Authorization'] = `Basic ${btoa(
  `${OAUTH2.CLIENT_ID}:${OAUTH2.CLIENT_SECRET}`
)}`;

const authorize = () => {
  return wso2Instance
    .post(ENDPOINTS.AUTH.AUTHORIZE, null, {
      params: {
        ...OAUTH2.AUTHORIZE,
        client_id: OAUTH2.CLIENT_ID,
      },
    })
    .then((res) => res)
    .catch((err) => {
      throw err;
    });
};

const token = ({ code }): Promise<AxiosResponse<TokenResponse>> => {
  return wso2Instance
    .post(ENDPOINTS.AUTH.TOKEN, null, {
      params: {
        ...OAUTH2.TOKEN,
        client_id: OAUTH2.CLIENT_ID,
        code: code,
      },
    })
    .then((res) => res)
    .catch((err) => {
      throw err;
    });
};

const refresh = ({ refresh_token }): Promise<AxiosResponse<TokenResponse>> => {
  return wso2Instance
    .post(ENDPOINTS.AUTH.TOKEN, null, {
      params: {
        ...OAUTH2.REFRESH,
        refresh_token: refresh_token,
      },
      headers: {
        Authorization: `Basic ${btoa(`${OAUTH2.CLIENT_ID}:${OAUTH2.CLIENT_SECRET}`)}`,
      },
    })
    .then((res) => res)
    .catch((err) => {
      throw err;
    });
};

const getUser = () => {
  const access_token = Cookies.get('access_token');

  return wso2Instance
    .get(ENDPOINTS.AUTH.USER, {
      params: {
        schema: 'openid',
      },
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
    })
    .then((res) => res)
    .catch((err) => {
      throw err;
    });
};

export default {
  authorize,
  token,
  refresh,
  getUser,
};
