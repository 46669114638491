/**
 * Register scene
 *
 * @author Miguel Antunes <mantunes@ubiwhere.com>
 *
 *
 */
import React, { useEffect } from 'react'
import styled from 'styled-components'

import { useDispatch, /*useSelector*/ } from 'react-redux'

import { actions } from 'store/rootSlices'

//import config from 'config'

const Register = ({ t, i18n }) => {

  //const { } = useSelector(state => state.Register)

  const { onMount, onUnmount} = actions.Register
  //const { api_t } = actions.App

  const dispatch = useDispatch()

  useEffect(() => {

    dispatch(onMount())
    return () => {
      dispatch(onUnmount())
    }
  }, [dispatch, onMount, onUnmount]);

  return (
    <Wrapper>
      Register
    </Wrapper>
  )
}

export default Register

const Wrapper = styled.div``