import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';

import { actions, RootState } from 'store/rootSlices';

import { Button as StyledButton } from 'shared/components/ui';

const Dashboard = ({ t, i18n }) => {
  //const { api_t } = useSelector((state: RootState) => state.App);
  const { value, welcome } = useSelector((state: RootState) => state.Dashboard);

  const { onMount, onUnmount, setValue, updateValue } = actions.Dashboard;
  const { navigateTo } = actions.App;

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(onMount());
    return () => {
      dispatch(onUnmount());
    };
  }, [dispatch, onMount, onUnmount]);

  return (
    <Wrapper>
      <Text>{welcome}</Text>
      <TextSmall>{value}</TextSmall>
      <UIComponents>
        <StyledButton primary>Button</StyledButton>
        <StyledButton primary active={false}>
          Button Outlined
        </StyledButton>
        <StyledButton>Button Secondary</StyledButton>
        <StyledButton active={false}>Button Outlined</StyledButton>
        <StyledButton disabled>Button Disabled</StyledButton>
      </UIComponents>
      <Buttons>
        <Button
          onClick={() => {
            dispatch(setValue('I was called by an Action'));
          }}
        >
          CALL ACTION
        </Button>
        <Button
          onClick={() => {
            dispatch(updateValue());
          }}
        >
          CALL SAGA
        </Button>
        <Button
          onClick={() => {
            dispatch(navigateTo('settings'));
          }}
        >
          NAVIGATE TO SETTINGS
        </Button>
      </Buttons>

      {t('helloWorld')}
    </Wrapper>
  );
};

export default Dashboard;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: center;
  padding: 0 1.6rem;
`;

const Text = styled.h1`
  color: ${({ theme }) => theme.colors.primary};
  padding: 0.6rem 1.2rem 0.6rem 1.2rem;
`;

const TextSmall = styled.div`
  color: ${({ theme }) => theme.colors.primary};
  padding: 0.6rem 1.2rem 0.6rem 1.2rem;
  font-weight: bold;
  font-size: 1.5rem;
`;

const Buttons = styled.div``;
const UIComponents = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  ${StyledButton} {
    max-width: 25rem;
  }
  width: 100%;
  > * {
    margin: 0.8rem 0;
  }
`;

const Button = styled.button`
  display: inline-block;
  border: none;
  padding: 0.5rem 2rem;
  margin: 0;
  text-decoration: none;
  background: ${({ theme }) => theme.colors.primaryMedium};
  color: #ffffff;
  font-family: sans-serif;
  font-size: ${({ theme }) => theme.fontSizes.extraSmall};
  line-height: 1;
  cursor: pointer;
  text-align: center;
  transition: background 250ms ease-in-out, transform 150ms ease;

  &:hover {
    background: #0053ba;
  }
`;
