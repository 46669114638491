/**
 * Point component
 *
 * @author Miguel Antunes <mantunes@ubiwhere.com>
 *
 *
 */

import React from 'react';
import styled from 'styled-components';

const Point = ({ active }) => {
  return (
    <Wrapper active={active}>
      <Circle>
        <Circle>
          <Circle center active={active} />
        </Circle>
      </Circle>
    </Wrapper>
  );
};

export default Point;

const Wrapper = styled.div<{ active: boolean }>`
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.primary};
  * {
    background: ${({ active }) => (active ? 'rgba(0, 153, 255, 0.3)' : 'rgba(196, 196, 196, 0.5)')};
  }
  z-index: 3;
`;
const Circle = styled.div<{ center?: boolean; active?: boolean }>`
  background: ${({ theme, active, center }) =>
    center && (active ? theme.colors.secondary : theme.colors.gray)};
  width: fit-content;
  border-radius: 50%;
  padding: 0.3125rem;
`;
