/**
 * -----------------------------------------------------------------------------
 * Copyright (C) Ubiwhere, LDA
 * 2012-2018
 *
 *
 * The reproduction, transmission or use of this document or its contents is not
 * permitted without express written authorization. All rights, including rights
 * created by patent grant or registration of a utility model or design, are
 * reserved. Modifications made to this document are restricted to authorized
 * personnel only. Technical specifications and features are binding only when
 * specifically and expressly agreed upon in a written contract.
 * -----------------------------------------------------------------------------
 *
 * Router
 * Add new routes here
 */
// Import page components bellow here
// Keep them organized as the routes object
import Dashboard from 'scenes/Dashboard';
// WARNING!! Don't erase the nextline as it is necessary for hygen to automatically import your components
// Define imports here
import Contacts from 'scenes/Contacts';
import Terms from 'scenes/Terms';
import Settings from 'scenes/Settings';
import About from 'scenes/About';
import Itineraries from 'scenes/Itineraries';
import Account from 'scenes/Account';
import Register from 'scenes/Register';
import Login from 'scenes/Login';
import LandingPage from 'scenes/LandingPage';
import RecommendationDetail from 'scenes/RecommendationDetail';
import EventRating from 'scenes/EventRating';
import AgentDetail from 'scenes/AgentDetail';
import EventDetail from 'scenes/EventDetail';
import NewRecommendation from 'scenes/NewRecommendation';
import Menu from 'scenes/Menu';
import Recommendations from 'scenes/Recommendations';
import Events from 'scenes/Events';
import Explore from 'scenes/Explore';
import Home from 'scenes/Home';

import config from 'config';

const { ROLES } = config;

// Define routes here
// WARNING!! Don't erase the nextline as it is necessary for hygen to automatically import your components
const Routes = [
  {
    exact: true,
    key: 'contacts',
    name: 'Contacts',
    path: '/menu/contacts',
    roles: [ROLES.GUEST],
    component: Contacts,
  },
  {
    exact: true,
    key: 'terms',
    name: 'Terms',
    path: '/menu/termsandconditions',
    roles: [ROLES.GUEST],
    component: Terms,
  },
  {
    exact: true,
    key: 'settings',
    name: 'Settings',
    path: '/menu/settings',
    roles: [ROLES.GUEST],
    component: Settings,
  },
  {
    exact: true,
    key: 'about',
    name: 'About',
    path: '/menu/about',
    roles: [ROLES.GUEST],
    component: About,
  },
  {
    exact: true,
    key: 'itineraries',
    name: 'Itineraries',
    path: '/menu/itineraries',
    roles: [ROLES.GUEST],
    component: Itineraries,
  },
  {
    exact: true,
    key: 'account',
    name: 'Account',
    path: '/menu/account',
    roles: [ROLES.GUEST],
    component: Account,
  },
  {
    exact: true,
    key: 'landingPage',
    name: 'LandingPage',
    path: '/',
    roles: [ROLES.GUEST],
    component: LandingPage,
  },
  {
    exact: true,
    key: 'register',
    name: 'Register',
    path: '/register',
    roles: [ROLES.GUEST],
    component: Register,
  },
  {
    exact: true,
    key: 'login',
    name: 'Login',
    path: '/login',
    roles: [ROLES.GUEST],
    component: Login,
  },
  {
    exact: true,
    key: 'recommendationDetail',
    name: 'RecommendationDetail',
    path: '/recommendations/:id',
    roles: [ROLES.GUEST],
    component: RecommendationDetail,
  },
  {
    exact: true,
    key: 'eventRating',
    name: 'EventRating',
    path: '/events/:id/rating',
    roles: [ROLES.GUEST],
    component: EventRating,
  },
  {
    exact: true,
    key: 'agentdetail',
    name: 'AgentDetail',
    path: '/events/agents/:id',
    roles: [ROLES.GUEST],
    component: AgentDetail,
  },
  {
    exact: true,
    key: 'newRecommendation',
    name: 'NewRecommendation',
    path: '/recommendations/new',
    roles: [ROLES.GUEST],
    component: NewRecommendation,
  },
  {
    exact: true,
    key: 'menu',
    name: 'Menu',
    path: '/menu',
    roles: [ROLES.GUEST],
    component: Menu,
  },
  {
    exact: true,
    key: 'recommendations',
    name: 'Recommendations',
    path: '/recommendations',
    roles: [ROLES.GUEST],
    component: Recommendations,
  },
  {
    exact: true,
    key: 'events',
    name: 'Events',
    path: '/events',
    roles: [ROLES.GUEST],
    component: Events,
  },
  {
    exact: true,
    key: 'eventDetail',
    name: 'EventDetail',
    path: '/events/:id',
    roles: [ROLES.GUEST],
    component: EventDetail,
  },
  {
    exact: true,
    key: 'explore',
    name: 'Explore',
    path: '/explore',
    roles: [ROLES.GUEST],
    component: Explore,
  },
  {
    exact: true,
    key: 'home',
    name: 'Home',
    path: '/home',
    roles: [ROLES.GUEST],
    component: Home,
  },
  {
    exact: true,
    key: 'dashboard',
    name: 'Dashboard',
    path: '/dashboard',
    roles: [ROLES.GUEST],
    component: Dashboard,
  },
];

// Maps don't work on object convert it to an array
export default Routes;
