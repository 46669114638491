/**
 * App
 * Please write a description
 *
 * @author Carlos Silva <csilva@ubiwhere.com>
 *
 *
 */

import React, { useEffect } from 'react';
import styled, { ThemeProvider } from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { Switch, Route } from 'react-router';
import { useLocation } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

import GlobalStyles from 'ui/globalStyles';
import theme from 'ui/theme';

import Routes from 'routes';

import { actions, RootState } from 'store/rootSlices';
import Navbar from 'shared/containers/Navbar';
import { shouldRenderNavbar } from './utils';
import ScrollToTop from './ScrollToTop';

function App(props) {
  const { t, i18n } = props;
  const location = useLocation();
  const { user, isAuth } = useSelector((state: RootState) => state.App);

  const { onMount } = actions.App;

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(onMount());
  }, [dispatch, onMount]);

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      <AppWrapper>
        <AuthIndicator isAuth={isAuth} />
        <ScrollToTop />
        <Switch>
          {Routes.map(
            (route) =>
              route.roles.indexOf(user.role) !== -1 && (
                <Route
                  exact={route.exact}
                  key={route.key}
                  path={route.path}
                  render={() => <route.component t={t} i18n={i18n} />}
                />
              )
          )}
        </Switch>
        {shouldRenderNavbar(location) && <Navbar {...props} />}
      </AppWrapper>
    </ThemeProvider>
  );
}

export default withTranslation()(App);

const AppWrapper = styled.div`
  display: flex;
  background-color: ${({ theme }) => theme.colors.background};
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  position: relative;
`;

const AuthIndicator = styled.div<{ isAuth: null | string }>`
  background: ${({ isAuth }) => (isAuth !== null ? 'lime' : 'lightgray')};
  height: 1rem;
  width: 1rem;
  border-radius: 0rem 0rem 0.5rem 0rem;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 300;
`;
