/**
 * Home scene sagas
 *
 * @author Miguel Antunes <mantunes@ubiwhere.com>
 *
 *
 */
import { call, putResolve, takeLatest } from 'redux-saga/effects';

import { actions } from 'store/rootSlices';
import API from 'api';

function* onMountSaga() {
  yield putResolve(actions.Home.getUpcommingEvents());
}

function* getUpcommingEventsSaga() {
  try {
    const response = yield call(API.Events.getEvents, { params: null, mocked: true });
    yield putResolve(actions.Home.setUpcommingEvents(response.data.results));
  } catch (error) {
    yield console.log('getUserEventsSaga onMountSaga:', error);
  }
}

function* onUnmountSaga() {}

export default function* watcherSignin() {
  yield takeLatest('Home/onMount', onMountSaga);
  yield takeLatest('Home/getUpcommingEvents', getUpcommingEventsSaga);
  yield takeLatest('Home/onUnmount', onUnmountSaga);
}
