import axios from 'axios';
import { camelizeKeys, decamelizeKeys } from 'humps';
import i18n from 'i18next';
import Cookies from 'js-cookie';
import store from 'store';
import { actions } from 'store/rootSlices';

const decamelizeKeysTransformer = function (data) {
  return data && JSON.stringify(decamelizeKeys(data));
};

// Converts all responses for CamelCase
axios.interceptors.response.use(
  (response) => {
    response.data = camelizeKeys(response.data);
    return response;
  },
  (error) => {
    if (error.response.status === 401) {
      // Handles unauthorized status
      store.dispatch(actions.App.checkAuthentication(error));
    } else {
      return Promise.reject(error);
    }
  }
);

axios.interceptors.request.use(
  (config) => {
    const access = Cookies.get('access_token');
    if (access) {
      config.headers.Authorization = access ? `Bearer ${access}` : null;
    } else {
      delete config.headers.Authorization;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export const removeAuthorizationInterceptor = () => {
  axios.interceptors.request.use(
    function (config) {
      delete config.headers.Authorization;
      return config;
    },
    function (error) {
      return Promise.reject(error);
    }
  );
};

axios.interceptors.request.use(
  (config) => {
    config.headers['Accept-Language'] = i18n.language;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Converts all requests to under-cased
axios.interceptors.request.use(
  (config) => {
    const currentContentType = config.headers['Content-Type'];
    // Converts URL get params to underscored
    if (config.params) {
      config.params = decamelizeKeys(config.params);
    }
    if (!currentContentType) {
      config.headers['Content-Type'] = 'application/json';
      config.transformRequest = [decamelizeKeysTransformer];
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

export default axios;
