/**
 * Login scene sagas
 *
 * @author Miguel Antunes <mantunes@ubiwhere.com>
 *
 *
 */
import { putResolve, takeLatest } from 'redux-saga/effects';
import { actions } from 'store/rootSlices';

function* onMountSaga({ payload }: ReturnType<any>) {
  if (payload.code) {
    yield putResolve(actions.App.getAuthToken(payload));
  } else {
    yield putResolve(actions.App.navigateTo('/'));
  }
}

function* onUnmountSaga() {}

export default function* watcherSignin() {
  yield takeLatest('Login/onMount', onMountSaga);
  yield takeLatest('Login/onUnmount', onUnmountSaga);
}
