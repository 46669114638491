import * as React from 'react';

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M21.52 11.21c.438-.438.59-1.089.398-1.695-.188-.6-.677-1.034-1.28-1.125l-4.892-.742a.188.188 0 01-.14-.105L13.422 2.92c-.27-.565-.817-.92-1.42-.92s-1.149.35-1.42.92L8.394 7.543a.17.17 0 01-.14.105l-4.892.742c-.603.091-1.093.524-1.28 1.125a1.678 1.678 0 00.397 1.695l3.543 3.598c.044.046.065.11.052.173l-.834 5.083c-.105.624.14 1.24.629 1.617a1.526 1.526 0 001.669.128l4.377-2.4a.197.197 0 01.175 0l4.377 2.4c.231.123.485.191.734.191.336 0 .655-.11.93-.319.49-.373.734-.988.629-1.617l-.834-5.083a.216.216 0 01.052-.173l3.543-3.598zM17.315 20.5c-.044.032-.113.064-.197.014l-4.377-2.4a1.502 1.502 0 00-.738-.191c-.258 0-.51.063-.738.19l-4.377 2.401c-.066.037-.131.032-.197-.014a.195.195 0 01-.074-.186l.834-5.083a1.71 1.71 0 00-.454-1.462L3.453 10.17a.194.194 0 01-.048-.196.179.179 0 01.153-.132l4.893-.742a1.595 1.595 0 001.192-.907l2.189-4.623a.18.18 0 01.17-.109c.044 0 .122.014.17.11l2.19 4.618a1.58 1.58 0 001.192.906l4.892.743c.105.013.14.1.153.132a.184.184 0 01-.048.196l-3.543 3.598a1.675 1.675 0 00-.454 1.462l.834 5.083c.018.119-.06.178-.074.192z"
        fill={props.fill || '#000'}
      />
    </svg>
  );
}

export default SvgComponent;
