/**
 * Eventcard component
 *
 * @author Miguel Antunes <mantunes@ubiwhere.com>
 *
 *
 */
import React from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';

import { actions } from 'store/rootSlices';
import theme from 'ui/theme';
import { Forward, Sustainable } from 'assets/icons';
import { Badge, Card, Date, Heading3 } from 'shared/components/ui';
import { Event } from 'api/types';

const EventCard = ({ event, mock = false }: { event: Event; mock?: boolean }) => {
  const dispatch = useDispatch();
  const { navigateTo } = actions.App;

  return (
    <Wrapper>
      <Card
        onClick={() => {
          dispatch(navigateTo(`/events/${event.id}`));
        }}
      >
        <Row>
          <Image src={event.seeAlso}>
            <Category>
              {event.categories?.[0] && <Badge type={event.categories[0].name} />}
            </Category>
          </Image>
          <WrapperInfo>
            <RowSpaced>
              <Title>{event.name}</Title>
              {event.sustainable && <Sustainable fill={theme.colors.green} />}
            </RowSpaced>
            <Location>{event.address}</Location>
            <RowSpacedAligned>
              <Date date={event.startDate} />
              <Forward fill={theme.colors.primary} />
            </RowSpacedAligned>
          </WrapperInfo>
        </Row>
      </Card>
    </Wrapper>
  );
};

export default EventCard;

const Wrapper = styled.div`
  min-height: 8.5rem;
`;

const WrapperInfo = styled.div`
  padding: 1.125rem 1rem;
  display: flex;
  flex: 1;
  flex-direction: column;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
`;

const RowSpaced = styled(Row)`
  justify-content: space-between;
`;

const Title = styled(Heading3)`
  margin-bottom: 0.25rem;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const RowSpacedAligned = styled(RowSpaced)`
  align-items: center;
`;

const Image = styled.div<{ src: string }>`
  background-image: url(${({ src }) => src});
  width: 8.5rem;
  height: 8.5rem;
  border-radius: 1rem;
  padding: 0.5rem;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  position: relative;
`;

const Category = styled.div`
  position: absolute;
  bottom: 0.5rem;
  left: 0.5rem;
  max-width: 7.5rem;
`;

const Label = styled.label`
  font-size: ${({ theme }) => theme.fontSizes.small};
  line-height: ${({ theme }) => theme.lineHeights.medium};
  letter-spacing: 0.013rem;
`;

const Location = styled(Label)`
  margin-bottom: 0.25rem;
  color: ${({ theme }) => theme.colors.primary};
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`;
