/**
 * Explore scene
 *
 * @author Miguel Antunes <mantunes@ubiwhere.com>
 *
 *
 */
import React, { useEffect } from 'react';
import styled from 'styled-components';

import { useDispatch /*useSelector*/ } from 'react-redux';

import { actions } from 'store/rootSlices';

//import config from 'config'

const Explore = ({ t, i18n }) => {
  //const { } = useSelector(state => state.Explore)

  const { onMount, onUnmount } = actions.Explore;
  //const { api_t } = actions.App

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(onMount());
    return () => {
      dispatch(onUnmount());
    };
  }, [dispatch, onMount, onUnmount]);

  return <Wrapper>Explore</Wrapper>;
};

export default Explore;

const Wrapper = styled.div`
  display: flex;
  flex: 1;
`;
