/**
 * LandingPage scene sagas
 *
 * @author Miguel Antunes <mantunes@ubiwhere.com>
 *
 *
 */
import { delay, put, takeLatest } from 'redux-saga/effects';

import { actions } from 'store/rootSlices';

function* onMountSaga({ payload }) {
  yield delay(5000);
  yield put(actions.LandingPage.setLoading({ value: false }));
}

function* onUnmountSaga({ payload }) {}

export default function* watcherSignin() {
  yield takeLatest('LandingPage/onMount', onMountSaga);
  yield takeLatest('LandingPage/onUnmount', onUnmountSaga);
}
