/**
 * Button component
 *
 * @author Miguel Antunes <mantunes@ubiwhere.com>
 *
 *
 */

import styled, { css } from 'styled-components';

interface Props {
  active?: boolean;
  primary?: boolean;
  disabled?: boolean;
  flat?: boolean;
  highlight?: boolean;
}

export default styled.button<Props>`
  margin: 0;
  display: inline-block;
  border: none;
  padding: 0.75rem 1rem;
  background: ${({ theme }) => theme.colors.secondary};
  color: ${({ theme }) => theme.colors.white};
  font-size: ${({ theme }) => theme.fontSizes.large};
  font-weight: 700;
  font-style: normal;
  line-height: ${({ theme }) => theme.lineHeights.medium};
  border-radius: 1.875rem;
  border: 0.063rem solid ${({ theme }) => theme.colors.secondary};
  cursor: pointer;
  text-align: center;
  transition: background 250ms ease-in-out, transform 150ms ease;
  width: 100%;
  letter-spacing: 0.013rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  svg path {
    fill: ${({ theme }) => theme.colors.white};
  }

  ${({ theme, primary }) =>
    primary &&
    css`
      background: ${() => theme.colors.primary};
      border: 0.063rem solid ${() => theme.colors.primary};
    `}

  ${({ theme, highlight }) =>
    highlight &&
    css`
      background: ${() => theme.colors.highlight};
      border: 0.063rem solid ${() => theme.colors.highlight};
    `}

  ${({ theme, active = true, primary, highlight }) =>
    !active &&
    css`
      color: ${() => (primary ? theme.colors.primary : theme.colors.secondary)};
      background: ${() => theme.colors.white};
      border: 0.063rem solid
        ${() =>
          primary
            ? theme.colors.primary
            : highlight
            ? theme.colors.highlight
            : theme.colors.secondary};

      svg path {
        fill: ${() => (primary ? theme.colors.primary : theme.colors.secondary)};
      }
    `}

  ${({ flat = false }) =>
    flat &&
    css`
      border: none;
    `}

  ${({ theme, disabled }) =>
    disabled &&
    css`
      color: ${() => theme.colors.white};
      background: ${() => theme.colors.gray};
      border: none;
      svg path {
        fill: ${() => theme.colors.white};
      }
    `}
`;
