import * as React from 'react';

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M11.792 15.475a1.008 1.008 0 00-.992 1.042.992.992 0 001.025.958h-.033c.55-.01.991-.458.991-1.008a1.016 1.016 0 00-.991-.992zM8.858 7.833a4.166 4.166 0 013.125-1.275c1.975 0 3.175 1.084 3.175 2.5-.037 1.284-.947 1.922-1.755 2.49-.656.459-1.245.872-1.245 1.544 0 .112.04.216.08.32.04.105.079.209.079.321a.608.608 0 01-.609.609h-.016c-.625 0-.925-.892-.925-1.375 0-1.061.773-1.611 1.495-2.126.634-.45 1.23-.874 1.23-1.591s-.542-1.325-1.667-1.325a2.727 2.727 0 00-1.975.833.703.703 0 01-.467.175.692.692 0 01-.666-.683.652.652 0 01.141-.417z"
        fill={props.fill || '#000'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 2C6.486 2 2 6.486 2 12s4.486 10 10 10 10-4.486 10-10S17.514 2 12 2zm0 18.333c-4.595 0-8.333-3.738-8.333-8.333 0-4.595 3.738-8.333 8.333-8.333 4.595 0 8.333 3.738 8.333 8.333 0 4.595-3.738 8.333-8.333 8.333z"
        fill={props.fill || '#000'}
      />
    </svg>
  );
}

export default SvgComponent;
