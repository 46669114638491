/**
 * EventDetail scene slice
 *
 * @author Miguel Antunes <mantunes@ubiwhere.com>
 *
 *
 */
import { createSlice } from '@reduxjs/toolkit';
import { EventDetailState } from '../types';

const initialState: EventDetailState = {
  loading: false,
  event: null,
};

export default createSlice({
  name: 'EventDetail',
  initialState,
  reducers: {
    onMount: (state, { payload }) => {
      state.loading = true;
    },
    setEvent: (state, { payload }) => {
      state.loading = false;
      state.event = payload;
    },
    onUnmount: (state) => {},
  },
});
