import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
  &&&{
    html {
      font-size: 16px;
    }

    body{
      font-family: 'Ubuntu', sans-serif !important;
    }

    body, h1, h2, h3, h4, h5, h6, p, ol, ul {
      margin: 0;
      padding: 0;
      font-weight: normal;
    }

    ol, ul {
      list-style: none;
    }

    input, button, submit { border:none; background:none } 

    @font-face {
      font-family: 'Ubuntu' !important;
      src:  local('Ubuntu'), 
            url('fonts/Ubuntu/Ubuntu-Bold.ttf') format("truetype");
      font-weight: 700;
      font-style: normal;
    }
    
    @font-face {
      font-family: 'Ubuntu' !important;
      src:  local('Ubuntu'), 
            url('fonts/Ubuntu/Ubuntu-BoldItalic.ttf') format("truetype");
      font-weight: 700;
      font-style: italic;
    }

    @font-face {
      font-family: 'Ubuntu' !important;
      src:  local('Ubuntu'), 
            url('fonts/Ubuntu/Ubuntu-Italic.ttf') format("truetype");
      font-weight: normal;
      font-style: italic;
    }

    @font-face {
      font-family: 'Ubuntu' !important;
      src:  local('Ubuntu'), 
            url('fonts/Ubuntu/Ubuntu-Light.ttf') format("truetype");
      font-weight: 300;
      font-style: normal;
    }

    @font-face {
      font-family: 'Ubuntu' !important;
      src:  local('Ubuntu'), 
            url('fonts/Ubuntu/Ubuntu-LightItalic.ttf') format("truetype");
      font-weight: 300;
      font-style: italic;
    }

    @font-face {
      font-family: 'Ubuntu' !important;
      src:  local('Ubuntu'), 
            url('fonts/Ubuntu/Ubuntu-Medium.ttf') format("truetype");
      font-weight: 500;
      font-style: normal;
    }

    @font-face {
      font-family: 'Ubuntu' !important;
      src:  local('Ubuntu'), 
            url('fonts/Ubuntu/Ubuntu-MediumItalic.ttf') format("truetype");
      font-weight: 500;
      font-style: italic;
    }

    @font-face {
      font-family: 'Ubuntu' !important;
      src:  local('Ubuntu'), 
            url('fonts/Ubuntu/Ubuntu-Regular.ttf') format("truetype");
      font-weight: normal;
      font-style: normal;
    }
  }
`;
