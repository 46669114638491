/**
 * Loader component
 *
 * @author Miguel Antunes <mantunes@ubiwhere.com>
 *
 *
 */

import { CircularProgress } from '@material-ui/core';
import React from 'react';
import styled from 'styled-components';

const Loader = ({ full = false }) => {
  return (
    <Wrapper full={full}>
      <ProgressLoader />
    </Wrapper>
  );
};

export default Loader;

const Wrapper = styled.div<{ full?: boolean }>`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: ${({ theme, full }) => (full ? theme.dimensions.navBarHeight : 0)};
`;

const ProgressLoader = styled(CircularProgress)`
  &.MuiCircularProgress-root {
    color: ${({ theme }) => theme.colors.gray};
  }
`;
