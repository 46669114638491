/**
 * Card component
 *
 * @author Miguel Antunes <mantunes@ubiwhere.com>
 *
 *
 */

import styled from 'styled-components';

interface Props {
  backgroundColor?: string;
}

export default styled.div<Props>`
  border-radius: 1rem;
  box-shadow: 0rem 0rem 2.25rem rgba(0, 0, 0, 0.16);
  background-color: ${({ backgroundColor, theme }) => backgroundColor || theme.colors.white};
`;
