import * as React from 'react';

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M22 14.969a.781.781 0 01-1.563 0V6.688c0-.862-.7-1.563-1.562-1.563h-1.016v.781a.781.781 0 01-1.562 0v-.781h-3.555v.781a.781.781 0 01-1.562 0v-.781H7.664v.781a.781.781 0 11-1.562 0v-.781h-.977c-.862 0-1.563.7-1.563 1.563v12.187c0 .862.701 1.563 1.563 1.563h13.75c.862 0 1.563-.701 1.563-1.563a.781.781 0 011.562 0A3.129 3.129 0 0118.875 22H5.125A3.129 3.129 0 012 18.875V6.687a3.129 3.129 0 013.125-3.125h.977v-.78a.781.781 0 111.562 0v.78h3.516v-.78a.781.781 0 111.562 0v.78h3.555v-.78a.781.781 0 111.562 0v.78h1.016A3.129 3.129 0 0122 6.688v8.28z"
        fill={props.fill || '#000'}
      />
      <path
        d="M17.86 10.203a.781.781 0 11-1.563 0 .781.781 0 011.562 0z"
        fill={props.fill || '#000'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.46 17.781c-.86 0-1.562-.7-1.562-1.562v-1.836c0-.862.701-1.563 1.563-1.563h1.836c.861 0 1.562.701 1.562 1.563v1.836c0 .861-.7 1.562-1.562 1.562H14.46zm1.837-3.398H14.46v1.836h1.836v-1.836z"
        fill={props.fill || '#000'}
      />
      <path
        d="M13.68 10.984a.781.781 0 100-1.562.781.781 0 000 1.562zM6.883 17.781a.781.781 0 100-1.562.781.781 0 000 1.562zM7.664 10.203a.781.781 0 11-1.562 0 .781.781 0 011.562 0zM6.883 14.383a.781.781 0 100-1.563.781.781 0 000 1.563zM11.063 13.602a.781.781 0 11-1.563 0 .781.781 0 011.563 0zM10.281 10.984a.781.781 0 100-1.562.781.781 0 000 1.562zM11.063 17A.781.781 0 119.5 17a.781.781 0 011.563 0z"
        fill={props.fill || '#000'}
      />
    </svg>
  );
}

export default SvgComponent;
