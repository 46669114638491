import * as React from 'react';

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={144}
      height={103}
      viewBox="0 0 144 103"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M139.182 60.996c0-8.553-6.98-28.85-15.591-28.85-8.611 0-15.591 20.297-15.591 28.85a15.453 15.453 0 004.57 10.951 15.64 15.64 0 0011.021 4.535c4.133 0 8.097-1.63 11.02-4.535a15.45 15.45 0 004.571-10.951z"
        fill="#C6C6C6"
      />
      <path
        d="M139.182 60.996c0-8.553-6.98-28.85-15.591-28.85-8.611 0-15.591 20.297-15.591 28.85a15.453 15.453 0 004.57 10.951 15.64 15.64 0 0011.021 4.535c4.133 0 8.097-1.63 11.02-4.535a15.45 15.45 0 004.571-10.951z"
        fill="#fff"
        fillOpacity={0.7}
      />
      <path
        d="M123.591 95.314a.177.177 0 01-.126-.052.175.175 0 01-.053-.125V58.959c0-.047.019-.092.053-.126a.18.18 0 01.252 0 .178.178 0 01.052.126v36.178a.178.178 0 01-.178.177z"
        fill="#C6C6C6"
      />
      <path
        d="M123.591 65.718a.176.176 0 01-.135-.061l-8.967-10.279a.177.177 0 01.019-.25.179.179 0 01.251.017l8.967 10.28a.18.18 0 01.027.19.177.177 0 01-.162.104zM123.591 71.65a.179.179 0 01-.175-.212.178.178 0 01.049-.09l5.095-5.065a.179.179 0 01.304.125.178.178 0 01-.052.126l-5.095 5.064a.18.18 0 01-.126.052zM22.081 75.989c0-4.817-3.93-16.246-8.78-16.246-4.848 0-8.779 11.43-8.779 16.246a8.7 8.7 0 002.574 6.167 8.806 8.806 0 006.206 2.555c2.328 0 4.56-.919 6.206-2.555a8.7 8.7 0 002.573-6.167z"
        fill="#C6C6C6"
      />
      <path
        d="M22.081 75.989c0-4.817-3.93-16.246-8.78-16.246-4.848 0-8.779 11.43-8.779 16.246a8.7 8.7 0 002.574 6.167 8.806 8.806 0 006.206 2.555c2.328 0 4.56-.919 6.206-2.555a8.7 8.7 0 002.573-6.167z"
        fill="#fff"
        fillOpacity={0.7}
      />
      <path
        d="M13.302 95.314a.101.101 0 01-.1-.1V74.841a.1.1 0 01.1-.1.1.1 0 01.1.1v20.373a.1.1 0 01-.1.1z"
        fill="#C6C6C6"
      />
      <path
        d="M13.302 78.648a.1.1 0 01-.076-.034l-5.05-5.789a.1.1 0 01.011-.14.1.1 0 01.141.01l5.05 5.788a.1.1 0 01-.022.149.1.1 0 01-.054.016zM13.302 81.988a.1.1 0 01-.099-.119.1.1 0 01.028-.051l2.869-2.852a.101.101 0 01.142 0 .1.1 0 010 .141l-2.87 2.852a.1.1 0 01-.07.03zM3.793 95.182c0-.967.386-1.893 1.073-2.577a3.677 3.677 0 015.184 0 3.632 3.632 0 011.073 2.577"
        fill="#C6C6C6"
      />
      <path
        d="M3.793 95.182c0-.967.386-1.893 1.073-2.577a3.677 3.677 0 015.184 0 3.632 3.632 0 011.073 2.577"
        fill="#fff"
        fillOpacity={0.7}
      />
      <path
        d="M7.458 95.181a.098.098 0 01-.1-.099v-1.92a.098.098 0 01.1-.098.1.1 0 01.1.099v1.92a.098.098 0 01-.1.099z"
        fill="#C6C6C6"
      />
      <path
        d="M7.458 94.522a.099.099 0 01-.065-.024l-1.188-1.014a.099.099 0 01-.03-.106.098.098 0 01.086-.067.102.102 0 01.073.023l1.188 1.015a.098.098 0 01-.064.173zM15.086 95.182c0-.967.386-1.893 1.073-2.577a3.677 3.677 0 015.184 0 3.632 3.632 0 011.073 2.577"
        fill="#C6C6C6"
      />
      <path
        d="M15.086 95.182c0-.967.386-1.893 1.073-2.577a3.677 3.677 0 015.184 0 3.632 3.632 0 011.073 2.577"
        fill="#fff"
        fillOpacity={0.7}
      />
      <path
        d="M18.75 95.181a.098.098 0 01-.1-.099v-1.92a.098.098 0 01.1-.098.1.1 0 01.1.099v1.92a.098.098 0 01-.1.099z"
        fill="#C6C6C6"
      />
      <path
        d="M18.751 94.522a.1.1 0 01-.065-.024l-1.188-1.014a.098.098 0 01.057-.173.1.1 0 01.072.023l1.189 1.015a.099.099 0 01.028.109.099.099 0 01-.093.064zM83.054 80.705c-22.384 0-40.595-18.102-40.595-40.352C42.459 18.103 60.669 0 83.054 0c22.385 0 40.596 18.102 40.596 40.353 0 22.25-18.211 40.352-40.596 40.352zm0-80.163c-22.084 0-40.05 17.859-40.05 39.81 0 21.953 17.966 39.812 40.05 39.812 22.084 0 40.051-17.86 40.051-39.811 0-21.952-17.967-39.811-40.05-39.811z"
        fill="#C6C6C6"
      />
      <path
        d="M83.054 37.374a.273.273 0 01-.272-.271V.27A.27.27 0 0183.054 0a.273.273 0 01.273.27v36.833a.27.27 0 01-.273.27zM123.378 40.623H86.324a.273.273 0 01-.273-.27.27.27 0 01.273-.271h37.054a.272.272 0 01.272.27.27.27 0 01-.272.271zM79.785 40.623H42.749a.273.273 0 01-.273-.27.27.27 0 01.273-.271h37.036a.273.273 0 01.272.27.27.27 0 01-.272.271zM83.054 80.705a.273.273 0 01-.272-.27V43.603a.27.27 0 01.272-.271.273.273 0 01.273.27v36.833a.27.27 0 01-.273.27zM85.457 38.423a.273.273 0 01-.252-.167.27.27 0 01.059-.295l26.086-25.93a.267.267 0 01.192-.08.272.272 0 01.193.463l-26.086 25.93a.272.272 0 01-.192.079zM80.652 38.423a.272.272 0 01-.192-.08l-26.086-25.93a.27.27 0 01.192-.461c.073 0 .142.028.193.079l26.086 25.93a.27.27 0 01-.193.462zM111.542 68.754a.27.27 0 01-.192-.08l-26.086-25.93a.27.27 0 01.193-.461c.072 0 .141.028.192.079l26.086 25.93a.27.27 0 01-.193.462zM54.566 68.754a.274.274 0 01-.251-.167.27.27 0 01.059-.296l26.086-25.93a.273.273 0 01.444.296.27.27 0 01-.06.088L54.76 68.675a.271.271 0 01-.193.079z"
        fill="#C6C6C6"
      />
      <path
        d="M83.054 68.112a28.047 28.047 0 01-15.515-4.678 27.796 27.796 0 01-10.285-12.458 27.604 27.604 0 01-1.59-16.039 27.708 27.708 0 017.643-14.213 27.979 27.979 0 0114.3-7.597 28.083 28.083 0 0116.134 1.58 27.888 27.888 0 0112.533 10.223 27.639 27.639 0 014.707 15.423 27.711 27.711 0 01-8.189 19.62 28.044 28.044 0 01-19.738 8.14zm0-54.977a27.5 27.5 0 00-15.212 4.587 27.254 27.254 0 00-10.085 12.215 27.067 27.067 0 00-1.558 15.726 27.168 27.168 0 007.494 13.936 27.433 27.433 0 0014.02 7.448 27.535 27.535 0 0015.82-1.548 27.342 27.342 0 0012.288-10.025 27.1 27.1 0 004.615-15.121 27.167 27.167 0 00-8.029-19.237 27.497 27.497 0 00-19.353-7.981z"
        fill="#C6C6C6"
      />
      <path
        d="M83.054 43.602a3.26 3.26 0 003.27-3.25 3.26 3.26 0 00-3.27-3.25 3.26 3.26 0 00-3.27 3.25 3.26 3.26 0 003.27 3.25z"
        fill="#00264B"
      />
      <path d="M70.397 95.247l-2.872.083 14.02-56.249 2.605.79-13.753 55.376z" fill="#00264B" />
      <path d="M95.712 95.247l2.873.083-14.02-56.249-2.606.79 13.753 55.376z" fill="#00264B" />
      <path d="M87.413 8.396h-8.99v4.874h8.99V8.396z" fill="#C6C6C6" />
      <path
        d="M82.51 9.479h-3.543v3.791h3.542V9.48zM86.868 9.479h-3.541v3.791h3.541V9.48z"
        fill="#fff"
      />
      <path
        d="M82.918 18.551a5.199 5.199 0 01-3.659-1.508 5.136 5.136 0 01-1.518-3.637v-.136c0-.323.13-.633.36-.861.23-.229.541-.357.866-.357h7.901c.326 0 .637.129.867.357.23.228.36.538.36.861v.136a5.136 5.136 0 01-1.518 3.637 5.198 5.198 0 01-3.659 1.508z"
        fill="#C6C6C6"
      />
      <path
        d="M87.077 9.614h-8.318a.684.684 0 01-.579-.32.674.674 0 01-.03-.66l1.635-3.25a.675.675 0 01.61-.374h5.047a.68.68 0 01.61.374l1.634 3.25a.674.674 0 01-.277.895.684.684 0 01-.332.085z"
        fill="#09F"
      />
      <path d="M111.662 19.228h-8.991v4.875h8.991v-4.875z" fill="#C6C6C6" />
      <path
        d="M106.758 20.312h-3.542v3.791h3.542v-3.791zM111.117 20.312h-3.542v3.791h3.542v-3.791z"
        fill="#fff"
      />
      <path
        d="M107.166 29.384a5.197 5.197 0 01-3.658-1.509 5.131 5.131 0 01-1.518-3.636v-.136c0-.323.129-.633.359-.861.23-.229.542-.357.867-.358h7.901c.325 0 .637.13.867.358.229.228.359.538.359.861v.136a5.135 5.135 0 01-1.518 3.636 5.197 5.197 0 01-3.659 1.51z"
        fill="#C6C6C6"
      />
      <path
        d="M104.643 16.114a.407.407 0 00-.366.225l-1.635 3.25a.407.407 0 00.366.587h8.317a.407.407 0 00.366-.588l-1.635-3.25a.409.409 0 00-.365-.224h-5.048z"
        fill="#E6007E"
      />
      <path d="M121.198 43.602h-8.991v4.875h8.991v-4.874z" fill="#C6C6C6" />
      <path
        d="M116.294 44.686h-3.542v3.791h3.542v-3.791zM120.653 44.686h-3.542v3.791h3.542v-3.791z"
        fill="#fff"
      />
      <path
        d="M116.702 53.758a5.196 5.196 0 01-3.658-1.508 5.132 5.132 0 01-1.518-3.637v-.136c0-.323.129-.633.359-.861.23-.228.542-.357.867-.357h7.901a1.229 1.229 0 011.226 1.218v.136a5.14 5.14 0 01-1.518 3.637 5.197 5.197 0 01-3.659 1.508z"
        fill="#C6C6C6"
      />
      <path
        d="M120.861 44.821h-8.317a.685.685 0 01-.58-.32.673.673 0 01-.03-.66l1.635-3.25a.672.672 0 01.609-.374h5.048a.682.682 0 01.61.375l1.634 3.25a.676.676 0 01-.609.98z"
        fill="#09F"
      />
      <path d="M111.117 66.352h-8.991v4.874h8.991v-4.874z" fill="#C6C6C6" />
      <path
        d="M106.213 67.435h-3.542v3.792h3.542v-3.792zM110.572 67.435h-3.542v3.792h3.542v-3.792z"
        fill="#fff"
      />
      <path
        d="M106.621 76.508a5.197 5.197 0 01-3.658-1.51 5.131 5.131 0 01-1.518-3.636v-.135c0-.324.129-.633.359-.862.23-.228.542-.357.867-.357h7.901c.325 0 .637.129.867.357.229.229.359.538.359.862v.135a5.135 5.135 0 01-1.518 3.637 5.197 5.197 0 01-3.659 1.509z"
        fill="#C6C6C6"
      />
      <path
        d="M110.78 67.57h-8.317a.688.688 0 01-.58-.32.676.676 0 01-.029-.659l1.634-3.25a.686.686 0 01.61-.375h5.048a.682.682 0 01.609.374l1.635 3.25a.672.672 0 01-.278.894.685.685 0 01-.332.086z"
        fill="#E6007E"
      />
      <path d="M54.446 24.103h8.991v-4.875h-8.99v4.875z" fill="#C6C6C6" />
      <path
        d="M59.35 24.103h3.543v-3.791H59.35v3.791zM54.991 24.103h3.542v-3.791h-3.542v3.791z"
        fill="#fff"
      />
      <path
        d="M58.942 29.384a5.198 5.198 0 01-3.659-1.509 5.135 5.135 0 01-1.518-3.636v-.136c0-.323.13-.633.36-.861.23-.229.541-.357.866-.358h7.901c.325 0 .637.13.867.358.23.228.36.538.36.861v.136a5.135 5.135 0 01-1.518 3.636 5.199 5.199 0 01-3.66 1.51z"
        fill="#C6C6C6"
      />
      <path
        d="M63.1 20.447h-8.317a.683.683 0 01-.58-.321.673.673 0 01-.029-.659l1.635-3.25a.675.675 0 01.61-.374h5.047a.68.68 0 01.61.374l1.634 3.25a.673.673 0 01-.278.895.683.683 0 01-.331.085z"
        fill="#E6007E"
      />
      <path d="M44.91 48.477h8.992v-4.874H44.91v4.874z" fill="#C6C6C6" />
      <path
        d="M49.815 48.477h3.542v-3.791h-3.542v3.791zM45.455 48.477h3.542v-3.791h-3.542v3.791z"
        fill="#fff"
      />
      <path
        d="M49.406 53.758a5.198 5.198 0 01-3.659-1.508 5.136 5.136 0 01-1.518-3.637v-.136c0-.323.13-.633.36-.861.23-.228.541-.357.866-.357h7.902c.325 0 .636.129.866.357.23.228.36.538.36.861v.136a5.136 5.136 0 01-1.518 3.637 5.198 5.198 0 01-3.659 1.508z"
        fill="#C6C6C6"
      />
      <path
        d="M53.565 44.821h-8.317a.685.685 0 01-.58-.32.675.675 0 01-.03-.66l1.635-3.25a.676.676 0 01.61-.374h5.047a.68.68 0 01.61.375l1.634 3.25a.673.673 0 01-.277.894.684.684 0 01-.332.085z"
        fill="#09F"
      />
      <path d="M54.991 71.227h8.991v-4.875h-8.99v4.875z" fill="#C6C6C6" />
      <path
        d="M59.895 71.227h3.542v-3.792h-3.541v3.792zM55.536 71.227h3.542v-3.792h-3.542v3.792z"
        fill="#fff"
      />
      <path
        d="M59.487 76.508a5.199 5.199 0 01-3.659-1.51 5.136 5.136 0 01-1.518-3.636v-.135c0-.324.13-.633.36-.862.23-.228.541-.357.866-.357h7.901c.326 0 .637.129.867.357.23.229.36.538.36.862v.135a5.136 5.136 0 01-1.518 3.637 5.198 5.198 0 01-3.66 1.509z"
        fill="#C6C6C6"
      />
      <path
        d="M63.646 67.57h-8.318a.684.684 0 01-.58-.32.674.674 0 01-.029-.66l1.635-3.25a.676.676 0 01.609-.374h5.048a.681.681 0 01.61.374l1.634 3.25a.673.673 0 01-.278.895.684.684 0 01-.331.085z"
        fill="#E6007E"
      />
      <path d="M87.413 76.643h-8.99v4.875h8.99v-4.875z" fill="#C6C6C6" />
      <path
        d="M82.51 77.726h-3.543v3.792h3.542v-3.792zM86.868 77.726h-3.541v3.792h3.541v-3.792z"
        fill="#fff"
      />
      <path
        d="M78.967 80.57c-.253 0-.495.1-.674.278a.946.946 0 00-.28.67v.135A4.86 4.86 0 0079.45 85.1a4.92 4.92 0 003.468 1.428 4.92 4.92 0 003.468-1.428 4.86 4.86 0 001.436-3.447v-.135c0-.252-.1-.492-.28-.67a.958.958 0 00-.674-.278h-7.9z"
        fill="#C6C6C6"
      />
      <path
        d="M80.394 73.529a.408.408 0 00-.365.224l-1.635 3.25a.404.404 0 00.166.537.41.41 0 00.2.05h8.317a.411.411 0 00.348-.192.405.405 0 00.017-.395l-1.634-3.25a.405.405 0 00-.366-.224h-5.048z"
        fill="#09F"
      />
      <path
        d="M51.507 87.205a.273.273 0 00.272-.27.27.27 0 00-.273-.272h-2.588V85.58h2.589a.273.273 0 00.272-.27.27.27 0 00-.273-.271H29.439a.273.273 0 00-.273.27.27.27 0 00.273.271h2.588v1.084h-2.588a.273.273 0 00-.273.27.27.27 0 00.273.271h2.588v1.084h-2.588a.273.273 0 00-.273.27.27.27 0 00.273.271h2.588v1.083H29.71a.547.547 0 00-.545.542.54.54 0 00.545.542h2.205a.404.404 0 00-.025.135v3.792a.405.405 0 00.408.406.41.41 0 00.41-.406v-3.792a.402.402 0 00-.026-.135h15.58a.402.402 0 00-.025.135v3.792a.405.405 0 00.409.406.41.41 0 00.408-.406v-3.792a.4.4 0 00-.025-.135h2.205a.547.547 0 00.545-.542.54.54 0 00-.545-.542h-2.316V88.83h2.589a.273.273 0 00.272-.27.27.27 0 00-.273-.272h-2.588v-1.083h2.589zM32.57 85.58h15.802v1.084H32.571V85.58zm15.802 4.333H32.571V88.83h15.802v1.083zm0-1.624H32.571v-1.084h15.802v1.084z"
        fill="#00264B"
      />
      <path
        d="M35.277 85.716h-.817a.41.41 0 01-.409-.407v-.812a.405.405 0 01.409-.406h.817a.41.41 0 01.409.406v.812a.406.406 0 01-.409.407z"
        fill="#C6C6C6"
      />
      <path
        d="M34.884 84.82a.27.27 0 01-.146-.042.27.27 0 01-.123-.275l2.694-15.584a.27.27 0 01.315-.22.273.273 0 01.222.311l-2.694 15.585a.27.27 0 01-.268.225z"
        fill="#C6C6C6"
      />
      <path
        d="M34.883 84.82a.274.274 0 01-.268-.225L31.92 69.01a.27.27 0 01.223-.312.274.274 0 01.315.22l2.694 15.586a.268.268 0 01-.123.274.273.273 0 01-.146.042z"
        fill="#C6C6C6"
      />
      <path
        d="M40.684 66.251c.263-1.85-.731-3.518-2.22-3.727-1.488-.209-2.908 1.121-3.17 2.97-.222 1.56.452 2.988 1.56 3.523l-.062.445 1.347.19.063-.445c1.215-.21 2.26-1.396 2.482-2.956z"
        fill="#E6007E"
      />
      <path
        d="M34.426 65.41c-.322-1.84-1.784-3.125-3.265-2.868-1.48.256-2.42 1.956-2.098 3.796.272 1.552 1.355 2.704 2.576 2.874l.078.443 1.34-.232-.077-.443c1.091-.57 1.719-2.019 1.447-3.57zM34.868 84.497a.273.273 0 01-.272-.271V68.205a.27.27 0 01.272-.271.273.273 0 01.273.27v16.022a.27.27 0 01-.273.27z"
        fill="#C6C6C6"
      />
      <path
        d="M37.421 65.127c0-1.867-1.218-3.382-2.722-3.382-1.503 0-2.722 1.515-2.722 3.382 0 1.576.868 2.896 2.042 3.272v.449h1.36v-.45c1.174-.375 2.042-1.695 2.042-3.27z"
        fill="#09F"
      />
      <path
        d="M143.759 96.101H.241c-.064 0-.125-.052-.17-.146A.842.842 0 010 95.6a.84.84 0 01.07-.353c.046-.094.107-.147.17-.147H143.76c.064 0 .125.053.17.147.046.093.071.22.071.353a.83.83 0 01-.071.354c-.045.094-.106.146-.17.146z"
        fill="#C6C6C6"
      />
      <path
        d="M115.372 94.157a.515.515 0 00-.043-.438.524.524 0 00-.365-.249l-.314-1.822-.705.657.406 1.63a.524.524 0 00.78.508.526.526 0 00.241-.286z"
        fill="#FFD4C7"
      />
      <path
        d="M114.903 93.285l-.693.181a.13.13 0 01-.097-.013.127.127 0 01-.061-.077l-1.245-4.383-.376-1.118a.667.667 0 01.118-.64.676.676 0 01.606-.243.677.677 0 01.569.478l.994 3.341.281 2.334a.131.131 0 01-.068.13.13.13 0 01-.028.01z"
        fill="#C6C6C6"
      />
      <path
        d="M112.371 86.253c.706 0 1.279-.569 1.279-1.27 0-.702-.573-1.271-1.279-1.271s-1.278.569-1.278 1.27c0 .702.572 1.271 1.278 1.271zM108.608 102.365l-.612-.181.411-2.431.903.267-.702 2.345z"
        fill="#FFD4C7"
      />
      <path
        d="M108.588 103l-1.972-.583.007-.025a.801.801 0 01.996-.536l1.204.356-.235.788z"
        fill="#00264B"
      />
      <path d="M115.308 102.334l-.638-.001-.303-2.446h.629l.312 2.447z" fill="#FFD4C7" />
      <path
        d="M115.471 102.948h-2.058v-.026a.793.793 0 01.495-.735.803.803 0 01.306-.061l1.257.001v.821zM108.843 101.645h-.64a.228.228 0 01-.1-.022.228.228 0 01-.127-.151.23.23 0 01-.004-.101c-.408-.181 1.791-10.667 2.311-10.464l.017-.024 2.617.212c1.173 2.332 1.915 6.191 2.609 10.22a.225.225 0 01-.039.195.22.22 0 01-.078.067.225.225 0 01-.099.027l-.719.035a.236.236 0 01-.139-.036.236.236 0 01-.09-.11l-2.758-6.853a.08.08 0 00-.029-.037.073.073 0 00-.045-.012.075.075 0 00-.072.052l-2.394 6.846a.234.234 0 01-.221.156z"
        fill="#00264B"
      />
      <path
        d="M112.972 91.3l-.02-.001-2.786-.21.489-3.09c.053-.344.218-.663.468-.907.251-.245.574-.402.922-.449l.065-.008a1.617 1.617 0 011.462.581 1.607 1.607 0 01.278 1.56l-.878 2.524z"
        fill="#C6C6C6"
      />
      <path
        d="M114.854 86.436c-.879.4-2.072.328-3.435 0 .188-.514.335-1.01.169-1.374a1.089 1.089 0 01-.037-.888 1.712 1.712 0 011.585-1.049c.456 0 .893.18 1.215.5.322.32.503.754.503 1.207v1.604z"
        fill="#00264B"
      />
      <path
        d="M112.034 83.241h.946v1.336h-2.29a1.339 1.339 0 01.394-.945 1.346 1.346 0 01.95-.391z"
        fill="#00264B"
      />
      <path
        d="M110.105 94.445a.51.51 0 00.169-.407.513.513 0 00-.205-.39l.582-1.756-.933.251-.409 1.63a.517.517 0 00.097.687.531.531 0 00.699-.015z"
        fill="#FFD4C7"
      />
      <path
        d="M110.102 93.456l-.697-.163a.13.13 0 01-.097-.153l.967-4.452.195-1.163a.673.673 0 01.405-.51.68.68 0 01.892.37c.05.122.062.256.035.385l-.696 3.415-.853 2.192a.129.129 0 01-.151.08z"
        fill="#C6C6C6"
      />
      <path d="M56.58 102.31h.6l.285-2.302h-.886v2.302z" fill="#FFB8B8" />
      <path d="M56.314 103v-.995l1.294-.001a.87.87 0 01.866.86V103h-2.16z" fill="#4A4A4A" />
      <path d="M53.343 102.31h.6l.286-2.302h-.886v2.302z" fill="#FFB8B8" />
      <path d="M53.078 103v-.995l1.294-.001a.87.87 0 01.865.86V103h-2.159z" fill="#4A4A4A" />
      <path
        d="M55.101 85.946c.713 0 1.29-.574 1.29-1.282 0-.709-.577-1.283-1.29-1.283-.712 0-1.29.574-1.29 1.282 0 .709.578 1.283 1.29 1.283zM59.512 93.025a.487.487 0 00-.422-.423.494.494 0 00-.213.017l-.721-.859-.632.308 1.035 1.2a.493.493 0 00.585.3.495.495 0 00.368-.543zM53.308 93.894a.486.486 0 00-.317-.681l-.145-1.11-.7-.077.227 1.564a.49.49 0 00.332.565.497.497 0 00.603-.26z"
        fill="#FFD4C7"
      />
      <path
        d="M54.023 87.085a.634.634 0 00-.892-.109.632.632 0 00-.198.262l-1.057 2.623a1.62 1.62 0 00-.1.845l.345 2.305a.209.209 0 00.27.17l.453-.139a.21.21 0 00.147-.224 7.545 7.545 0 01.612-3.98l.499-1.108a.628.628 0 00-.079-.645z"
        fill="#C6C6C6"
      />
      <path
        d="M54.787 85.946a1.385 1.385 0 01-1.038-.437 1.366 1.366 0 01-.189-1.606 1.37 1.37 0 01.909-.663c.19-.04.386-.04.576 0 .28-.183.61-.273.944-.257a.928.928 0 01.78.53.652.652 0 01-.316.834 1.201 1.201 0 01-.63.01.944.944 0 00-.622.054c-.183.108-.265.397-.102.532a.56.56 0 01-.023.873.618.618 0 01-.289.13z"
        fill="#00264B"
      />
      <path
        d="M53.187 92.975l-.23 8.631a.206.206 0 00.064.155.21.21 0 00.157.059l.754-.038a.208.208 0 00.197-.176L55.026 96a.105.105 0 01.206-.006l1.214 5.397a.21.21 0 00.275.152l.748-.046a.209.209 0 00.14-.211l-.654-8.393-3.768.082z"
        fill="#4A4A4A"
      />
      <path
        d="M52.82 88.875a2.34 2.34 0 01.534-1.743 2.365 2.365 0 011.625-.841c.569-.04 1.45-.054 1.741.686.876 2.23-.657 2.175-.129 4.107.529 1.932.995 2.043.27 2.182-.725.14-3.984.963-3.85-.396.082-.836-.066-2.69-.192-3.995z"
        fill="#C6C6C6"
      />
      <path
        d="M56.566 86.75a.638.638 0 00-.822.362.627.627 0 00-.034.326l.411 2.557c.07.438.257.849.54 1.19l1.337 1.62a.211.211 0 00.318.008l.318-.35a.208.208 0 00.01-.268l-.093-.12a7.01 7.01 0 01-1.42-3.456l-.164-1.356a.629.629 0 00-.401-.513z"
        fill="#C6C6C6"
      />
      <path
        d="M59.573 93.62a.506.506 0 00.27-.551.5.5 0 00-.089-.199l.379-3.676h-.818l-.3 3.59a.505.505 0 00.199.873c.12.032.248.019.36-.037zM60.324 87.165c.639 0 1.157-.515 1.157-1.15 0-.635-.518-1.15-1.157-1.15s-1.157.516-1.157 1.15c0 .635.518 1.15 1.157 1.15z"
        fill="#A0616A"
      />
      <path
        d="M60.542 91.335a.212.212 0 01-.204-.155 11.589 11.589 0 00-.717-1.681 1.387 1.387 0 01.796-1.924 1.407 1.407 0 011.528.405c.122.14.215.305.272.482a6.85 6.85 0 01.263 2.298.21.21 0 01-.167.195l-1.726.375a.211.211 0 01-.045.005z"
        fill="#E6007E"
      />
      <path d="M62.213 102.251h.578l.274-2.213h-.852v2.213z" fill="#A0616A" />
      <path d="M64.04 102.919h-2.087v-.967h1.25a.837.837 0 01.837.832v.135z" fill="#00264B" />
      <path d="M56.44 101.508l.507.276 1.313-1.808-.746-.408-1.073 1.94z" fill="#A0616A" />
      <path
        d="M57.718 102.968l-1.829-.999.469-.848 1.095.598a.832.832 0 01.33 1.13l-.065.119zM62.186 101.023a.212.212 0 01-.21-.184l-.722-5.53a.117.117 0 00-.167-.09.118.118 0 00-.053.049l-3.008 5.426a.217.217 0 01-.113.096.216.216 0 01-.148-.001l-.656-.248a.206.206 0 01-.071-.045.212.212 0 01-.05-.231l2.084-5.09a.161.161 0 00.01-.04c.283-2.172 1.002-3.277 1.215-3.564a.163.163 0 00.023-.148l-.03-.088a.21.21 0 01.042-.204c.769-.871 2.109-.396 2.123-.391l.006.002.004.006c1.503 2.049.948 8.703.821 10.017a.21.21 0 01-.195.189l-.888.068-.017.001z"
        fill="#00264B"
      />
      <path
        d="M64.82 87.35a.486.486 0 00-.011.078l-2.023 1.16-.492-.282-.524.682.822.582a.378.378 0 00.452-.01l2.086-1.636a.498.498 0 00.544-.138.49.49 0 00-.437-.81.496.496 0 00-.417.375z"
        fill="#A0616A"
      />
      <path
        d="M62.718 88.642l-.653.843a.213.213 0 01-.32.017l-.74-.764a.584.584 0 01.287-1.041c.155-.02.31.022.434.117l.928.516a.213.213 0 01.104.142.21.21 0 01-.04.17z"
        fill="#E6007E"
      />
      <path
        d="M60.185 90.106l-1.054-.19a.213.213 0 01-.173-.185c-.003-.03 0-.06.01-.089l.342-1.005a.585.585 0 01.684-.468.589.589 0 01.473.68l-.033 1.056a.21.21 0 01-.16.198.213.213 0 01-.09.003z"
        fill="#6C63FF"
      />
      <path
        d="M60.507 85.751c.297.202.687.41.991.195a.55.55 0 00.181-.633c-.145-.413-.544-.595-.926-.735a2.676 2.676 0 00-1.55-.196c-.513.13-.966.624-.873 1.141.074.417.47.763.413 1.182-.056.422-.533.644-.955.72-.421.075-.893.099-1.195.4-.387.383-.29 1.07.073 1.475.397.378.9.627 1.444.713.704.166 1.465.253 2.134-.022.67-.274 1.19-1.013.996-1.706a2.376 2.376 0 00-.461-.781 2.173 2.173 0 01-.446-.788.815.815 0 01.149-.717.208.208 0 00.028-.243l-.003-.005z"
        fill="#00264B"
      />
    </svg>
  );
}

export default SvgComponent;
