/**
 * Recommendations scene
 *
 * @author Miguel Antunes <mantunes@ubiwhere.com>
 *
 *
 */
import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useDispatch /*useSelector*/ } from 'react-redux';

import { actions } from 'store/rootSlices';
import theme from 'ui/theme';
import Back from 'assets/icons/Back';
import { Button as StyledButton, Heading2, Subtitle as StyledSubtitle } from 'shared/components/ui';
import { Recommendations as RecommendationsIllustration } from 'assets/illustrations';
import RecommendationCard from 'shared/components/RecommendationCard';

//import config from 'config'

const recommendations = [
  {
    title: 'Festival de teatro ambiental',
    status: 'pending',
    date: '2021-04-23T18:00:00.000Z',
    id: '1',
  },
  {
    title: 'Feira do livro',
    status: 'accepted',
    date: '2021-03-29T13:00:00.000Z',
    id: '2',
  },
];

const Recommendations = ({ t, i18n }) => {
  //const { } = useSelector(state => state.Recommendations)

  const { onMount, onUnmount } = actions.Recommendations;
  const { navigateTo, navigateBack } = actions.App;
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(onMount());
    return () => {
      dispatch(onUnmount());
    };
  }, [dispatch, onMount, onUnmount]);

  return (
    <Wrapper>
      <Header>
        <Back
          onClick={() => {
            dispatch(navigateBack());
          }}
          fill={theme.colors.primary}
        />
        <Title>{t('recommendations.title')}</Title>
        <Subtitle>{t('recommendations.my')}</Subtitle>
      </Header>
      <WrapperCenter>
        {recommendations ? (
          <WrapperList>
            {recommendations.map((element) => (
              <RecommendationCard
                key={`recommendation_card_${element.id}`}
                recommendation={element}
              />
            ))}
          </WrapperList>
        ) : (
          <>
            <Text>{t('recommendations.empty')}</Text>

            <Illustration />
          </>
        )}
        <Button
          onClick={() => {
            dispatch(navigateTo(`recommendations/new`));
          }}
        >
          {' '}
          {t('recommendations.create')}{' '}
        </Button>
      </WrapperCenter>
    </Wrapper>
  );
};

export default Recommendations;

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding-bottom: 9.375rem;
`;

const WrapperCenter = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 1rem 0;
`;

const WrapperList = styled.div`
  margin-bottom: 3.625rem;
  width: 100%;
  > * {
    margin-bottom: 1.5rem;
    :last-child {
      margin-bottom: 0;
    }
  }
`;

const Header = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 2rem 1rem 1.5rem;
`;

const Title = styled(Heading2)`
  margin: 2rem 0 1rem;
`;

const Subtitle = styled(StyledSubtitle)`
  color: ${({ theme }) => theme.colors.highlight};
`;

const Text = styled.p`
  font-weight: 300;
  font-size: ${({ theme }) => theme.fontSizes.medium};
  line-height: ${({ theme }) => theme.lineHeights.extraLarge};
  color: ${({ theme }) => theme.colors.primary};
`;

const Illustration = styled(RecommendationsIllustration)`
  margin: 3.25rem 0;
`;

const Button = styled(StyledButton)`
  max-width: 15.625rem;
`;
