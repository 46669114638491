/**
 * Badge component
 *
 * @author Miguel Antunes <mantunes@ubiwhere.com>
 *
 *
 */

import React from 'react';
import styled from 'styled-components';
import moment from 'moment';

const Date = ({ date }) => {
  const sameYear = moment().year === moment(date).year;
  const newDate = moment(date);
  return (
    <Wrapper>
      {sameYear
        ? moment.locale() === 'pt'
          ? newDate.format('DD [de] MMMM [|] HH:mm')
          : newDate.format('MMMM Do [|] HH:mm')
        : newDate.format('LL [|] HH:mm')}
    </Wrapper>
  );
};

export default Date;

const Wrapper = styled.label`
  font-size: ${({ theme }) => theme.fontSizes.small};
  line-height: ${({ theme }) => theme.lineHeights.medium};
  letter-spacing: 0.013rem;
  color: ${({ theme }) => theme.colors.darkGray};
`;
