import * as React from 'react';

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={144}
      height={103}
      viewBox="0 0 144 103"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M107.041 52.743c0-10.945-8.89-36.936-19.856-36.936-10.967 0-19.84 25.975-19.84 36.936.017 10.96 8.906 19.83 19.873 19.83 10.933-.017 19.823-8.886 19.823-19.83z"
        fill="#C6C6C6"
      />
      <path
        d="M107.041 52.743c0-10.945-8.89-36.936-19.856-36.936-10.967 0-19.84 25.975-19.84 36.936.017 10.96 8.906 19.83 19.873 19.83 10.933-.017 19.823-8.886 19.823-19.83z"
        fill="#fff"
        fillOpacity={0.7}
      />
      <path
        d="M87.201 96.67a.227.227 0 01-.232-.233V50.136c0-.133.1-.232.232-.232.133 0 .233.1.233.232v46.303c-.017.132-.116.232-.233.232z"
        fill="#C6C6C6"
      />
      <path
        d="M87.201 58.788a.205.205 0 01-.166-.083L75.603 45.552a.23.23 0 01.017-.316.23.23 0 01.316.017L87.35 58.406a.23.23 0 01-.017.316c-.033.033-.083.066-.133.066zM87.201 66.378a.227.227 0 01-.232-.233c0-.066.016-.116.066-.166l6.48-6.477a.231.231 0 01.316 0 .23.23 0 010 .316l-6.48 6.477a.198.198 0 01-.15.083zM31.654 64.219c0-7.673-6.231-25.892-13.908-25.892S3.838 56.546 3.838 64.22c0 7.672 6.248 13.9 13.925 13.9 7.676-.016 13.89-6.228 13.89-13.9z"
        fill="#C6C6C6"
      />
      <path
        d="M31.654 64.219c0-7.673-6.231-25.892-13.908-25.892S3.838 56.546 3.838 64.22c0 7.672 6.248 13.9 13.925 13.9 7.676-.016 13.89-6.228 13.89-13.9z"
        fill="#fff"
        fillOpacity={0.7}
      />
      <path
        d="M17.746 95.01a.166.166 0 01-.166-.167V62.392a.166.166 0 01.332 0V94.86c0 .083-.083.15-.166.15z"
        fill="#C6C6C6"
      />
      <path
        d="M17.746 68.454a.151.151 0 01-.116-.05l-8.01-9.217a.178.178 0 01.017-.233c.067-.05.167-.05.216.017l8.01 9.217c.05.066.05.166-.017.232-.017.034-.067.034-.1.034zM17.746 73.785a.167.167 0 01-.166-.166.15.15 0 01.05-.117l4.553-4.55a.16.16 0 01.232 0 .16.16 0 010 .232l-4.553 4.55a.15.15 0 01-.116.05z"
        fill="#C6C6C6"
      />
      <path
        d="M30.64 86.14c-.93 0-1.695.765-1.695 1.695v3.969c0 .93.765 1.694 1.695 1.694h2.144c.93 0 1.694-.764 1.694-1.694v-3.97c0-.93-.764-1.693-1.694-1.693H30.64zM70.42 93.63h-2.144a1.822 1.822 0 01-1.828-1.826v-3.97c0-1.012.814-1.826 1.828-1.826h2.143c1.014 0 1.828.814 1.828 1.827v3.969c0 1.013-.83 1.827-1.828 1.827z"
        fill="#3F3D56"
      />
      <path
        d="M68.99 90.459H31.936c-1.678 0-3.057-.997-3.057-2.21v-7.124l1.844-1.943H70.57l1.479 1.943v7.125c0 1.212-1.38 2.209-3.058 2.209z"
        fill="#C6C6C6"
      />
      <path
        d="M70.386 82.753H30.923l-.4-6.145.25-.631 1.479-3.654h36.539l2.01 4.135-.415 6.295z"
        fill="#C6C6C6"
      />
      <path
        d="M70.718 76.475l-.282 4.617h-8.69v-3.421l8.74-1.71.232.514zM39.43 77.67v3.422h-8.54l-.3-4.484.25-.615 8.59 1.678z"
        fill="#fff"
      />
      <path
        d="M68.558 73.37H32.485l3.855-12.822a2.567 2.567 0 012.459-1.843l23.894-.233a2.562 2.562 0 012.525 1.944l3.34 12.954z"
        fill="#C6C6C6"
      />
      <path
        d="M65.783 72.157l-30.54-.365a.256.256 0 01-.25-.25c0-.016 0-.05.017-.066l2.925-9.134a.25.25 0 01.232-.166h24.559c.1 0 .2.066.233.166l3.04 9.483c.05.133-.033.266-.166.316 0 .016-.016.016-.05.016z"
        fill="#fff"
      />
      <path
        d="M54.634 60.05h-8.225c-.066 0-.133-.066-.166-.183a.506.506 0 01.017-.348l.68-1.711c.034-.083.084-.133.15-.133h6.88c.066 0 .116.05.149.133l.681 1.71c.033.117.05.233.017.35-.05.116-.1.182-.183.182z"
        fill="#09F"
      />
      <path
        d="M32.501 73.12h-5.416v-1.195c0-.798.648-1.445 1.445-1.445h3.955v2.64h.016zM73.958 73.12h-5.416v-2.64h3.954c.798 0 1.446.647 1.446 1.445v1.195h.016zM26.47 91.65L.009 101.144l.112.312 26.46-9.494-.111-.313zM74.421 91.654l-.112.313 26.459 9.497.112-.312-26.459-9.498zM51.992 98.53h-1.579v-2.043h1.579v2.043zm0-4.085h-1.579v-2.043h1.579v2.043z"
        fill="#C6C6C6"
      />
      <path
        d="M51.992 98.53h-1.579v-2.043h1.579v2.043zm0-4.085h-1.579v-2.043h1.579v2.043z"
        fill="#fff"
        fillOpacity={0.7}
      />
      <path d="M51.992 102.915h-1.579v-2.043h1.579v2.043z" fill="#C6C6C6" />
      <path d="M51.992 102.915h-1.579v-2.043h1.579v2.043z" fill="#fff" fillOpacity={0.7} />
      <path
        d="M50.48 51.713c4.34 0 7.86-3.517 7.86-7.855a7.857 7.857 0 00-7.86-7.856 7.857 7.857 0 00-7.86 7.856 7.857 7.857 0 007.86 7.855z"
        fill="#09F"
      />
      <path
        d="M49.732 47.18l-2.36-3.023 1.38-1.063 1.113 1.428 3.755-3.97 1.263 1.196-5.151 5.431z"
        fill="#fff"
      />
      <path d="M116.949 99.61h-1.745l-.831-6.727h2.576v6.727z" fill="#FFD4C7" />
      <path
        d="M117.397 101.304h-5.633v-.067c0-1.212.981-2.192 2.194-2.192h3.439v2.259z"
        fill="#00264B"
      />
      <path d="M139.447 96.305l-1.495.896-4.171-5.33 2.21-1.33 3.456 5.764z" fill="#FFD4C7" />
      <path
        d="M135.875 100.423l-.034-.066a2.208 2.208 0 01.748-3.006l2.941-1.76 1.163 1.943-4.818 2.889zM117.414 98.231h-.033c-.848 0-1.695-.05-2.542-.15a38.323 38.323 0 00-1.097-.099h-.017c-.282-.083-.299-2.773-.166-11.974.067-4.717.133-10.048.05-13.452v-.034l.033-.016c3.107-2.707 7.112-3.72 11.249-2.857h.034l.016.034c1.712 2.906 4.304 7.074 6.813 11.11 5.749 9.267 7.394 11.99 7.211 12.207l-.016.016c-.399.2-.682.35-.931.482-.831.432-1.18.614-3.057 1.378l-.05.017-.033-.033c-5.833-6.394-10.685-13.519-13.692-20.112a927.165 927.165 0 00-1.23 8.304c-1.296 8.935-2.16 14.814-2.525 15.18h-.017zM126.47 44.04c.581-2.425 1.08-5.165-.017-7.39-.764-1.545-3.606-2.076-5.234-1.511-1.628.564-2.808 2.075-3.323 3.72-.515 1.644-.465 3.42-.233 5.148 2.975-.05 5.982-.116 8.807.033z"
        fill="#00264B"
      />
      <path
        d="M121.402 44.04a3.488 3.488 0 10.001-6.976 3.488 3.488 0 00-.001 6.976z"
        fill="#FFD4C7"
      />
      <path
        d="M125.672 39.822a4.381 4.381 0 00-4.303-4.468 4.382 4.382 0 00-4.47 4.302v.282c2.991.017 5.982.017 8.773-.116z"
        fill="#00264B"
      />
      <path
        d="M118.976 73.253a30.628 30.628 0 0110.219 1.71c.116-5.513-1.778-11.027-5.218-15.145l-.033-.034.017-.033c.914-2.64 1.861-5.38 2.708-8.104l-.016-.05c-.599-2.11-1.861-4.235-3.889-6.51a1.236 1.236 0 00-1.694-.084c-3.606 3.306-5.567 8.238-5.118 12.905v.033c-1.579 5.38-2.16 8.138-2.675 10.562-.366 1.777-.698 3.322-1.33 5.514a32.316 32.316 0 017.029-.764z"
        fill="#C6C6C6"
      />
      <path opacity={0.1} d="M124.094 56.264l-5.151 11.226-2.958-2.972 8.109-8.255z" fill="#000" />
      <path
        d="M112.512 62.508l.366.698c0 .016 0 .033-.017.033l-.016.016 1.877 3.637a.42.42 0 01-.182.582l-2.277 1.162a.436.436 0 01-.581-.182l-1.812-3.521h-.049l-.2-.399c0-.016 0-.016.017-.033h.016l-.083-.15h-.016c-.017 0-.017 0-.034-.016l-.199-.399c0-.016 0-.016.017-.033h.016l-.099-.2h-.034l-.116-.215v-.017h.017l-.216-.431a.435.435 0 01.182-.582l2.277-1.162a.421.421 0 01.581.182l.532 1.013.017-.016s.016.016.016.033z"
        fill="#4A4A4A"
      />
      <path
        d="M111.798 61.644l2.758 5.365a.273.273 0 01-.116.365l-2.177 1.113a.274.274 0 01-.366-.117l-2.758-5.364a.274.274 0 01.116-.365l.333-.166.016.05c.05.116.183.149.3.1l1.113-.566c.116-.05.149-.199.099-.298l-.016-.05.349-.183c.116-.066.266-.017.349.116z"
        fill="#09F"
      />
      <path
        d="M112.595 68.204c.781-.05 1.38-.73 1.33-1.527 0-.084-.017-.167-.034-.25l4.254-2.79-2.426-1.046-3.672 2.807a1.44 1.44 0 00.548 2.806z"
        fill="#FFD4C7"
      />
      <path
        d="M116.683 66.195l-.05-.033c-1.013-.748-2.143-1.512-3.223-2.259l-.067-.05.05-.066c2.326-2.707 4.636-5.547 6.879-8.288v-.016l.017-.017.016-.016h.017-.066l-.698-5.431c-.632-.847-.748-2.541.066-3.72.848-1.23 2.642-1.628 3.838-.88a2.545 2.545 0 011.114 1.876 2.311 2.311 0 01-.598 1.86c.299 2.873.299 5.431.315 7.922v.017l-.016.017c-2.393 3.039-5.018 6.061-7.561 8.984l-.033.1zM141.834 101.3h-39.668c-.1 0-.166-.06-.166-.15s.066-.15.166-.15h39.668c.1 0 .166.06.166.15s-.083.15-.166.15z"
        fill="#C6C6C6"
      />
      <path
        d="M36.589 22.75c6.029 0 10.916-4.886 10.916-10.912C47.505 5.812 42.618.927 36.59.927c-6.03 0-10.917 4.885-10.917 10.911s4.887 10.911 10.917 10.911z"
        fill="#E6007E"
      />
    </svg>
  );
}

export default SvgComponent;
