import * as React from 'react';

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M19.46 7.151L14.61 12l4.848 4.848a1.846 1.846 0 01-2.61 2.611L12 14.611l-4.849 4.848c-.36.36-.832.541-1.305.541a1.846 1.846 0 01-1.305-3.152L9.39 12 4.54 7.15a1.846 1.846 0 012.61-2.61L12 9.388l4.849-4.849a1.846 1.846 0 012.61 2.611z"
        fill={props.fill || '#000'}
      />
    </svg>
  );
}

export default SvgComponent;
