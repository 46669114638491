import * as React from 'react';

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M16.42 8.827c.306.305.306.8 0 1.105l-5.24 5.241a.781.781 0 01-1.105 0L7.58 12.678a.78.78 0 111.104-1.105l1.943 1.943 4.689-4.69a.781.781 0 011.104 0zM22 12c0 5.527-4.473 10-10 10S2 17.527 2 12 6.473 2 12 2s10 4.473 10 10zm-1.563 0A8.433 8.433 0 0012 3.562 8.433 8.433 0 003.562 12 8.433 8.433 0 0012 20.438 8.433 8.433 0 0020.438 12z"
        fill={props.fill || '#000'}
      />
    </svg>
  );
}

export default SvgComponent;
