/**
 * Text component
 *
 * @author Miguel Antunes <mantunes@ubiwhere.com>
 *
 *
 */

import styled from 'styled-components';

export default styled.p`
  font-style: normal;
  font-weight: 300;
  font-size: ${({ theme }) => theme.fontSizes.medium};
  line-height: ${({ theme }) => theme.lineHeights.extraLarge};
  color: ${({ theme }) => theme.colors.primary};
`;
