/**
 * DateRangePicker component
 *
 * @author Miguel Antunes <mantunes@ubiwhere.com>
 *
 *
 */
import React from 'react';
import styled from 'styled-components';
import LocalizationProvider from '@material-ui/lab/LocalizationProvider';
import MobileDateRangePicker from '@material-ui/lab/MobileDateRangePicker';
import AdapterDateFns from '@material-ui/lab/AdapterDateFns';
import { TextField } from '@material-ui/core';

const DateRangePicker = (props) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <MobileDateRangePicker
        value={props.value}
        onChange={props.onChange}
        showToolbar
        renderInput={(start, end) => {
          console.log(start, end);
          return (
            <Wrapper>
              <DateRangeInput
                {...props}
                variant="standard"
                label="Date Range"
                placeholder="Date"
                onClick={start?.inputProps?.onClick}
                value={
                  start?.inputProps?.value || end?.inputProps?.value
                    ? `${start?.inputProps?.value}, ${end?.inputProps?.value}`
                    : ''
                }
              />
            </Wrapper>
          );
        }}
      />
    </LocalizationProvider>
  );
};

export default DateRangePicker;

const Wrapper = styled.div``;

const DateRangeInput = styled(TextField)`
  & .MuiOutlinedInput-root,
  & .MuiInputLabel-standard {
    color: ${({ theme }) => theme.colors.red};
    font-family: 'Ubuntu';
    line-height: ${({ theme }) => theme.lineHeights.medium};
    font-size: ${({ theme }) => theme.fontSizes.medium};
  }
`;
