import mock from 'api/mock';
import { APIResponse, Event } from 'api/types';
import { AxiosResponse } from 'axios';
import interceptor from 'interceptors';
import ENDPOINTS from '../endpoints';

const getEventDetail = ({ id, mocked = false }): Promise<AxiosResponse<APIResponse<Event>>> => {
  if (mocked) return mock.getEventDetail;
  return interceptor
    .get(ENDPOINTS.EVENT.DEFAULT, { params: { id: '37c9597d-22fa-42f0-b02f-58106c95bffa' } })
    .then((res) => res)
    .catch((err) => {
      throw err;
    });
};

const getEvents = ({ params, mocked = false }): Promise<AxiosResponse<APIResponse<Event[]>>> => {
  if (mocked) return mock.getEvents;
  return interceptor
    .get(ENDPOINTS.EVENT.DEFAULT, { params: { ...params } })
    .then((res) => res)
    .catch((err) => {
      throw err;
    });
};

const getRatingSurvey = (id: number) => {
  return mock.getRatingSurvey;
};

const getAgentDetail = (id: number) => {
  return mock.getAgentDetail;
};

export default {
  getEventDetail,
  getEvents,
  getAgentDetail,
  getRatingSurvey,
};
