/**
 * Heading2 component
 *
 * @author Miguel Antunes <mantunes@ubiwhere.com>
 *
 *
 */

import styled from 'styled-components';

export default styled.h2`
  font-style: normal;
  font-weight: bold;
  font-size: ${({ theme }) => theme.fontSizes.extraExtraLarge};
  line-height: ${({ theme }) => theme.lineHeights.extraExtraLarge};
  letter-spacing: 0.013rem;
  color: ${({ theme }) => theme.colors.primaryDark};
`;
