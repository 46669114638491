/**
 * EUFooter component
 *
 * @author Miguel Antunes <mantunes@ubiwhere.com>
 *
 *
 */

import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { FlagEU } from 'assets/icons';

const EUFooter = (props) => {
  const { t } = useTranslation();

  return (
    <Wrapper {...props}>
      <WrapperContent>
        <WrapperFlag>
          <FlagEU />
        </WrapperFlag>
        <FundingText>{t('home.funding_eu')}</FundingText>
      </WrapperContent>
    </Wrapper>
  );
};

export default EUFooter;

const Wrapper = styled.div`
  border-radius: 1rem 1rem 0 0;
  padding: 2rem 1.5rem 2rem;
  background-color: ${({ theme }) => theme.colors.secondary};
`;

const WrapperContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const WrapperFlag = styled.div`
  min-width: 3.5rem;
  margin-right: 1rem;
`;

const FundingText = styled.p`
  color: ${({ theme }) => theme.colors.white};
  font-size: 0.563rem;
  line-height: ${({ theme }) => theme.lineHeights.small};
`;
