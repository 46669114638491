/**
 * LandingPage scene slice
 *
 * @author Miguel Antunes <mantunes@ubiwhere.com>
 *
 *
 */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  loading: true,
};

export default createSlice({
  name: 'LandingPage',
  initialState,
  reducers: {
    onMount: (state, { payload }) => {},
    setLoading: (state, { payload }) => {
      state.loading = payload.value;
    },
    onUnmount: (state, { payload }) => initialState,
  },
});
