import * as React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  simple?: number;
}

function SvgComponent(props: Props) {
  return (
    <svg
      width={96}
      height={103}
      viewBox="0 0 96 103"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M68.898 45.664c5.643 0 10.217-4.597 10.217-10.267 0-5.67-4.574-10.267-10.217-10.267-5.642 0-10.216 4.596-10.216 10.267 0 5.67 4.574 10.267 10.216 10.267z"
        fill="#E6007E"
      />
      <path
        d="M28.399 59.697c.06 2.24.433 4.46 1.109 6.596l.048.147h4.139a7.644 7.644 0 01-.014-.147c-.275-3.189-1.867-22.594.036-25.913-.166.27-5.64 9.186-5.318 19.317z"
        fill="#C6C6C6"
      />
      <path
        d="M28.399 59.697c.06 2.24.433 4.46 1.109 6.596l.048.147h4.139a7.644 7.644 0 01-.014-.147c-.275-3.189-1.867-22.594.036-25.913-.166.27-5.64 9.186-5.318 19.317z"
        fill="#fff"
        fillOpacity={0.5}
      />
      <path
        d="M28.71 66.293c.034.049.07.099.107.147h3.105l-.082-.147c-.513-.93-2.032-3.713-3.441-6.596-1.515-3.1-2.904-6.316-2.787-7.48-.036.263-1.084 8.267 3.098 14.076z"
        fill="#C6C6C6"
      />
      <path
        d="M28.71 66.293c.034.049.07.099.107.147h3.105l-.082-.147c-.513-.93-2.032-3.713-3.441-6.596-1.515-3.1-2.904-6.316-2.787-7.48-.036.263-1.084 8.267 3.098 14.076z"
        fill="#fff"
        fillOpacity={0.5}
      />
      <path
        d="M60.975 66.826a20.893 20.893 0 01-.967 5.75l-.042.129h-3.608l.011-.129c.24-2.78 1.628-19.698-.03-22.59.144.234 4.916 8.008 4.636 16.84z"
        fill="#C6C6C6"
      />
      <path
        d="M60.975 66.826a20.893 20.893 0 01-.967 5.75l-.042.129h-3.608l.011-.129c.24-2.78 1.628-19.698-.03-22.59.144.234 4.916 8.008 4.636 16.84z"
        fill="#fff"
        fillOpacity={0.5}
      />
      <path
        d="M60.704 72.576c-.03.043-.062.087-.094.129h-2.707l.072-.129c.447-.811 1.771-3.236 3-5.75 1.32-2.702 2.532-5.506 2.43-6.52.031.228.945 7.206-2.701 12.27z"
        fill="#C6C6C6"
      />
      <path
        d="M60.704 72.576c-.03.043-.062.087-.094.129h-2.707l.072-.129c.447-.811 1.771-3.236 3-5.75 1.32-2.702 2.532-5.506 2.43-6.52.031.228.945 7.206-2.701 12.27z"
        fill="#fff"
        fillOpacity={0.5}
      />
      {!props.simple && (
        <path
          d="M19.716 9.334L21.4 7.98c-1.307-.145-1.844.572-2.064 1.139-1.021-.427-2.133.132-2.133.132l3.367 1.228a2.56 2.56 0 00-.852-1.146zM70.4 1.371L72.083.02c-1.308-.145-1.845.571-2.064 1.139-1.022-.427-2.134.132-2.134.132l3.367 1.228a2.56 2.56 0 00-.852-1.147z"
          fill="#00264B"
        />
      )}
      <path
        d="M95.16 55.858c0 1.121-.04 2.233-.117 3.335a47.024 47.024 0 01-8.666 24.132 47.22 47.22 0 01-8.341 8.953l-9.974-5.882L62.1 72.41s8.897-18.103 33.044-17.775c.01.407.016.814.016 1.224z"
        fill="#09F"
      />
      <path
        d="M95.042 59.193a47.322 47.322 0 01-5.27 18.6A47.097 47.097 0 0177.516 92.7l-9.266-5.463-5.288-12.404s8.57-17.436 32.08-15.641z"
        fill="#C6C6C6"
      />
      <path
        d="M86.377 83.325a46.214 46.214 0 01-2.085 2.707A46.942 46.942 0 0166.13 99.454a46.86 46.86 0 01-4.383 1.565 51.116 51.116 0 01-32.568-2.078 47.187 47.187 0 01-25.06-27.06c9.019-6.64 26.788-16.145 46.155-6.908a884.597 884.597 0 0114.35 6.987c10.683 5.322 17.793 9.158 21.753 11.365z"
        fill="#09F"
      />
      <path
        d="M84.292 86.032a46.951 46.951 0 01-18.85 13.673 46.726 46.726 0 01-23.068 2.907 46.808 46.808 0 01-21.626-8.573 47.12 47.12 0 01-14.881-17.95c7.531-5.986 24.902-16.741 43.965-7.65 4.903 2.338 9.28 4.47 13.13 6.395 11.227 5.591 18.143 9.392 21.33 11.198z"
        fill="#C6C6C6"
      />
      <path
        d="M70.999 97.096A46.566 46.566 0 0148.249 103a46.77 46.77 0 01-20.913-4.948 47.007 47.007 0 01-16.53-13.793c11.203-1.187 42.172-2.788 60.193 12.837z"
        fill="#09F"
      />
      <path
        d="M66.13 99.454a46.746 46.746 0 01-27.772 2.482A46.899 46.899 0 0114.08 88.157c4.075-.601 36.069-4.732 52.05 11.297zM87.29 29.716v.002c-33.495-24.888-71.872-7.37-72.168-7.236v-.002a46.75 46.75 0 0115.2-10.198 46.567 46.567 0 0117.927-3.565c16.285 0 30.631 8.34 39.042 21z"
        fill="#C6C6C6"
      />
      <path
        d="M87.29 29.716v.002c-33.495-24.888-71.872-7.37-72.168-7.236v-.002a46.75 46.75 0 0115.2-10.198 46.567 46.567 0 0117.927-3.565c16.285 0 30.631 8.34 39.042 21z"
        fill="#fff"
        fillOpacity={0.5}
      />
      <path
        d="M17.82 56.773c5.666-1.025 8.568-11.275 6.48-22.893-2.087-11.619-8.373-20.206-14.04-19.18-5.666 1.024-8.568 11.274-6.48 22.893 2.087 11.618 8.373 20.205 14.04 19.18z"
        fill="#C6C6C6"
      />
      <path
        d="M17.82 56.773c5.666-1.025 8.568-11.275 6.48-22.893-2.087-11.619-8.373-20.206-14.04-19.18-5.666 1.024-8.568 11.274-6.48 22.893 2.087 11.618 8.373 20.205 14.04 19.18z"
        fill="#09F"
      />
      <path
        d="M19.265 70.342c-.336-25.521-8.833-49.47-8.919-49.71l-.774.281c.085.238 8.535 24.062 8.87 49.44l.823-.011z"
        fill="#C6C6C6"
      />
      <path
        d="M4.19 32.848l-.254.787 10.172 3.306.253-.787L4.19 32.848zM23.325 31.96l-8.381 6.67.511.65 8.382-6.671-.512-.65zM45.055 53.093c6.23 0 11.281-10.357 11.281-23.132 0-12.775-5.05-23.132-11.281-23.132-6.23 0-11.281 10.357-11.281 23.132 0 12.775 5.05 23.132 11.28 23.132z"
        fill="#C6C6C6"
      />
      <path
        d="M45.055 53.093c6.23 0 11.281-10.357 11.281-23.132 0-12.775-5.05-23.132-11.281-23.132-6.23 0-11.281 10.357-11.281 23.132 0 12.775 5.05 23.132 11.28 23.132z"
        fill="#09F"
      />
      <path
        d="M44.013 67.824c4.518-27.242.045-54.386 0-54.657l-.88.15c.046.269 4.493 27.27 0 54.36l.88.147z"
        fill="#C6C6C6"
      />
      <path
        d="M35.123 24.987l-.42.79 10.2 5.483.421-.79-10.201-5.483zM55.67 27.734l-10.2 5.486.42.79 10.2-5.486-.42-.79z"
        fill="#C6C6C6"
      />
      <path
        d="M94.057 45.652a85.801 85.801 0 00-12.93-5.94c-24.69-8.979-49.173-5.519-58.757 7.331 7.458-15.742 34.07-20.691 60.925-10.926a87.478 87.478 0 019.162 3.933 47.003 47.003 0 011.6 5.602z"
        fill="#C6C6C6"
      />
      <path
        d="M94.057 45.652a85.801 85.801 0 00-12.93-5.94c-24.69-8.979-49.173-5.519-58.757 7.331 7.458-15.742 34.07-20.691 60.925-10.926a87.478 87.478 0 019.162 3.933 47.003 47.003 0 011.6 5.602z"
        fill="#fff"
        fillOpacity={0.5}
      />
      {!props.simple && (
        <path
          d="M60.71 20.32l1.683-1.352c-1.307-.145-1.845.572-2.064 1.139-1.022-.427-2.133.132-2.133.132l3.366 1.228a2.558 2.558 0 00-.852-1.146z"
          fill="#00264B"
        />
      )}
    </svg>
  );
}

export default SvgComponent;
