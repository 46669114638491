import * as React from 'react';

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M2.17 21.015l3.146-3.124c-1.358-2.558-1.946-4.73-1.946-6.542 0-1.584.452-2.92 1.245-4.052.77-1.11 1.833-1.97 3.078-2.671 1.585-.883 3.441-1.472 5.365-1.856.023 0 .046 0 .046-.023C16.137 2.136 19.26 2 21.434 2h.113c.023 0 .046 0 .046.023.022 0 .045 0 .045.022.022 0 .022.023.045.023s.023.023.045.023c.023 0 .023.022.046.045l.045.045c.023.023.023.023.023.045l.045.046c0 .022.023.022.023.045s.022.023.022.045c0 .023 0 .023.023.045 0 .023.022.046.022.068v.114c.023 2.173-.09 5.32-.724 8.353l-.34 1.494c-.656 2.445-1.675 4.686-3.282 6.225-1.313 1.268-3.01 2.037-5.184 1.97-1.766-.046-3.87-.634-6.316-1.947L2.985 21.83a.574.574 0 01-.815 0 .574.574 0 010-.815zM9.436 5.056l-.384 9.1 3.214-3.214.294-6.882c-1.086.249-2.15.589-3.124.996zm11.41-1.879c-1.97.023-4.573.159-7.131.634l-.25 5.93 3.645-3.644a.574.574 0 01.815 0 .574.574 0 010 .815l-3.644 3.645 5.93-.25c.476-2.557.612-5.16.635-7.13zm-1.02 8.965c.046-.227.091-.453.16-.68l-6.882.295-3.238 3.214 9.123-.385c.317-.77.611-1.584.838-2.444zm-7.356 7.357c1.833.045 3.26-.589 4.369-1.653a8.506 8.506 0 001.562-2.083l-9.734.43-1.675 1.676c2.127 1.086 3.938 1.607 5.478 1.63zm-6.316-2.445l1.675-1.675.43-9.734s-.022 0-.022.022c-1.087.589-2.038 1.336-2.694 2.287-.657.928-1.019 2.06-1.042 3.395 0 1.562.498 3.441 1.653 5.705z"
        fill={props.fill || '#000'}
      />
    </svg>
  );
}

export default SvgComponent;
