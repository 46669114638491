import * as React from 'react';

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M20.367 6.38L15.18 1.143A.479.479 0 0014.845 1H5.77C4.796 1 4 1.804 4 2.792v18.42A1.78 1.78 0 005.77 23h12.963a1.78 1.78 0 001.77-1.788V6.72a.466.466 0 00-.136-.34zM15.32 2.643l3.561 3.597h-2.74a.829.829 0 01-.82-.83V2.642zm4.237 18.57c0 .456-.37.83-.82.83H5.77a.829.829 0 01-.821-.83V2.792c0-.455.37-.83.82-.83h8.6V5.41a1.78 1.78 0 001.769 1.788h3.412v14.015h.008z"
        fill={props.fill || '#000'}
      />
      <path
        d="M16.619 10.734a.478.478 0 01-.477.482H8.365a.478.478 0 01-.476-.482c0-.266.212-.481.476-.481h7.777c.264.004.477.22.477.481zM16.619 13.356a.478.478 0 01-.477.481H8.365a.478.478 0 01-.476-.481c0-.267.212-.481.476-.481h7.777c.264 0 .477.214.477.48zM16.619 15.973a.478.478 0 01-.477.482H8.365a.478.478 0 01-.476-.482c0-.266.212-.481.476-.481h7.777c.264.004.477.22.477.481zM14.024 18.595a.478.478 0 01-.477.481H8.365a.478.478 0 01-.476-.481c0-.267.212-.482.476-.482h5.186c.26 0 .473.215.473.482z"
        fill={props.fill || '#000'}
      />
    </svg>
  );
}

export default SvgComponent;
