import * as React from 'react';

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M21.756 20.578l-5.688-5.688a7.878 7.878 0 001.765-4.973C17.833 5.552 14.282 2 9.917 2S2 5.552 2 9.917s3.552 7.916 7.917 7.916a7.878 7.878 0 004.973-1.765l5.688 5.688a.831.831 0 001.178 0 .832.832 0 000-1.178zm-11.84-4.411a6.257 6.257 0 01-6.25-6.25 6.257 6.257 0 016.25-6.25 6.257 6.257 0 016.25 6.25 6.257 6.257 0 01-6.25 6.25z"
        fill={props.fill || '#000'}
      />
    </svg>
  );
}

export default SvgComponent;
