export default {
  PROD_CONFIG: 'PROD_CONFIG',
  DOMAIN: 'frontend-dev.cem.ubiwhere.com',
  API_URL: 'https://api-dev.cem.ubiwhere.com/api/v1',
  OAUTH2: {
    URL: 'https://id.evoralab.xyz/oauth2',
    CLIENT_ID: 'ALQrM61LrF1PT0uTBbN7Lo_p9QMa',
    CLIENT_SECRET: 'YdfG90MEXdJ7Ae1oTqdRTm5k08Ya',
    AUTHORIZE: {
      scope: 'internal_login openid',
      redirect_uri: 'https://frontend-dev.cem.ubiwhere.com/login/',
      response_type: 'code',
    },
    TOKEN: {
      scope: 'internal_login openid',
      grant_type: 'authorization_code',
      redirect_uri: 'https://frontend-dev.cem.ubiwhere.com/login/',
    },
    REFRESH: {
      grant_type: 'refresh_token',
    },
  },
};
