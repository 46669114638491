/**
 * EventDetail scene sagas
 *
 * @author Miguel Antunes <mantunes@ubiwhere.com>
 *
 *
 */
import { call, put, takeLatest } from 'redux-saga/effects';
import API from 'api';

import { actions } from 'store/rootSlices';
import { APIResponse, Event } from 'api/types';
import { AxiosResponse } from 'axios';

function* onMountSaga({ payload }: ReturnType<any>) {
  try {
    const response: AxiosResponse<APIResponse<Event>> = yield call(API.Events.getEventDetail, {
      id: payload.id,
      mocked: true,
    });
    yield put(actions.EventDetail.setEvent(response.data.results[0]));
  } catch (error) {
    yield console.log('💥EventDetail onMountSaga:', error);
  }
}

function* onUnmountSaga() {}

export default function* watcherSignin() {
  yield takeLatest('EventDetail/onMount', onMountSaga);
  yield takeLatest('EventDetail/onUnmount', onUnmountSaga);
}
