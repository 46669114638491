/**
 * Menu scene slice
 *
 * @author Miguel Antunes <mantunes@ubiwhere.com>
 *
 *
 */
import { createSlice } from '@reduxjs/toolkit'

const initialState = {}

export default createSlice({
  name: 'Menu',
  initialState,
  reducers: {
    onMount: (state, { payload }) => {},
    onUnmount: (state, { payload }) => {}
  }
})