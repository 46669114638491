/**
 * Events scene slice
 *
 * @author Miguel Antunes <mantunes@ubiwhere.com>
 *
 *
 */
import { createSlice } from '@reduxjs/toolkit';
import { EventsState } from '../types';

const initialState: EventsState = {
  loadingUserEvents: false,
  userEvents: null,
  loadingFutureEvents: false,
  futureEvents: null,
  loadingPreviousEvents: false,
  previousEvents: null,
};

export default createSlice({
  name: 'Events',
  initialState,
  reducers: {
    onMount: (state) => {},
    getUserEvents: (state) => {
      state.loadingUserEvents = true;
    },
    setUserEvents: (state, { payload }) => {
      state.userEvents = payload;
      state.loadingUserEvents = false;
    },
    getFutureEvents: (state) => {
      state.loadingFutureEvents = true;
    },
    setFutureEvents: (state, { payload }) => {
      state.futureEvents = payload;
      state.loadingFutureEvents = false;
    },
    getPreviousEvents: (state) => {
      state.loadingPreviousEvents = true;
    },
    setPreviousEvents: (state, { payload }) => {
      state.previousEvents = payload;
      state.loadingPreviousEvents = false;
    },
    onUnmount: (state) => {},
  },
});
