import * as React from 'react';

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.386 10.992l2.266.329A.625.625 0 0116 12.387l-1.64 1.598.387 2.257c.088.51-.449.9-.907.659l-2.027-1.066-2.026 1.066a.625.625 0 01-.907-.659l.387-2.257-1.64-1.598a.625.625 0 01.347-1.066l2.266-.33 1.013-2.053a.625.625 0 011.121 0l1.013 2.053zm-.314 2.88a.625.625 0 01.18-.553l.967-.943-1.338-.195a.624.624 0 01-.47-.342l-.598-1.212-.599 1.212a.625.625 0 01-.47.342l-1.338.195.968.943c.147.144.214.35.18.554l-.229 1.332c.26-.136.466-.246.632-.334.671-.356.69-.367.855-.367.1 0 .2.024.291.072l1.197.63-.229-1.333z"
        fill={props.fill || '#000'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.69 5.75H19A.625.625 0 0119 7h-.313v11.523a.625.625 0 01-.365.57l-6.25 2.85a.625.625 0 01-.519 0l-6.25-2.85a.625.625 0 01-.365-.57V7h-.313a.625.625 0 010-1.25h2.31l3.957-2.637a.938.938 0 111.841 0L16.69 5.75zm-2.254 0l-2.623-1.749L9.188 5.75h5.247zm-2.623 14.938l5.624-2.566V7H6.188v11.122l5.625 2.566z"
        fill={props.fill || '#000'}
      />
    </svg>
  );
}

export default SvgComponent;
