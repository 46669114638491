import endpoints from 'api/endpoints';
import React from 'react';
import Translation from 'shared/containers/Translation';
import config from 'config';

export const DEFAULT_USER = { name: 'It is me', role: 'guest' };

export const GENERATE_TRANSLATIONS = (apiTranslations) => {
  let translations = {};
  Object.keys(apiTranslations).forEach((value, key) => {
    translations[value] = (
      <Translation transKey={value} key={key}>
        {apiTranslations[value]}
      </Translation>
    );
  });

  return translations;
};

export const shouldRenderNavbar = (location) => {
  const route = location.pathname.split('/');
  return (
    route[1] === 'home' ||
    route[1] === 'explore' ||
    route[1] === 'events' ||
    route[1] === 'recommendations' ||
    route[1] === 'menu'
  );
};

export const authorizeUser = () => {
  const { OAUTH2 } = config;
  const searchParams = new URLSearchParams({
    ...OAUTH2.AUTHORIZE,
    client_id: OAUTH2.CLIENT_ID,
  });
  const redirectURL = `${endpoints.AUTH.AUTHORIZE}?${searchParams}`;
  window.location.replace(redirectURL);
};

export const secondsFromNow = (seconds) => new Date(new Date().getTime() + seconds * 1000);
