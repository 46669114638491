import { all } from 'redux-saga/effects';

import appSaga from 'app/logic/sagas';
import dashboardSaga from 'scenes/Dashboard/logic/sagas';
import settingsSaga from 'scenes/Settings/logic/sagas';
// ! WARNING!! Don't erase the nextline as it is necessary for hygen to automatically import your components
// Define imports here
import achievementsSaga from 'scenes/Account/containers/Achievements/logic/sagas';
import itinerariesSaga from 'scenes/Itineraries/logic/sagas';
import accountSaga from 'scenes/Account/logic/sagas';
import registerSaga from 'scenes/Register/logic/sagas';
import loginSaga from 'scenes/Login/logic/sagas';
import landingPageSaga from 'scenes/LandingPage/logic/sagas';
import recommendationDetailSaga from 'scenes/RecommendationDetail/logic/sagas';
import eventRatingSaga from 'scenes/EventRating/logic/sagas';
import agentDetailSaga from 'scenes/AgentDetail/logic/sagas';
import eventDetailSaga from 'scenes/EventDetail/logic/sagas';
import newRecommendationSaga from 'scenes/NewRecommendation/logic/sagas';
import menuSaga from 'scenes/Menu/logic/sagas';
import recommendationsSaga from 'scenes/Recommendations/logic/sagas';
import eventsSaga from 'scenes/Events/logic/sagas';
import exploreSaga from 'scenes/Explore/logic/sagas';
import homeSaga from 'scenes/Home/logic/sagas';
import navbarSaga from 'shared/containers/Navbar/logic/sagas';

export default function* rootSaga() {
  yield all([
    achievementsSaga(),
    itinerariesSaga(),
    accountSaga(),
    registerSaga(),
    loginSaga(),
    landingPageSaga(),
    recommendationDetailSaga(),
    eventRatingSaga(),
    agentDetailSaga(),
    eventDetailSaga(),
    newRecommendationSaga(),
    menuSaga(),
    recommendationsSaga(),
    appSaga(),
    dashboardSaga(),
    settingsSaga(),
    navbarSaga(),
    homeSaga(),
    exploreSaga(),
    eventsSaga(),
  ]);
}
