import * as React from 'react';

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M17.978 14.808a.216.216 0 00-.052.173l.834 5.083a1.674 1.674 0 01-.629 1.617 1.52 1.52 0 01-.93.319c-.25 0-.503-.068-.734-.191l-4.377-2.4a.197.197 0 00-.175 0l-4.377 2.4a1.526 1.526 0 01-1.669-.128 1.682 1.682 0 01-.629-1.617l.834-5.083a.194.194 0 00-.052-.173L2.479 11.21a1.678 1.678 0 01-.397-1.695c.188-.6.677-1.034 1.28-1.125l4.892-.742a.17.17 0 00.14-.105l2.188-4.623c.271-.57.817-.92 1.42-.92s1.15.355 1.42.92l2.184 4.623a.188.188 0 00.14.105l4.892.742c.603.091 1.092.524 1.28 1.125.192.606.04 1.258-.397 1.695l-3.543 3.598z"
        fill={props.fill || '#000'}
      />
    </svg>
  );
}

export default SvgComponent;
