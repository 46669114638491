/**
 * EventRating scene
 *
 * @author Miguel Antunes <mantunes@ubiwhere.com>
 *
 *
 */
import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { actions, RootState } from 'store/rootSlices';
import { Back } from 'assets/icons';
import RatingSurvey from './containers/RatingSurvey';
import { Button as StyledButton, Heading2, Text } from 'shared/components/ui';
import { Onboarding5 } from 'assets/illustrations';

//import config from 'config'

const EventRating = ({ t, i18n }) => {
  const { loading, title, queries, eventRated, rewardRedeemed, rewardLocation, surveyStarted } =
    useSelector((state: RootState) => state.EventRating);
  const { id } = useParams();

  const { onMount, onUnmount, startSurvey } = actions.EventRating;
  const { navigateBack } = actions.App;

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(onMount({ id }));
    return () => {
      dispatch(onUnmount());
    };
  }, [dispatch, onMount, onUnmount, id]);

  if (loading)
    return (
      <Wrapper>
        <div>Loading...</div>
      </Wrapper>
    );

  return (
    <Wrapper>
      <Return
        onClick={() => {
          dispatch(navigateBack());
        }}
      >
        <Back />
      </Return>
      {queries && eventRated ? (
        <WrapperRating>
          <WrapperInfo>
            <Title>{t('rating.completed_title')}</Title>
            <Description>
              <Text>
                {t('rating.completed_info_0')}
                <Strong>{title}</Strong>
                {t('rating.completed_info_1')}
              </Text>
              <Text>
                {t('rating.completed_info_2')}
                <Strong>{rewardLocation}</Strong>
                {t('rating.completed_info_3')}
              </Text>
            </Description>
          </WrapperInfo>
          <Onboarding5 />
          <Button disabled={rewardRedeemed}>
            {rewardRedeemed ? t('rating.completed_redeemed') : t('rating.completed_get_reward')}
          </Button>
        </WrapperRating>
      ) : surveyStarted ? (
        <RatingSurvey />
      ) : (
        <WrapperRating>
          <WrapperInfo>
            <Title>{t('rating.start_title')}</Title>
            <Description>
              <Text>{t('rating.start_info_0')}</Text>
              <Text>{t('rating.start_info_1')}</Text>
              <Text>
                {t('rating.start_info_2')}
                <Strong>{rewardLocation}</Strong>
                {'.'}
              </Text>
            </Description>
          </WrapperInfo>
          <Onboarding5 />
          <Button onClick={() => dispatch(startSurvey())}>{t('rating.start')}</Button>
        </WrapperRating>
      )}
    </Wrapper>
  );
};

export default EventRating;

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding-bottom: ${({ theme }) => theme.dimensions.navBarHeight};
`;

const Return = styled.div`
  margin-left: 1rem;
  margin-top: 3.25rem;
  svg path {
    fill: ${({ theme }) => theme.colors.primary};
  }
`;

const Title = styled(Heading2)`
  margin-bottom: 1rem;
`;
const Description = styled.div``;
const Strong = styled.strong`
  font-weight: 500;
`;

const WrapperInfo = styled.div``;

const WrapperRating = styled.div`
  padding: 1.5rem 1rem 2.5rem;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`;

const Button = styled(StyledButton)`
  width: 15.625rem;
`;
