/**
 * RecommendationDetail scene
 *
 * @author Miguel Antunes <mantunes@ubiwhere.com>
 *
 *
 */
import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';

import { actions, RootState } from 'store/rootSlices';
import { Art, Back } from 'assets/icons';
import {
  Badge,
  Button,
  Heading2,
  Heading3,
  Link as StyledLink,
  Subtitle as StyledSubtitle,
  StatusBar,
  Text,
} from 'shared/components/ui';
import moment from 'moment';

//import config from 'config'

const RecommendationDetail = ({ t, i18n }) => {
  const { editing, loading, recommendationDetail } = useSelector(
    (state: RootState) => state.RecommendationDetail
  );
  const { id } = useParams();
  const { onMount, onUnmount, setEditing } = actions.RecommendationDetail;
  const { navigateBack /*, navigateTo */ } = actions.App;

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(onMount({ id }));
    return () => {
      dispatch(onUnmount());
    };
  }, [dispatch, onMount, onUnmount, id]);

  if (loading)
    return (
      <Wrapper>
        <div>Loading...</div>
      </Wrapper>
    );

  if (!recommendationDetail && typeof recommendationDetail === 'undefined') return null;

  if (editing) return <Wrapper>EditingRecommendation</Wrapper>; //<EditRecommendationForm />;

  const { title, description, date, location, category, similarEvents, status } =
    recommendationDetail;

  return (
    <Wrapper>
      <DarkSection>
        <Back
          onClick={() => {
            dispatch(navigateBack());
          }}
        />
        <Title>{t('recommendation_detail.my_recommendations')}</Title>
        <Info>
          <Icon>
            <Art />
          </Icon>
          <InfoText>
            <RecommendationTitle>{title}</RecommendationTitle>
            <WrapperLocationDate>
              <Label>{location}</Label>
              {' | '}
              <Label>
                {moment(date[0]).format('MMMM, D') + ' — ' + moment(date[1]).format('MMMM, D')}
              </Label>
            </WrapperLocationDate>
            <Description>{description}</Description>
            <Categories>
              {category.map((cat) => (
                <Badge key={`badge_${cat}`} type={cat} />
              ))}
            </Categories>
            <Subtitle>{t('recommendation_detail.similar_events')}</Subtitle>
            <SimilarEvents>
              {similarEvents.map((link, index) => (
                <Link key={`similar_event_${index}`}>{link}</Link>
              ))}
            </SimilarEvents>
          </InfoText>
        </Info>
      </DarkSection>
      <WrapperStatus>
        <StatusTitle>{t('recommendation_detail.status') + ': '}</StatusTitle>
        <StatusBar status={status} />
        <StatusLabel>{t(`recommendation_detail.status_${status}`)}</StatusLabel>
      </WrapperStatus>
      <WrapperEdit>
        {(status === 'pending' || status === 'accepted') && (
          <EditWarning>{t('recommendation_detail.edit_warning')}</EditWarning>
        )}
        <EditButton
          disabled={status === 'pending' || status === 'accepted'}
          onClick={() => dispatch(setEditing({ value: true }))}
        >
          {t('common.edit')}
        </EditButton>
      </WrapperEdit>
    </Wrapper>
  );
};

export default RecommendationDetail;

const Wrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.background};
  padding-bottom: ${({ theme }) => theme.dimensions.navBarHeight};
`;

const DarkSection = styled.section`
  background-color: ${({ theme }) => theme.colors.primary};
  border-radius: 0 0 1rem 1rem;
  box-shadow: 0rem 0.25rem 0.75rem 0.25rem rgba(0, 0, 0, 0.2);
  padding: 2rem 1rem 2.5rem;
  z-index: 2;
  svg path {
    fill: ${({ theme }) => theme.colors.white};
  }
  * {
    color: ${({ theme }) => theme.colors.white};
  }
`;

const Title = styled(Heading2)`
  margin: 1rem 0 2rem;
`;

const Info = styled.div`
  display: flex;
  flex-direction: row;
`;

const Icon = styled.div`
  margin-right: 1.25rem;
  svg {
    width: 1.75rem;
    height: 1.75rem;
  }
`;

const InfoText = styled.div``;

const RecommendationTitle = styled(Heading3)`
  margin-bottom: 0.5rem;
`;

const WrapperLocationDate = styled.p`
  margin-bottom: 0.5rem;
  line-height: ${({ theme }) => theme.lineHeights.medium};
  font-size: ${({ theme }) => theme.fontSizes.small};
  font-weight: 400;
  letter-spacing: 0.013rem;
`;
const Label = styled.label``;

const Description = styled(Text)`
  margin-bottom: 1.5rem;
`;

const Categories = styled.div`
  display: flex;
  margin-bottom: 1.5rem;

  > div {
    margin-right: 1rem;
  }
  :last-child {
    margin-right: 0;
  }
`;

const Subtitle = styled(StyledSubtitle)`
  margin-bottom: 0.5rem;
`;

const Link = styled(StyledLink)`
  font-weight: 300;
`;

const SimilarEvents = styled.div``;

const WrapperStatus = styled.div`
  padding: 2.5rem 1.5rem 2rem;
`;

const StatusTitle = styled(Heading3)`
  margin-bottom: 1rem;
`;

const StatusLabel = styled.label`
  color: ${({ theme }) => theme.colors.primary};
  line-height: ${({ theme }) => theme.lineHeights.extraLarge};
  font-size: ${({ theme }) => theme.fontSizes.medium};
`;

const WrapperEdit = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`;

const EditButton = styled(Button)`
  width: 15.625rem;
  margin: 1.5rem 0 2.5rem;
`;

const EditWarning = styled.p`
  font-size: ${({ theme }) => theme.fontSizes.small};
  line-height: ${({ theme }) => theme.lineHeights.medium};
  color: ${({ theme }) => theme.colors.gray};
  margin: 0 2.375rem;
  text-align: center;
`;
